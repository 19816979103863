import { Injectable } from '@angular/core';
import { User } from '@constants/request.const';
import { environment } from '@environments/environment';

const BASE_SOUND_MATE_URL = environment.baseSoundMateUrl;
const BASE_RADIO_STREAMING_URL = environment.baseRadioStreamingUrl;
const API = 'api';
const VERSION1 = 'v1';
const AUTH = 'auth';
const SIGN_IN = 'login';
const GET_LOGIN_FORM = 'GetLoginForm';
const LOGOUT = 'logout';
const SIGN_UP = 'SignUp';
const SIGN_UP_WITH_PASSWORD = 'SignUpWithPassword';
const LOGIN_WITH_PASSWORD = 'loginWithPassword';
const SIGN_UP_WITH = 'SignUpWith';
const LOGIN_WITH = 'LoginWith';
const GET_SIGN_UP_FORM = 'GetSignUpForm';

const USER = 'User';
const HISTORY = 'history';
const STATISTICS = 'statistics';
const LOGS = 'logs';

const GET_PREFERENCES_SETTINGS = 'GetPreferencesSettings';
const UPDATE_PREFERENCES_SETTINGS = 'UpdatePreferencesSettings';
const GET_PAYMENT_SETTINGS = 'PaymentSettingsV2';
const UPDATE_PAYMENT_SETTINGS = 'UpdatePayoutAddressesV2';
const GET_INFO_SETTINGS = 'GetDetailedInfoSettings';
const UPDATE_INFO_SETTINGS = 'UpdateDetailedInfoSettings';
const UPDATE_EMAIL_ADDRESS = 'UpdateEmailAddress';
const UPDATE_EMAIL_ADDRESS_Version = 'UpdateEmailAddressV2';
const UPDATE_PHONE_NUMBER = 'UpdatePhoneNumber';
const UPDATE_PHONE_NUMBER_VERSION = 'UpdatePhoneNumberV2';
const UPDATE_PASSWORD = 'UpdatePassword';
const UPDATE_PIN = 'pin';
const UPDATE_2FA = 'UpdateGoogle2FASettings';
const REMOVE_ACCOUNT = 'DeleteAccount';
const GET_2FA = 'Get2FASettings';

const RADIO_STATION = 'Radiostation';
const GENRE = 'Genre';
const FAVORITE = 'Favorite';
const RATING = 'rating';
const LISTENING = 'Listening';
const DETAILS = 'details';
const UPDATE = 'update';
const RADIO_STREAMING = 'radiostreaming';
const GUIDE_SHOWN = 'guide-shown';
const GENRES_SELECTION_SHOWN = 'genres-selection-shown';
const SETTINGS = 'Settings';
const USER_SETTINGS = 'user_settings'

const EXTRA_MINUTES = 'ExtraMinutes';
const BUY = 'buy';

const MEMBERSHIP = 'memberships';
const GET_ALL = 'GetAll';
const UPGRADE = 'Upgrade';
const INCOME_CALCULATOR = 'incomeCalculator';
const PLANS_MAIN = 'cards';
const COMMISSION = 'GetAllCommission';

const CURRENCIES = 'Currencies';
const FIAT = 'Fiat';
const SET_CURRENCY = 'SetCurrency';

const FILTER = 'Filter';
const GET_TARGET_BALANCES = 'GetTargetBalances';
const GET_ALL_COUNTRIES = 'GetAllCountries';

const GET_ALL_COUNTRIES_New = 'GetRepresentativeCountries';

const PAYMENTS = 'Payments';
const WITHDRAWAL_PAGE_DATA = 'WithdrawalPageData';
const WITHDRAW = 'Withdraw';
const WITHDRAWAL_HISTORY = 'WithdrawalHistory';
const PAYMENT_PROOF = 'PaymentProofs';
const TRANSACTIONS_HISTORY = 'LogHistory';
const BOOSTER_HISTORY = 'BoosterLogHistory'
const PAYMENT_PROOFS = 'PaymentProofs';

const VOUCHER = 'Voucher';
const REDEEM = 'Redeem';
const VOUCHER_BUY = 'Buy';
const DISCOUNT = 'Discount';
const GETOWN = 'GetOwn';
const SALES_REPORT = 'SalesReport';
const SALES_REPORT_STAT = 'SalesReportStats';

const TRANSFER = 'Transfer';
const GET_TRANSFER_MENU_FORM = 'GetTransferMenuForm';
const GET_TRANSFER_TO_OWN_FORM = 'GetTransferToOwnForm';
const GET_TRANSFER_TO_OTHER_FORM = 'GetTransferToOtherForm';
const GET_TRANSFER_POINTS_FORM = 'GetTransferPointsForm';
const TRANSFER_TO_OWN = 'TransferToOwn';
const TRANSFER_TO_OTHER = 'TransferToOther';
const TRANSFER_POINTS = 'TransferPoints';
const BALANCES = 'Balances';
const ACTIVITIES = 'activities';
const BALANCE = 'balance';
const PROFILE = 'Profile';
const INFO = 'Info';
const MY_TEAM = 'MyTeam';
const MY_TEAM_MENU = 'GetMyTeamMenu';
const GET_REFERRALS = 'GetReferrals';
const REFERRALS = 'Referrals';
const GET_DIRECT_REFERRALS = 'GetDirectReferrals';
const GET_DIRECT_REFERRALS_STATS = 'GetDirectReferralsStats';
const GET_DIRECT_REFERRAL_OPTIONS = 'GetDirectReferralOptions';
const GET_PROMO_MATERIALS = 'GetPromoMaterials';
const GET_REFERRAL_PROFILE_INFO = 'GetReferralProfileInfo';
const EARNING = 'Earnings';
const REPRESENTATIVE = 'Representatives';
const REPRESENTATIVE_MENU = 'GetRepresentativeMenu';
const REPRESENTATIVE_COUNTRIES = 'GetRepresentativeCountries';
const REPRESENTATIVES = 'GetRepresentatives';
const REPRESENTATIVES_CREAT = 'CreateRepresentant';
const IS_SUBMIT_FORM = 'IsRepresentativeSubmitForm';
export const LISTENED_PAID_MIN = 'listenedPaidMinutes';

const REFRESH_TOKEN = 'RefreshToken';
const REFRESH = 'refresh';
const TOKEN = 'token';
const YOUTUBE = 'youtube'

const CONFIRMATION = 'Confirmation';
const IS_CONFIRMATION_NEEDED = 'IsConfirmationNeeded';
const CONFIRM_USER_ACTION = 'SendCodeToEmailOrPhone';
const CONFIRMATION_PHONE_OR_EMAIL = 'GetConfirmationPhoneOrEmail';
const UPDATE_EMAIL = 'UpdateEmail';
const EMAIL_CONFIRM = 'EmailConfirm';

const GET_RESET_PASSWORD_FORM = 'GetResetPasswordForm';
const SEND_REST_PASSWORD_LINK = 'SendResetPasswordLink';
const GET_NEW_PASSWORD_FORM = 'GetNewPasswordForm';
const GET_NEW_PASS_ON_EMAIL = 'GetNewPasswordOnEmail';
const SET_NEW_PASSWORD = 'SetNewPassword';

const PRE_LOGIN = 'prelogin';
const PHONE_CONFIRMATION_REQUIRED = 'IsPhoneConfirmationRequired';

const LOGIN_SETUP_REQUIRED = 'IsConfirmationRequiredForPrelogin';
const PHONE_SETUP_REQUIRED = 'IsPhoneSetupRequired';
const PIN_SETUP_REQUIRED = 'IsPinSetupRequired';
const IDENTITY_CONFIRMATION_REQUIRED = 'IsConfirmationOnLoginNeeded';
const SET_PHONE_NUMBER = 'SetPhoneNumber';
const SET_PIN = 'SetPin';
const CONFIRM_PHONE_NUMBER = 'ConfirmPhoneNumber';
const LOGIN = 'login';
const SEND_CONFIRMATION_CODE = 'SendCodeToEmailOrPhoneOnLogin';

const SEND_CONFIRMATION_EMAIL_CODE = 'EmailConfirmV2';

const SEND_PHONE_CONFIRM_CODE = 'SendConfirmationSMS';
const VALIDATION = 'Validation';
const PIN = 'pin';
const PIN_Version = 'pinV2';
const G2FAQR_LINK = 'Google2FAAuthenticationURLforQR';
const G2FAQR_LINK_USER = 'user';
const G2FA_VERIFICATION_QR = 'Google2FAVerificationQR';
const DASHBOARD_PAYMENT = 'DashboardPayment'
const BOOSTER_CARDS = 'boostercards'
const BUY_BOOSTER_PLANS = 'BuyBoosterPlan'
const READ_NOTIFICATIONS_KEY = 'readNotifications'
const GET_APPLICATION_HISTORY = 'GetRepApplicationHistory'

const BLOCKED_LIST = 'BlockedCountryList';

const RADIO_STREAMING_API_GENRE = `${BASE_RADIO_STREAMING_URL}/${API}/${GENRE}`;
const RADIO_STREAMING_API_LISTENING = `${BASE_RADIO_STREAMING_URL}/${API}/${LISTENING}`;
const RADIO_STREAMING_API_RADIO_STATION = `${BASE_RADIO_STREAMING_URL}/${API}/${RADIO_STATION}`;
const RADIO_STREAMING_API_SETTINGS = `${BASE_RADIO_STREAMING_URL}/${API}/${SETTINGS}/${RADIO_STREAMING}`;
const RADIO_STREAMING_API_EXTRA_MINUTES = `${BASE_RADIO_STREAMING_URL}/${API}/${EXTRA_MINUTES}`;
const RADIO_STREAMING_API_USER = `${BASE_RADIO_STREAMING_URL}/${API}/${USER}`;
const RADIO_STREAMING_API_STATISTICS = `${BASE_RADIO_STREAMING_URL}/${API}/${STATISTICS}`;

const SOUND_MATE_API_VERSION1_SIGN_IN = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${AUTH}`;
const SOUND_MATE_API_VERSION1_SIGN_IN_NEW = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}`;
const SOUND_MATE_API_VERSION1_SIGN_IN_AUTH = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}`;
const SOUND_MATE_API_VERSION1_SIGN_OUT = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${User}`;

const SOUND_MATE_API_VERSION1_SIGN_UP = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${AUTH}`;
const SOUND_MATE_API_VERSION1_PRE_LOGIN = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${PRE_LOGIN}`;
const SOUND_MATE_API_MEMBERSHIP = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${MEMBERSHIP}`;
const SOUND_MATE_API_PAYMENT = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/PaymentProcessors`;
const SOUND_MATE_API_PAYMENTS = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/Payments`;
const SOUND_MATE_API_TRANSFER = `${BASE_SOUND_MATE_URL}/${API}/${TRANSFER}`;
const SOUND_MATE_API_VOUCHER_CODE = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${VOUCHER}`;
const SOUND_MATE_API_REFRESH = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${TOKEN}`;
const SOUND_MATE_API_CONFIRMATION = `${BASE_SOUND_MATE_URL}/${API}`;
const SOUND_MATE_API_VERSION1_USER_VALIDATION = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/user`;
const SOUND_MATE_API_PAYMENT_PROOFS = `${BASE_SOUND_MATE_URL}/${API}/${PAYMENT_PROOFS}`;
const SOUND_MATE_API_DASHBOARD = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${PAYMENTS}`

const SOUND_MATE_API_PAYMENT_PROOF = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/logs/PaymentProofs`;

const SOUND_MATE_API_FILTER = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${YOUTUBE}`;
const SOUND_MATE_API_COMMISSION = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${MEMBERSHIP}/${COMMISSION}`;
const SOUND_MATE_API_VERSION1_CURRENCIES = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${CURRENCIES}`;


const SOUND_MATE_API_EARNING = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${USER}/Earnings`;
const SOUND_MATE_API_MYEARNING = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${USER}/TeamEarnings`;
const SOUND_MATE_API_MYREFERRALS = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${REFERRALS}/${GET_REFERRALS}`;
const SOUND_MATE_API_VOUCHER = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${VOUCHER}/OwnVoucherReport`;
const SOUND_MATE_API_UNLOCKED_LOGS = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${LOGS}/unlockedVideoLogs`;

const SOUND_MATE_API_SETTINGS = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${USER_SETTINGS}`;
const SOUND_MATE_API_SETTINGS_NEW = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${PAYMENTS}`;
const SOUND_MATE_API_USER = `${BASE_SOUND_MATE_URL}/${API}/${USER}`;
const SOUND_MATE_API_VERSION1_LOGS = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${LOGS}`;
const SOUND_MATE_API_PROFILE = `${BASE_SOUND_MATE_URL}/${API}/${PROFILE}`;
const SOUND_MATE_API_VERSION1_MY_TEAM = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${MY_TEAM}`;
const SOUND_MATE_API_VERSION1 = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}`;

const SOUND_MATE_API_REPRESENTATIVE = `${BASE_SOUND_MATE_URL}/${API}/${VERSION1}/${REPRESENTATIVE}`;


@Injectable({
  providedIn: 'platform'
})
export class UrlsService {
  public SOUND_MATE = {
    BASE: BASE_SOUND_MATE_URL,
    API_SIGN_IN: {
      base: SOUND_MATE_API_VERSION1_SIGN_IN,
      blackList: `${SOUND_MATE_API_VERSION1_SIGN_IN}/${BLOCKED_LIST}`,
      singInWithPassword: `${SOUND_MATE_API_VERSION1_SIGN_IN}/${LOGIN}`,
      singOut: `${SOUND_MATE_API_VERSION1_SIGN_OUT}/${LOGOUT}`,
      signInForm: `${SOUND_MATE_API_VERSION1_SIGN_IN}/${GET_LOGIN_FORM}`,
      signInSocial: (social: string) => `${SOUND_MATE_API_VERSION1_SIGN_IN}/${LOGIN_WITH}${social}`,
      GET_RESET_PASSWORD_FORM: `${SOUND_MATE_API_VERSION1_SIGN_IN}/${GET_RESET_PASSWORD_FORM}`,
      SEND_REST_PASSWORD_LINK: `${SOUND_MATE_API_VERSION1_SIGN_IN}/${SEND_REST_PASSWORD_LINK}`,
      GET_NEW_PASS_ON_EMAIL: `${SOUND_MATE_API_VERSION1_SIGN_IN}/${GET_NEW_PASS_ON_EMAIL}`,
      SET_NEW_PASSWORD: `${SOUND_MATE_API_VERSION1_SIGN_IN}/${SET_NEW_PASSWORD}`,
      GET_NEW_PASSWORD_FORM: `${SOUND_MATE_API_VERSION1_SIGN_IN}/${GET_NEW_PASSWORD_FORM}`,
    },
    API_SIGN_UP_V1: {
      base: SOUND_MATE_API_VERSION1_SIGN_UP,
      singUpWithPassword: `${SOUND_MATE_API_VERSION1_SIGN_UP}/${SIGN_UP_WITH_PASSWORD}`,
      signUpWithSocial: (provider: string) => `${SOUND_MATE_API_VERSION1_SIGN_UP}/${SIGN_UP_WITH}${provider}`,
      GET_SIGN_UP_FORM: `${SOUND_MATE_API_VERSION1_SIGN_UP}/${GET_SIGN_UP_FORM}`,
    },
    API_PRE_LOGIN: {
      base: SOUND_MATE_API_VERSION1_PRE_LOGIN,
      PHONE_SETUP_REQUIRED: `${SOUND_MATE_API_VERSION1_PRE_LOGIN}/${PHONE_SETUP_REQUIRED}`,
      LOGIN_SETUP_REQUIRED: `${SOUND_MATE_API_VERSION1_PRE_LOGIN}/${LOGIN_SETUP_REQUIRED}`,
     
      PHONE_CONFIRMATION_REQUIRED: `${SOUND_MATE_API_VERSION1_PRE_LOGIN}/${PHONE_CONFIRMATION_REQUIRED}`,
      IDENTITY_CONFIRMATION_REQUIRED: `${SOUND_MATE_API_VERSION1_PRE_LOGIN}/${IDENTITY_CONFIRMATION_REQUIRED}`,
      PIN_SETUP_REQUIRED: `${SOUND_MATE_API_VERSION1_PRE_LOGIN}/${PIN_SETUP_REQUIRED}`,
      SET_PHONE_NUMBER: `${SOUND_MATE_API_VERSION1_PRE_LOGIN}/${SET_PHONE_NUMBER}`,
      SET_PIN: `${SOUND_MATE_API_VERSION1_PRE_LOGIN}/${SET_PIN}`,
      CONFIRM_PHONE_NUMBER: `${SOUND_MATE_API_VERSION1_PRE_LOGIN}/${CONFIRM_PHONE_NUMBER}`,
      
      CONFIRM_EMAIL: `${SOUND_MATE_API_VERSION1_PRE_LOGIN}/${SEND_CONFIRMATION_EMAIL_CODE}`,
      LOGIN: `${SOUND_MATE_API_VERSION1_PRE_LOGIN}/${LOGIN}`,
      SEND_CONFIRMATION_CODE: `${SOUND_MATE_API_VERSION1_PRE_LOGIN}/${SEND_CONFIRMATION_CODE}`,
      SEND_PHONE_CONFIRM_CODE: `${SOUND_MATE_API_VERSION1_PRE_LOGIN}/${SEND_PHONE_CONFIRM_CODE}`,
    },
    API_REFRESH: {
      base: SOUND_MATE_API_REFRESH,
      refresh: `${SOUND_MATE_API_REFRESH}/${REFRESH}`,
    },
    API_MEMBERSHIP: {
      GET_ALL: `${SOUND_MATE_API_MEMBERSHIP}/${GET_ALL}`,
      UPGRADE: `${SOUND_MATE_API_MEMBERSHIP}`,
      INCOME_CALCULATOR: `${SOUND_MATE_API_MEMBERSHIP}/${INCOME_CALCULATOR}`,
      PLANS_MAIN: `${SOUND_MATE_API_MEMBERSHIP}/${PLANS_MAIN}`,
      BOOSTER_ACCOUNT_CARDS: `${SOUND_MATE_API_MEMBERSHIP}/${BOOSTER_CARDS}`,
      BUY_BOOSTER_PLANS:`${SOUND_MATE_API_MEMBERSHIP}/${BUY_BOOSTER_PLANS}`
    },
    API_USERS: {
      getHistoryLogs: `${SOUND_MATE_API_VERSION1_LOGS}/${HISTORY}`,
      READ_NOTIFICATIONS:`${SOUND_MATE_API_VERSION1_LOGS}/${READ_NOTIFICATIONS_KEY}`
    },
    API_SETTINGS: {
      base: SOUND_MATE_API_SETTINGS,
      getPreferencesSettings: `${SOUND_MATE_API_SETTINGS}/${GET_PREFERENCES_SETTINGS}`,
      updatePreferencesSettings: `${SOUND_MATE_API_SETTINGS}/${UPDATE_PREFERENCES_SETTINGS}`,
      getPaymentSettings: `${SOUND_MATE_API_SETTINGS_NEW}/${GET_PAYMENT_SETTINGS}`,
      updatePaymentSettings: `${SOUND_MATE_API_SETTINGS_NEW}/${UPDATE_PAYMENT_SETTINGS}`,
      updatePassword: `${SOUND_MATE_API_SETTINGS}/${UPDATE_PASSWORD}`,
      updatePin: `${SOUND_MATE_API_SETTINGS}/${UPDATE_PIN}`,
      update2FA: `${SOUND_MATE_API_SETTINGS}/${UPDATE_2FA}`,
      removeAccount: `${SOUND_MATE_API_SETTINGS}/${REMOVE_ACCOUNT}`,
      get2FASettings: `${SOUND_MATE_API_SETTINGS}/${GET_2FA}`,
      getDetailedInfoSettings: `${SOUND_MATE_API_SETTINGS}/${GET_INFO_SETTINGS}`,
      updateDetailedInfoSettings: `${SOUND_MATE_API_SETTINGS}/${UPDATE_INFO_SETTINGS}`,
      updateEmailAddress: `${SOUND_MATE_API_SETTINGS}/${UPDATE_EMAIL_ADDRESS}`,
      updateEmailAddress_verion: `${SOUND_MATE_API_SETTINGS}/${UPDATE_EMAIL_ADDRESS_Version}`,
      updatePhoneNumber: `${SOUND_MATE_API_SETTINGS}/${UPDATE_PHONE_NUMBER}`,
      updatePhoneNumber_version: `${SOUND_MATE_API_SETTINGS}/${UPDATE_PHONE_NUMBER_VERSION}`,
    },
    API_TRANSFER: {
      getTransferMenuForm: `${SOUND_MATE_API_PAYMENTS}/${GET_TRANSFER_MENU_FORM}`,
      getTransferToOwnForm: `${SOUND_MATE_API_PAYMENTS}/${GET_TRANSFER_TO_OWN_FORM}`,
      getTransferToOtherForm: `${SOUND_MATE_API_PAYMENTS}/${GET_TRANSFER_TO_OTHER_FORM}`,
      getTransferPointsForm: `${SOUND_MATE_API_PAYMENTS}/${GET_TRANSFER_POINTS_FORM}`,
      transferToOwn: `${SOUND_MATE_API_TRANSFER}/${TRANSFER_TO_OWN}`,
      transferToOther: `${SOUND_MATE_API_PAYMENTS}/${TRANSFER_TO_OTHER}`,
      transferPoints: `${SOUND_MATE_API_TRANSFER}/${TRANSFER_POINTS}`,
    },
    API_VOUCHER_CODE: {
      base: SOUND_MATE_API_VOUCHER_CODE,
      redeemVoucherCode: `${SOUND_MATE_API_VOUCHER_CODE}/${REDEEM}`,
      buyCode: `${SOUND_MATE_API_VOUCHER_CODE}/${VOUCHER_BUY}`,
      discount: `${SOUND_MATE_API_VOUCHER_CODE}/${DISCOUNT}`,
      getOwn: `${SOUND_MATE_API_VOUCHER_CODE}/${GETOWN}`,
      salesReport: `${SOUND_MATE_API_VOUCHER_CODE}/${SALES_REPORT}`,
      salesReportStat: `${SOUND_MATE_API_VOUCHER_CODE}/${SALES_REPORT_STAT}`,
      transfer: `${SOUND_MATE_API_VOUCHER_CODE}/${TRANSFER}`,
    },
    API_PAYMENT: {
      getWithdrawalPageData: `${SOUND_MATE_API_PAYMENTS}/${WITHDRAWAL_PAGE_DATA}`,
      withdrawMoney: `${SOUND_MATE_API_PAYMENT}/${WITHDRAW}`,
      getWithdrawalHistory: `${SOUND_MATE_API_PAYMENT}/${WITHDRAWAL_HISTORY}`,
      paymentProof: `${SOUND_MATE_API_PAYMENT_PROOF}`,
      getTransactionsHistory: `${SOUND_MATE_API_PAYMENT}/${TRANSACTIONS_HISTORY}`,
      getBoosterHistory: `${SOUND_MATE_API_PAYMENT}/${BOOSTER_HISTORY}`,
      getPaymentProofs: `${SOUND_MATE_API_PAYMENT_PROOFS}/${HISTORY}`,
      dashboard_payments:`${SOUND_MATE_API_DASHBOARD}/${DASHBOARD_PAYMENT}`
    },
    API_FILTER: {
      base: SOUND_MATE_API_FILTER,
      GET_TARGET_BALANCES: `${SOUND_MATE_API_FILTER}/${GET_TARGET_BALANCES}`,
      GET_COUNTRIES: `${SOUND_MATE_API_FILTER}/${GET_ALL_COUNTRIES_New}`,
    },
    API_COMMISSION: {
      base: SOUND_MATE_API_COMMISSION,
      GET_ALL: `${SOUND_MATE_API_COMMISSION}`,
    },
    API_CURRENCIES: {
      base: SOUND_MATE_API_VERSION1_CURRENCIES,
      GET_FIAT_CURRENCIES: `${SOUND_MATE_API_VERSION1_CURRENCIES}/${FIAT}`,
      SET_CURRENCY: `${SOUND_MATE_API_VERSION1_CURRENCIES}`,
    },
    API_DASHBOARD: {
      GET_DASHBOARD_PROFILE_INFO: `${SOUND_MATE_API_PROFILE}/${INFO}`,
      GET_DASHBOARD_REFERRALS: `${SOUND_MATE_API_VERSION1_MY_TEAM}/${GET_DIRECT_REFERRALS}`,
      GET_DASHBOARD_EARNING: `${SOUND_MATE_API_USER}/${EARNING}`,
      GET_DASHBOARD_MEMBERSHIP: `${SOUND_MATE_API_MEMBERSHIP}/${PLANS_MAIN}`,
    },
    API_CONFIRMATION: {
      base: SOUND_MATE_API_CONFIRMATION,
      CONFIRMATION_REQUIRED: `${SOUND_MATE_API_PAYMENTS}/${IS_CONFIRMATION_NEEDED}`,
      CONFIRMATION_SOURCE: `${SOUND_MATE_API_CONFIRMATION}/${CONFIRMATION_PHONE_OR_EMAIL}`,
      SEND_CONFIRMATION_CODE: `${SOUND_MATE_API_CONFIRMATION}/${VERSION1}/${PAYMENTS}/${CONFIRM_USER_ACTION}`,
      UPDATE_EMAIL: `${SOUND_MATE_API_CONFIRMATION}/${UPDATE_EMAIL}`,
      EMAIL_CONFIRM: `${SOUND_MATE_API_CONFIRMATION}/${EMAIL_CONFIRM}`,
    },
    API_VALIDATION: {
      base: SOUND_MATE_API_VERSION1_USER_VALIDATION,
      pinCheck: `${SOUND_MATE_API_VERSION1_USER_VALIDATION}/${PIN}`,
      pinCheck_Version: `${SOUND_MATE_API_VERSION1_USER_VALIDATION}/${PIN_Version}`
    },
    API_REFERRALS: {
      GET_MENU: `${SOUND_MATE_API_VERSION1_MY_TEAM}/${MY_TEAM_MENU}`,
      DIRECT_REFERRALS: `${SOUND_MATE_API_VERSION1_MY_TEAM}/${GET_DIRECT_REFERRALS}`,
      DIRECT_REFERRALS_STATS: `${SOUND_MATE_API_VERSION1_MY_TEAM}/${GET_DIRECT_REFERRALS_STATS}`,
      REFERRAL_OPTIONS: `${SOUND_MATE_API_VERSION1_MY_TEAM}/${GET_DIRECT_REFERRAL_OPTIONS}`,
      PROMO_MATERIALS: `${SOUND_MATE_API_VERSION1_MY_TEAM}/${GET_PROMO_MATERIALS}`,
      PROFILE: `${SOUND_MATE_API_VERSION1_MY_TEAM}/${GET_REFERRAL_PROFILE_INFO}/`,
    },
    API_REPRESENTATIVE: {
      GET_MENU: `${SOUND_MATE_API_REPRESENTATIVE}/${REPRESENTATIVE_MENU}`,
      GET_COUNTRIES: `${SOUND_MATE_API_FILTER}/${REPRESENTATIVE_COUNTRIES}`,
      GET_REPRESENTATIVES: `${SOUND_MATE_API_REPRESENTATIVE}/${REPRESENTATIVES}`,
      REPRESENTATIVES_CREAT: `${SOUND_MATE_API_REPRESENTATIVE}`,
      GET_WHETER_REPRESENTATIVE: `${SOUND_MATE_API_REPRESENTATIVE}/${IS_SUBMIT_FORM}`,
      GET_APPLICATION_HISTORY : `${SOUND_MATE_API_REPRESENTATIVE}/${GET_APPLICATION_HISTORY}`
    },
    API_STATISTICS: {
      GET_MENU: `${SOUND_MATE_API_REPRESENTATIVE}/${REPRESENTATIVE_MENU}`,
      EARNING: `${SOUND_MATE_API_EARNING}`,
      MYEARNING: `${SOUND_MATE_API_MYEARNING}`,
      MYREFERRALS: `${SOUND_MATE_API_MYREFERRALS}`,
      VOUCHER_HISTORY: `${SOUND_MATE_API_VOUCHER}`,
      UNLOCKED_LOGS: `${SOUND_MATE_API_UNLOCKED_LOGS}`,
      balances: `${SOUND_MATE_API_VERSION1}/logs/${STATISTICS}`,
      activity: `${SOUND_MATE_API_VERSION1}/logs/activities`
    },
    // /api/Login/Google2FAAuthenticationURLforQR
    // GET /api/Login/Google2FAVerificationQR
    API_G2FA: {
      GET_G2FA_QR_CODE: `${SOUND_MATE_API_VERSION1_SIGN_IN_AUTH}/${G2FAQR_LINK_USER}/${G2FAQR_LINK}`,
      GET_G2FA_VERIFICATION_QR_NEW: `${SOUND_MATE_API_VERSION1_SIGN_IN_NEW}/${G2FAQR_LINK_USER}/${G2FA_VERIFICATION_QR}`,
      GET_G2FA_VERIFICATION_QR: `${SOUND_MATE_API_VERSION1_SIGN_IN}/${G2FA_VERIFICATION_QR}`
    }
  };

  public RADIO_STREAMING = {
    BASE: BASE_RADIO_STREAMING_URL,
    API_GENRE: {
      base: RADIO_STREAMING_API_GENRE,
      stationByGenre: (id: string) => `${RADIO_STREAMING_API_GENRE}/${id}/${RADIO_STATION}s`,
    },
    API_LISTENING: {
      base: RADIO_STREAMING_API_LISTENING,
      listeningDetails: `${RADIO_STREAMING_API_LISTENING}/${DETAILS}`,
      listenedPaidMinutes: `${RADIO_STREAMING_API_LISTENING}/${LISTENED_PAID_MIN}`,
      updateListening: (id: string) => `${RADIO_STREAMING_API_LISTENING}/${id}/${UPDATE}`,
    },
    API_RADIO: {
      base: RADIO_STREAMING_API_RADIO_STATION,
      favoriteStations: `${RADIO_STREAMING_API_RADIO_STATION}/${FAVORITE}`,
      stationFavoriteById: (id: string) => `${RADIO_STREAMING_API_RADIO_STATION}/${id}/${FAVORITE}`,
      stationRating: (id: string) => `${RADIO_STREAMING_API_RADIO_STATION}/${id}/${RATING}`,
    },
    API_SETTINGS: {
      base: RADIO_STREAMING_API_SETTINGS,
      guideShown: `${BASE_RADIO_STREAMING_URL}/${API}/${SETTINGS}/${RADIO_STREAMING}/${GUIDE_SHOWN}`,
      genresSelectionShown: `${BASE_RADIO_STREAMING_URL}/${API}/${SETTINGS}/${RADIO_STREAMING}/${GENRES_SELECTION_SHOWN}`,
    },
    API_USER: {
      base: RADIO_STREAMING_API_USER,
      details: `${RADIO_STREAMING_API_USER}/${DETAILS}`
    },
    API_STATISTICS: {
      base: RADIO_STREAMING_API_STATISTICS,
      balance: {
        radioStreaming: `${RADIO_STREAMING_API_STATISTICS}/${BALANCE}/${RADIO_STREAMING}`
      },
    },
    API_EXTRA_MINUTES: {
      base: RADIO_STREAMING_API_EXTRA_MINUTES,
      details: `${RADIO_STREAMING_API_EXTRA_MINUTES}/${DETAILS}`,
      buy: `${RADIO_STREAMING_API_EXTRA_MINUTES}/${BUY}`,
    },

    SIGNAL_R: {
      listening: `${BASE_RADIO_STREAMING_URL}/${LISTENING}`,
      streaming: `${BASE_RADIO_STREAMING_URL}/${RADIO_STREAMING}`,
    }
  };


  public AUTH_EXCLUDE = [
    /* TODO: Add other urls without authentication to this collection */
    this.SOUND_MATE.API_REFRESH.refresh,
  ];
}
