import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  API_BALANCE_WALLETS_SETTINGS,
  API_USER_VERSION1_ACTIVITIES,
  API_USER_VERSION1_BALANCES,
  API_USER_VERSION1_DETAILS,
  API_VERSION1_GET_USERNAME,
  API_VERSION1_REF_DETAILS,
  API_VERSION1_USER_EXIST,
  API_VERSION1_USER_NAME,
  API_VERSION1_USER_SET_PIN_NUMBER,
  API_VERSION1_USER_SET_USERNAME,
  BASE_IP_CURRENCY_NEW_URL,
  BASE_IP_CURRENCY_URL,
  BASE_SOUND_MATE_URL,
  CASHOUT_API,
  LOGIN,
  MY_RANK,
  TRANSFER_CASHOUT
} from '@constants/request.const';
import { IUserDetails } from '@interfaces/header.interface';
import { IUserActivity, IUserBalance, IUserBalanceCheck } from '@interfaces/payment/deposit-page-data.interface';
import { UserHistoryData } from '@interfaces/users/user-history.interface';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthManagerService } from './auth-manager.service';
import { AuthService } from './auth.service';
import { CurrencyService } from './currency.service';
import { LocalStorageService } from './local-storage.service';
import { UrlsService } from './urls.service';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {
  private timeout: any;
  private readonly inactivityTime =  21600000 ;
  currencyCode: string;
  myRanks = new BehaviorSubject(null);
  activityCountUpdated = new Subject();
  constructor(
    private readonly http: HttpClient,
    private readonly urls: UrlsService,
    private readonly currencyService: CurrencyService,private router: Router,private storage: LocalStorageService,    private readonly auth: AuthService,
    private readonly authManager: AuthManagerService,
  ) {
    this.currencyCode = this.currencyService.getCurrentCurrency()?.code;
    window.addEventListener('mousemove',()=>this.resetTimer())
    window.addEventListener('keydown',()=>this.resetTimer())
  }

  public getUserHeaderDetails(code?: string): Observable<IUserDetails> {
    const params = new HttpParams()
      .set('currencyCode', String(this.currencyCode? this.currencyCode : code));
    return this.http.get<IUserDetails>(`${BASE_SOUND_MATE_URL}${API_USER_VERSION1_DETAILS}`, { params });
  }

  public getUserBalances(code?: string): Observable<IUserBalance[]> {
 
    const params = new HttpParams()
      .set('currencyCode', String(this.currencyCode ? this.currencyCode  : code));
    return this.http.get<IUserBalance[]>(`${BASE_SOUND_MATE_URL}${API_USER_VERSION1_BALANCES}`, { params });
  }

  public getUserActivities(periodType: string): Observable<IUserActivity[]> {
    const params = new HttpParams()
      .set('periodType', String(periodType))
      .set('currencyCode', String(this.currencyCode));
    return this.http.get<IUserActivity[]>(`${BASE_SOUND_MATE_URL}${API_USER_VERSION1_ACTIVITIES}`, { params });
  }

  public getUserName(): Observable<string> {
    return this.http.get<string>(`${BASE_SOUND_MATE_URL}${API_VERSION1_USER_NAME}`);
  }

  public getUserHistory(pageNumber: number, pageSize: number): Observable<any> {
    const params = new HttpParams()
      .set('pageNumber', String(pageNumber))
      .set('pageSize', String(pageSize));
    return this.http.get<UserHistoryData>(this.urls.SOUND_MATE.API_USERS.getHistoryLogs, { params,observe: 'response' });
  }


  public getUserNameById(id: string, type: string): Observable<string> {
    return this.http.get<string>(`${BASE_SOUND_MATE_URL}${API_VERSION1_GET_USERNAME}?id=${id}&socialType=${type.toLowerCase()}`);
  }

  public isUserExist(username: string) {
    return this.http.get<boolean>(`${BASE_SOUND_MATE_URL}${API_VERSION1_USER_EXIST}?userName=${username}`);
  }

  public setUserName(username: string, id: string, type: string): Observable<void> {
    return this.http.post<void>(`${BASE_SOUND_MATE_URL}${API_VERSION1_USER_SET_USERNAME}?id=${id}`, {
      "username": username,
      "socialType": type.toLowerCase()
    });
  }

  public setPinNumber(pinValue: string): Observable<void> {
    return this.http.post<void>(`${BASE_SOUND_MATE_URL}${API_VERSION1_USER_SET_PIN_NUMBER}`, {
      "oldPin": pinValue,
      "newPin": pinValue
    });
  }
  
  public getBalanceSettings(): Observable<IUserBalanceCheck[]> {
    return this.http.get<IUserBalanceCheck[]>(`${BASE_SOUND_MATE_URL}${API_BALANCE_WALLETS_SETTINGS}`);
  }

  public getUserDetails(): Observable<void> {
    return this.http.get<void>(`${BASE_SOUND_MATE_URL}${API_VERSION1_REF_DETAILS}`);
  }

  public getIPCurrencies(): Observable<any> {
    return this.http.get<any>(`${BASE_IP_CURRENCY_URL}`);
  }

  public getNewIPCurrencies(ip): Observable<any> {
    const ipUrl = '?access_key=4f6d88ddb75c9bf9e994798636f4b8bb'
    return this.http.get<any>(`${BASE_IP_CURRENCY_NEW_URL}${ip}${ipUrl}`);
  }
  resetTimer() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.storage.removeLocalStorageItem('sociallogin');
      this.storage.removeLocalStorageItem('welcomeMessage');
      this.storage.removeLocalStorageItem('CURRENCY');
      this.auth.signOut(this.authManager.refreshToken).pipe(
      finalize(() => {
          this.authManager.clearAuthData();
          this.router.navigate([LOGIN]);
        })
      ).subscribe();
    }, this.inactivityTime);
 
  }
  getUserRankDetails(): Observable<any> { 
  return  this.http.get(`${BASE_SOUND_MATE_URL}${MY_RANK}`)
  }
  getActivityStatus(pageNumber: number, pageSize: number,isRead:boolean = true): Observable<any> {
    const params = new HttpParams()
      .set('pageNumber', String(pageNumber))
      .set('pageSize', String(pageSize))
      .set('isRead', String(isRead))
    return this.http.get<UserHistoryData>(this.urls.SOUND_MATE.API_USERS.getHistoryLogs, { params,observe: 'response' });
  }
  readNotifications(): Observable<any> {
    const params = new HttpParams().set("isRead", String(true));
    return this.http.post(this.urls.SOUND_MATE.API_USERS.READ_NOTIFICATIONS, {params});
  }
  TransferToOthe(currencyCode){
    const params = new HttpParams()
    .set('currencyCode',currencyCode)
    return this.http.get(`${BASE_SOUND_MATE_URL}${CASHOUT_API}`,{params})
  }
  transferToCashout(code,count){
    const headers = new HttpHeaders()
      .set('Code', String(code));
    return this.http.post(`${BASE_SOUND_MATE_URL}${TRANSFER_CASHOUT}?cashoutVideos=${count}`,null,{headers})
  }
}
