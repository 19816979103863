import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IconsClass } from '@assets/images/icons.class';
import { ButtonModes, ButtonSizes } from '@kit/button/button.const';
import { FieldSize } from '@kit/controls/readonly-field/readonly-field.const';
import { TranslationsService } from '@services/translations.service';

@Component({
  selector: 'skd-welcome-message-dialog',
  templateUrl: './welcome-message-dialog.component.html',
  styleUrls: ['./welcome-message-dialog.component.scss']
})
export class WelcomeMessageDialogComponent implements OnInit {

  public static width = '660px';
  public static hight = '800px';
  public content;
  public title;

  public successCircleSvg = IconsClass.successCircleSvg;

  public fieldSize = FieldSize;
  public buttonModes = ButtonModes;
  public buttonSizes = ButtonSizes;

  constructor(@Inject(MAT_DIALOG_DATA) public data: WelcomeMessageDialogComponent,
    private dialogRef: MatDialogRef<WelcomeMessageDialogComponent>,
    public readonly translationsService: TranslationsService) {
    this.content = data.content;
    this.title = data.title;
  }

  ngOnInit(): void {
  }

}
