import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  enableplan = new Subject();
  public userHeaderDetails$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  constructor() { }
}
