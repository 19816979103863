<div class="menu-selector">
  <button mat-icon-button
          [disableRipple]="disableRipple"
          [matMenuTriggerFor]="menu"
          (menuClosed)="isMenuOpen(arrow.down)"
          (menuOpened)="isMenuOpen(arrow.up)">
    <mat-icon>{{ icon }}</mat-icon>
  </button>

  <mat-menu #menu="matMenu"
            xPosition="before"
            [overlapTrigger]="overlapTrigger">
    <button *ngFor="let item of menuList"
            class="menu-selector-button"
            mat-menu-item
            (click)="onMenuClick(item.id)">
      <span class="menu-selector-button-item">
        <span class="menu-selector-button-item-icon" [innerHTML]="item.icon | safe"></span>

        <span>{{ item.name | translate }}</span>
      </span>
    </button>
  </mat-menu>
</div>
