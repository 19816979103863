export enum LoginPolicy {
  username = 0,
  email = 1,
  usernameAndEmail = 2
}

export enum SocialLoginType {
  facebook = 0,
  google = 1,
  twitter = 2
}

export const SOCIAL_NAME_BY_TYPE = {
  [SocialLoginType.facebook]: 'Facebook',
  [SocialLoginType.google]: 'Google',
  [SocialLoginType.twitter]: 'Twitter',
};
