import { Component, Input, OnInit } from '@angular/core';
import { ICurrency } from '@interfaces/currency.interface';
import { IOption } from '@interfaces/selects.interface';
import { CurrenciesService } from '@services/currencies.service';
import { CurrencyService } from '@services/currency.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'skd-select-currency',
  templateUrl: './select-currency.component.html',
  styleUrls: ['./select-currency.component.scss']
})
export class SelectCurrencyComponent implements OnInit {
@Input() currencyData:any;
@Input() currency:any;
public currencyOptions: IOption<string>[] = [];
public currenciesByCode = new Map<string, ICurrency>();
searchValue;
setCurrency;
currentCurrencyCode;

public selectedCurrencyOption$ = new BehaviorSubject<IOption<string>>(null);
  constructor(private currenciesService: CurrenciesService,  private currencyService: CurrencyService) { }

  ngOnInit(): void {
    this.currentCurrencyCode = this.currencyService.getCurrentCurrency()?.code;
    let obj = JSON.parse(JSON.stringify(this.currency))
    obj.code = this.currency.name
    this.setCurrency = obj
    this.currenciesByCode  = this.currencyData.reduce((currMap, value) => currMap.set(value.code, value), new Map<string, ICurrency>());
        this.currencyOptions = this.currencyData.map(c => ({
          name: `${c.code} (${c.sign})`,
          id: c.code
        }));
        this.currencyOptions = this.currencyOptions.sort((a, b) => a.name.localeCompare(b.name));
        const selectedCurrency = this.currenciesByCode.get(this.currentCurrencyCode);
        this.currencyService.setCurrency(selectedCurrency);
        const selectedOption = this.currencyOptions.find(c => c.id === this.currentCurrencyCode);
        this.selectedCurrencyOption$.next(selectedOption);
  }
  filterDropdown(e){
    let searchString = e.toLowerCase();
    if (!searchString) {
      this.currencyOptions = this.currencyData.slice();
      return;
    } else {
      this.currencyOptions = this.currencyData.filter(user => user.code.toLowerCase().indexOf(searchString) > -1 );
   
    }
  }
  public onCurrencySelect(currency: IOption<string>): void {
    this.currenciesService.setCurrency(currency.id).pipe()
    .subscribe((data) => {
     this.currenciesByCode.get(currency.id);
      this.currencyService.setCurrency(this.currenciesByCode.get(currency.id));
      window.location.reload();
    },
      err => {
    
        if (err?.error?.defaultErrorMsgKey) {
          // this.dynamicTrans.dynamicTranslationService(err.error.defaultErrorMsgKey);
        }
      }
     
    );

  }
  selectValue(data){
   let obj = JSON.parse(JSON.stringify(data))
   obj.name = data.code
   let dat = JSON.parse(JSON.stringify(obj))
   this.setCurrency = dat;
   this.currencyService.setCurrency(data);
  }


}
