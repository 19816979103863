<div class="cookie-notification_wrapper">
  <div class="cookie-notification_title">
    {{ translations.COOKIE_NOTIFICATION.TITLE | translate }}
  </div>
  <div class="cookie-notification_content">
    <div class="cookie-notification_message">
      {{ translations.COOKIE_NOTIFICATION.MESSAGE | translate }}
    </div>
    <div class="cookie-notification_actions">
      <button skdButton size="small" (click)="accept()">
        {{ translations.COOKIE_NOTIFICATION.SEND_BUTTON | translate }}
      </button>
    </div>
  </div>
</div>

<mat-icon class="close-icon"
          svgIcon="close-icon"
          (click)="close()">
</mat-icon>
