import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { TranslationsService } from '@services/translations.service';


@Component({
  selector: 'skd-toaster-dialog',
  templateUrl: './toaster-dialog.component.html',
  styleUrls: ['./toaster-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToasterDialogComponent implements OnInit {

  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ToasterDialogComponent>,public translations: TranslationsService) { }

  ngOnInit(): void {
    setTimeout(()=>this.closeDialog(), 4000);
  }
  closeDialog(){
    this.dialogRef.close();
  }
}
