import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICurrency } from '@interfaces/currency.interface';
import { Observable } from 'rxjs';
import { UrlsService } from './urls.service';

@Injectable({
  providedIn: 'root'
})
export class CurrenciesService {
  constructor(
    private readonly http: HttpClient,
    private readonly urls: UrlsService,
  ) { }

  public getCurrencies(): Observable<ICurrency[]> {
    return this.http.get<ICurrency[]>(this.urls.SOUND_MATE.API_CURRENCIES.GET_FIAT_CURRENCIES);
  }

  public setCurrency(currencyCode: string) {
    const params = new HttpParams()
      .set('currencyCode', currencyCode);

    return this.http.post(this.urls.SOUND_MATE.API_CURRENCIES.SET_CURRENCY, { params }, { responseType: 'text' });
  }
}
