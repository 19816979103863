import { FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { SocialLoginType } from './auth-options.const';

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const COOKIE_ACCEPTED = 'cookieAccepted';

export const SOCIAL_LOGIN_NAME_BY_TYPE = {
  [SocialLoginType.facebook]: FacebookLoginProvider,
  [SocialLoginType.google]: GoogleLoginProvider,
};
