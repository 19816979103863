import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ToasterService {
  constructor(
    private translateService: TranslateService,
    private toastrService: ToastrService,
  ) {}

  public success(message: string, options?: string | Object): void {
    const translated = this.translateMessage(message, options);
    this.toastrService.success(translated, '', {timeOut: 5000});
  }

  public warning(message: string, options?: string | Object): void {
    const translated = this.translateMessage(message, options);
    this.toastrService.warning(translated, '', {timeOut: 5000});
  }

  public error(message: string, options?: string | Object): void {
    const translated = this.translateMessage(message, options);
    this.toastrService.error(translated, '', {timeOut: 5000});
  }

  private translateMessage(message: string, options: Object): string {
    const translateOptions = typeof options === 'string'
      ? { value: options }
      : options;

    return this.translateService?.instant(message, translateOptions);
  }
}
