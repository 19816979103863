import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { COOKIE_ACCEPTED } from '@constants/auth.const';
import { PORTAL_NAMES } from '@constants/portal.const';
import { SkdPortalService } from '@kit/portal/portal.service';
import { CookieNotificationComponent } from '@pages/welcome/cookie-notification/cookie-notification.component';
import { LocalStorageService } from '@services/local-storage.service';
import { LOADING_NAMES } from './core/scadasoft-kit/loading/loading.const';

@Component({
  selector: 'skd-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  title = 'Scadasoft-user-panel';
  public portalName = PORTAL_NAMES.COOKIE_NOTIFICATION;
  public spinnerName = LOADING_NAMES.MAIN;

  constructor(
    private portalService: SkdPortalService,
    private storageService: LocalStorageService,
  ) {}

  public ngOnInit(): void {
    this.portalService.setComponent(CookieNotificationComponent, this.portalName);
    this.initCookieNotification();
  }

  private initCookieNotification(): void {
    const cookieAccepted = this.storageService.getLocalStorageItem(COOKIE_ACCEPTED);

    if (cookieAccepted) {
      return;
    }

    this.portalService.show(this.portalName);
  }
}
