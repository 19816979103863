import { Injectable } from '@angular/core';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '@constants/auth.const';
import {IPreLoginResponse } from '@interfaces/auth.interface';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthManagerService {
  private _accessToken: string = null;
  private _refreshToken: string = null;

  public get accessToken(): string {
    return this._accessToken;
  }

  public get refreshToken(): string {
    return this._refreshToken;
  }

  public get isAuthorized(): boolean {
    return Boolean(
      this._accessToken ||
      this.storageService.getLocalStorageItem(ACCESS_TOKEN)
    );
  }

  constructor(
    private storageService: LocalStorageService
  ) {
    this._accessToken = this.storageService.getLocalStorageItem(ACCESS_TOKEN);
    this._refreshToken = this.storageService.getLocalStorageItem(REFRESH_TOKEN);
  }

  public setAuthData(data: IPreLoginResponse): void {
    this.setAccessToken(data.authToken);
    this.setRefreshToken(data.refreshToken);
  }

  public clearAuthData(): void {
    this.clearAccessToken();
    this.clearRefreshToken();
  }

  public setAccessToken(token: string): void {
    this._accessToken = token;
    this.storageService.setLocalStorageItem(ACCESS_TOKEN, token);
  }

  public setRefreshToken(token: string): void {
    this._refreshToken = token;
    this.storageService.setLocalStorageItem(REFRESH_TOKEN, token);
  }

  private clearAccessToken(): void {
    this._accessToken = null;
    this.storageService.removeLocalStorageItem(ACCESS_TOKEN);
  }

  private clearRefreshToken(): void {
    this._refreshToken = null;
    this.storageService.removeLocalStorageItem(REFRESH_TOKEN);
  }
}
