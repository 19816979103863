import { TranslationsService } from '@services/translations.service';
import { ISideNav } from '@interfaces/side-nav.interface';
import {
  CALCULATOR,
  DIRECT,
  MONEY,
  MEMBERSHIP,
  PROMO, EARN_RADIO, REPRESENTATIVES,
  USER,
  FINANCES, REFERRALS, EARN_YOUTUBE, MEMBERSHIP_PLANS, MEMBERSHIP_INCOME_CALCULATOR, STATISTICS, SETTINGS, EARN_MOVIE, SUBSCRIPTION_PLANS, INCOME_CALCULATOR, RADIO, RADIO_NAME, YOUTUBE_NAME, MOVIE_NAME, DEPOSIT_NAME, TRANSFER_NAME, WITHDRAW_NAME, VOUCHER_NAME, EXTRA_MINUTES_NAME, LOGS_NAME, DIRECT_REFERRALS, INDIRECT_REFERRALS, DIRECT_REF, PROMO_REF, COUNTRY_AGENT, MANAGE_CODE, SALES_REPORT, MANAGE_CODE_LINK, SALES_REPORT_LINK, BUY_AIRTIME_CODES, BUY_AIRTIME_CODES_LINK, MY_PROFILES, ACTIVITY_LOG, APPLICATION_FORM, APPLICATION_FORM_LINK, COUNTRY_CODE_LINK, USER_INFO, PROMO_TREE_CHART, MEMBERSHIP_BOOSTER_ACCOUNT, TUTORIALS_LINK, BOOSTER_ACCOUNT, Referral_Calculator,  Referral_Competition, Leaderboard,
  UNLOCKED_VIDEO_LOGS_LINK,
  PAID_CATEGORYS, 
} from '@constants/routes.const';
import { IconsClass } from '@assets/images/icons.class';

const translate = new TranslationsService();

export const NUV_LIST: ISideNav[] = [
  {
    name: translate.Dashboard,
    icon: '<i class="fa-solid fa-house"></i>',
    link: '/user',
    active: false,
    visible: true,
    list: [
      {
        name:translate.Dashboard,
        label: translate.SIDE_NAV.SUMMARY,
        link: '/user',
        visible: true
      }
    ]
  },
  {
    name: translate.Membership,
    icon: '<i class="fa-solid fa-users"></i>',
    link: `${MEMBERSHIP}/${MEMBERSHIP_PLANS}`,
    active: false,
    visible: true,
    list: [
      {
        name :translate.Subscription_Plans,
        label: translate.SIDE_NAV.SUBSCRIPTION_PLANS.PRICING,
        link: `${MEMBERSHIP}/${MEMBERSHIP_PLANS}`,
        visible: true
      },
      {
        name:translate.Income_Calculator,
        label: translate.SIDE_NAV.SUBSCRIPTION_PLANS.INCOME_CALCULATOR,
        link: `${MEMBERSHIP}/${MEMBERSHIP_INCOME_CALCULATOR}`,
        visible: true
      },
     
    ]
  },
  {
    name:translate.Rate_Earn,
    icon: '<i class="fa-solid fa-money-bill"></i>',
    link: EARN_RADIO,
    active: false,
    visible: true,
    list: [
      {
        name:translate.Radio,
        label: translate.SIDE_NAV.RATE_EARN.RADIO,
        link: EARN_RADIO,
        visible: true
      },
      {
        name:translate.Youtube,
        label: translate.SIDE_NAV.RATE_EARN.YOUTUBE,
        link: EARN_YOUTUBE,
        visible: true,
      }, 
      
      {
        name: translate.Insta_Likes,
        label: '',
        link: PAID_CATEGORYS,
        visible: true
      },
      {
        name: translate.Insta_Follows,
        label: '',
        link: PAID_CATEGORYS,
        visible: true
      },
      {
        name: translate.Insta_Views,
        label: '',
        link: PAID_CATEGORYS,
        visible: true
      },
      {
        name: translate.Insta_Reels_Views,
        label: '',
        link: PAID_CATEGORYS,
        visible: true
      },
      {
        name: translate.Tiktok_Followers,
        label: '',
        link: PAID_CATEGORYS,
        visible: true
      },
      {
        name: translate.Tiktok_Likes,
        label: '',
        link: PAID_CATEGORYS,
        visible: true
      },
      {
        name: translate.Tiktok_Views,
        label: '',
        link: PAID_CATEGORYS,
        visible: true
      },
      {
        name: translate.YT_Subscribers,
        label: '',
        link: PAID_CATEGORYS,
        visible: true
      },
      {
        name: translate.YT_Likes,
        label: '',
        link: PAID_CATEGORYS,
        visible: true
      },
      {
        name: translate.YT_Watch_Hours,
        label: '',
        link: PAID_CATEGORYS,
        visible: true
      },
      {
        name: translate.Forum_Reviews,
        label: '',
        link: PAID_CATEGORYS,
        visible: true
      },
      {
        name: translate.Join_A_Community,
        label: '',
        link: PAID_CATEGORYS,
        visible: true
      },
      {
        name: translate.App_Reviews,
        label: '',
        link: PAID_CATEGORYS,
        visible: true
      },
      {
        name: translate.Game_Reviews,
        label: '',
        link: PAID_CATEGORYS,
        visible: true
      },
      {
        name: translate.Web_Reviews,
        label: '',
        link: PAID_CATEGORYS,
        visible: true
      },
      {
        name:translate.Movie,
        label: translate.SIDE_NAV.RATE_EARN.MOVIE,
        link: EARN_MOVIE,
        visible: true,
      },
      {
        name:translate.Booster_Account,
        label: translate.SIDE_NAV.SUBSCRIPTION_PLANS.INCOME_CALCULATOR,
        link: BOOSTER_ACCOUNT,
        visible: true
      },
      {
        name:translate.Tutorials,
        label: translate.SIDE_NAV.RATE_EARN.TUTORIAL,
        link: TUTORIALS_LINK,
        visible: true,
      },
    ]
  },
  {
    name:translate.Referrals,
    icon: '<i class="fa-sharp fa-solid fa-person"></i>',
    link: REFERRALS,
    active: false,
    visible: true,
    list: [
      {
        name:translate.Direct_referrals,
        label: translate.SIDE_NAV.MY_TEAM.DIRECT,
        link: DIRECT_REF,
        visible: true
      },
      {
        name:translate.Indirect_referrals,
        label: translate.SIDE_NAV.MY_TEAM.CHART,
        link: PROMO_TREE_CHART,
        visible: true
      },
      {
        name:translate.Promo_Tools,
        label: translate.SIDE_NAV.MY_TEAM.PROMO,
        link: PROMO_REF,
        visible: true
      },
      {
        name:translate.Referral_Calculator,
        label: translate.SIDE_NAV.MY_TEAM.CAL,
        link: Referral_Calculator,
        visible: true
      },
      {
        name:translate.Referral_Competition,
        label: translate.SIDE_NAV.MY_TEAM.COMPETITION,
        link: Referral_Competition,
        visible: true
      },
      {
        name:translate.Unlocked_Video_Logs,
        label: translate.SIDE_NAV.RATE_EARN.UNLOCKED_VIDEO_LOGS,
        link: UNLOCKED_VIDEO_LOGS_LINK,
        visible: true,
      }
    ]
  },
  {
    name: translate.Finance,
    icon: '<i class="fa-solid fa-bookmark"></i>',
    link: FINANCES,
    visible: true,
    active: false,
    list: [
      {
        name:translate.Deposit,
        label: translate.SIDE_NAV.MY_MONEY.DEPOSIT,
        link: 'deposit',
        visible: true
      },
      {
        name:translate.Send,
        label: translate.SIDE_NAV.MY_MONEY.TRANSFER,
        link: 'send',
        visible: true
      },
      {
        name:translate.Withdraw,
        label: translate.SIDE_NAV.MY_MONEY.WITHDRAW,
        link: 'withdraw',
        visible: true
      },
      {
        name:translate.Vouchers,
        label: translate.SIDE_NAV.MY_MONEY.VOUCHER,
        link: 'vouchers',
        visible: true
      },
      {
        name:translate.Extra_Minutes,
        label: translate.SIDE_NAV.MY_MONEY.EXTRA_MINUTES,
        link: FINANCES,
        visible: true
      },
      {
        name:translate.Logs,
        label: translate.SIDE_NAV.MY_MONEY.LOGS,
        link: 'logs',
        visible: true
      },
      {
        name:translate.Payment_Proofs,
        label: translate.SIDE_NAV.MY_MONEY.PAYMENT_PROOFS,
        link: 'payment-proofs',
        visible: true
      }
    ]
  },
  {
    name: translate.Representatives,
    icon: '<i class="fa-solid fa-book-open-reader"></i>',
    link: REPRESENTATIVES,
    active: false,
    visible: true,
    list: [
      {
        name:translate.Country_Agent,
        label: translate.SIDE_NAV.LEADERS.COUNTRY,
        link:  COUNTRY_CODE_LINK,
        // link: REPRESENTATIVES,
        visible: true
      },
      
        {
          name:translate.Manage_Code,
          label: translate.SIDE_NAV.LEADERS.COUNTRY,
          link: MANAGE_CODE_LINK,
          visible: true
        },

        {
          name:translate.Sales_Report,
          label: translate.SIDE_NAV.LEADERS.COUNTRY,
          link: SALES_REPORT_LINK,
          visible: true
        },
        {
          name:translate.Buy_Voucher_Codes,
          label: translate.SIDE_NAV.LEADERS.COUNTRY,
          link: BUY_AIRTIME_CODES_LINK,
          visible: true
        },
        {
          name:translate.Application_Form,
          label: translate.SIDE_NAV.LEADERS.COUNTRY,
          link: APPLICATION_FORM_LINK,
          visible: true
        }
      
    ]
  },
  // {
  //   name: translate.Statistics,
  //   icon: '<i class="fe fe-award">',
  //   link: '',
  //   active: false,
  //   visible: true,
  //   list: []
  // },
  {
    name: translate.Top_members,                          // top memberShip
    icon:'<i class="fa-solid fa-chart-simple"></i>',
    link: 'statistics/my-earning',
    active: false,
    visible: true,
    list: [
      {
        name:translate.statistics,
        label: translate.SIDE_NAV.STATISTICS.MY_MONEY,
        link:'statistics/my-earning',
        visible: true
      }
    ]
  },
  {
    name: translate.Leaderboard,
    icon:'<i class="fa-solid fa-medal"></i>',
    link:Leaderboard,
    active: false,
    visible: true,
    list: [
      {
        name:translate.Top_10_Today_Earners,
        label: translate.SIDE_NAV.LEADERBOARD.Top_10_Today_Earners,
        link:'leader-board/top-ten-earners/top-ten-earner',
        visible: true
      },
      {
        name:translate.Top_10_Last_7_Days_Earners,
        label: translate.SIDE_NAV.LEADERBOARD.Top_10_Last_7_Days_Earners,
        link:'leader-board/top-ten-ear-days/last-seven-days-earn',
        visible: true
      },
      {
        name:translate.Top_10_Referrers_Today,
        label: translate.SIDE_NAV.LEADERBOARD.Top_10_Referrers_Today,
        link:'leader-board/top-ten-referrals/top-ten-ref-view',
        visible: true
      },
      {
        name:translate.Top_10_Last_7_Days_Referrers,
        label: translate.SIDE_NAV.LEADERBOARD.Top_10_Last_7_Days_Referrers,
        link:'leader-board/top-ten-ref-days/top-ten-ref-days-dash',
        visible: true
      }
    ]
  },

  {
    name: translate.Settings,
    icon:'<i class="fa-sharp fa-solid fa-gear"></i>',
    link:SETTINGS,
    active: false,
    visible: true,
    list: [
      {
        name:translate.User_Info,
        label: translate.SIDE_NAV.SETTINGS.SETTINGS,
        link:'settings/user-info',
        visible: true
      },
      {
        name:translate.My_Profile,
        label: translate.SIDE_NAV.SETTINGS.SETTINGS,
        link:'my-profile',
        visible: true
      },
      {
        name:translate.Activity_Logs,
        label: translate.SIDE_NAV.SETTINGS.SETTINGS,
        link:'activity-logs',
        visible: true
      },
      
    ]
  }
];
