
<div class="dropdown h-100" class="statusDropdownContainer">
	<a class="btn btn-white dropdown-toggle tatusFilterDropdown h-100 flex-middle" href="#" role="button"
		id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
		<span class="text-truncate"> {{currency ? setCurrency?.name : currency?.name}}</span>  
	</a>
	<div class="dropdown-menu w-100 pt-0" aria-labelledby="dropdownMenuButton" >
		<input type="text" class="w-100 p-2 searchInput" [ngModel]="searchValue" (ngModelChange)="filterDropdown($event)">
		<div class="dropdown-scroll">
			<div *ngFor="let option of currencyOptions; let i=index" (click)="onCurrencySelect(option)">
				<span (click)="selectValue(option)" >{{option?.code ? option.code + " " + "(" + option.sign + ")": option.name}}</span>
			</div>
		</div>
		<div *ngIf="currencyOptions.length <= 0" class="text-center text-muted mt-1">No expert found</div>
	</div>
</div>