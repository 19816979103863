import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_MODULES_MENU_ITEMS, BASE_SOUND_MATE_URL } from '@constants/request.const';
import { ISideNavResponse } from '@interfaces/side-nav.interface';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModulesService {

  emittingFunction = new Subject()

  constructor(private readonly http: HttpClient,) { }

  public getMenuItems(): Observable<ISideNavResponse[]> {
    return this.http.get<ISideNavResponse[]>(`${BASE_SOUND_MATE_URL}${API_MODULES_MENU_ITEMS}`);
  }
}
