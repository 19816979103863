import { Component } from '@angular/core';
import { COOKIE_ACCEPTED } from '@constants/auth.const';
import { PORTAL_NAMES } from '@constants/portal.const';
import { SkdPortalService } from '@kit/portal/portal.service';
import { LocalStorageService } from '@services/local-storage.service';
import { TranslationsService } from '@services/translations.service';

@Component({
  selector: 'skd-cookie-notification',
  templateUrl: './cookie-notification.component.html',
  styleUrls: ['./cookie-notification.component.scss']
})
export class CookieNotificationComponent {
  constructor(
    public readonly translations: TranslationsService,
    private portalService: SkdPortalService,
    private storageService: LocalStorageService,
  ) { }

  public close(): void {
    this.portalService.hide(PORTAL_NAMES.COOKIE_NOTIFICATION);
  }

  public accept(): void {
    this.storageService.setLocalStorageItem(COOKIE_ACCEPTED, true);
    this.close();
  }
}
