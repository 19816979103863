import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_VERSION1_USER_ENABLE, API_VERSION1_USER_ENABLE_APPLICATION, BASE_SOUND_MATE_URL } from '@constants/request.const';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FooterEnableService {

  public subject = new Subject();

  constructor(public http : HttpClient) {}
  interFaceEnable() {
    return this.http.get(`${BASE_SOUND_MATE_URL}${API_VERSION1_USER_ENABLE_APPLICATION}`);
  }
  interFaceSocial()
  {
    return this.http.get(`${BASE_SOUND_MATE_URL}${API_VERSION1_USER_ENABLE}`);
  }
}
