import { IconsClass } from '@assets/images/icons.class';

export const CURRENCIES = [
  {
    name: 'USD ($)',
    id: 'usd',
    icon: '',
    sign: '$',
  },
  {
    name: 'EUR (€)',
    id: 'eur',
    icon: '',
    sign: '€',
  },
  {
    name: 'GBP (£)',
    id: 'gbp',
    icon: '',
    sign: '£',
  },
  {
    name: 'BYN (Br)',
    id: 'byn',
    icon: '',
    sign: 'Br',
  },
  {
    name: 'UAH (₴)',
    id: 'uah',
    icon: '',
    sign: '₴',
  },
  {
    name: 'RUB (₽)',
    id: 'rub',
    icon: '',
    sign: '₽',
  },
  {
    name: 'JPY (¥)',
    id: 'jpy',
    icon: '',
    sign: '¥',
  },
  {
    name: 'CNY (¥)',
    id: 'cny',
    icon: '',
    sign: '¥',
  },
];

export const LANGUAGES = [
  {
    name: 'English',
    id: 'en',
    icon: IconsClass.GBFlagSvg,
  },
  {
    name: 'Spanish',
    id: 'es',
    icon: IconsClass.spainFlagSvg,
  },
  {
    name: 'Français',
    id: 'fr',
    icon: IconsClass.franceFlagSvg,
  },
  {
    name: 'عربي',
    id: 'ar',
    icon: IconsClass.arabicFlagSvg,
  },
  {
    name: 'Bahasa',
    id: 'id',
    icon: IconsClass.indonesiaFlagSvg,
  },
  {
    name: 'Filipino',
    id: 'fil',
    icon: IconsClass.philippinesFlagSvg,
  },
  {
    name: 'Russain',
    id: 'ru',
    icon: IconsClass.russiaFlagSvg,
  },
  {
    name: 'Português',
    id: 'pt',
    icon: IconsClass.portugalFlagSvg,
  },
  {
    name: 'Chinese(Traditional)',
    id: 'zh-tw',
    icon: IconsClass.chinaFlagSvg,
  },
  {
    name: 'Chinese(Simplified)',
    id: 'zh-cn',
    icon: IconsClass.chinaFlagSvg,
  },
  {
    name: 'German',
    id: 'de',
    icon: IconsClass.germanyFlagSvg,
  },
  {
    name: 'Turkish',
    id: 'tr',
    icon: IconsClass.turkeyFlagSvg,
  },
  {
    name: 'Vietnamese',
    id: 'vi',
    icon: IconsClass.vietnamFlagSvg,
  },
  {
    name: 'Italian',
    id: 'it',
    icon: IconsClass.italyFlagSvg,
  },
  {
    name: 'Japanese',
    id: 'ja',
    icon: IconsClass.japanFlagSvg,
  },
  {
    name: 'Polish',
    id: 'pl',
    icon: IconsClass.polandFlagSvg,
  },
  {
    name: 'Thai',
    id: 'th',
    icon: IconsClass.thailandFlagSvg,
  },
  {
    name: 'Lao',
    id: 'lo',
    icon: IconsClass.laoFlagSvg,
    
  },
  {
    name: 'Greek',
    id: 'el',
    icon: IconsClass.greFlagSvg,
   },
   {
     name: 'Ukrainian',
     id: 'uk',
     icon: IconsClass.ukaFlagSvg,
    },
    {
      name: 'Azerbaijani',
      id: 'az',
      icon: IconsClass.azerFlagSvg,
    },
    {
      name: 'Bosnian',
      id: 'bs',
   icon: IconsClass.bosnianFlagSvg,
    },

    {
      name: 'Czech',
      id: 'cs',
   icon: IconsClass.czechFlagSvg,
    },
    {
      name: 'Danish',
      id: 'da',
      icon:IconsClass.danishFlagSvg,
    },
    {
      name: 'Dutch',
      id: 'nl',
      icon:IconsClass.dutchFlagSvg,
    },
    {
      name: 'Estonian',
      id: 'et',
        icon:IconsClass.estonianFlagSvg,
    },
    {
      name: 'Finnish',
      id: 'fi',
        icon:IconsClass.finlandFlagSvg,
    },  
    {
      name: 'Hungarian',
      id: 'hu',
      icon:IconsClass.hungaryFlagSvg,
    },
    {
      name: 'Latvian',
      id: 'lv',
      icon:IconsClass.latvianFlagSvg,
    },
    {
      name: 'Lithuanian',
      id: 'lt',
      icon:IconsClass.lithuanianFlagSvg,
    },

    {
      name:'Norwegian',
      id:'no',
       icon:IconsClass.norwegianFlagSvg,
    },
    {

      name:'Romanian',
      id:'ro',
       icon:IconsClass.romanianFlagSvg,
    },

    {
      name:'Serbian',
      id:'sr',
       icon:IconsClass.serbianFlagSvg,
    },
    {
      name:'Slovak',
      id:'sk',
      icon:IconsClass.slovakFlagSvg,
    },
    {
      name:'Slovenian',
      id:'sl',
      icon:IconsClass.slovenianFlagSvg,
    },
    {
      name:'Swedish',
      id:'sv',
      icon:IconsClass.swedishFlagSvg,
    }
];
