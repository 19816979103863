import { Injectable } from '@angular/core';
import { LoadingComponent } from './loading.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private instances: Map<string, LoadingComponent> = new Map();

  public registerInstance(name: string, instance: LoadingComponent): void {
    this.instances.set(name, instance);
  }

  public removeInstance(name: string, instance: LoadingComponent): void {
    if (this.instances.get(name) === instance) {
      this.instances.delete(name);
    }
  }

  public hide(name: string): void {
    this.instances.get(name).hide();
  }

  public show(name: string): void {
    this.instances.get(name).show();
  }
}
