import { IconsClass } from '@assets/images/icons.class';
import { ACTIVITY_LOGS, SETTINGS, USER, USER_INFO, USER_SETTINGS } from '@constants/routes.const';
import { TranslationsService } from '@services/translations.service';

const translate = new TranslationsService();

export const HEADER_MENU_LIST = {
  profile: {
    name: translate.HEADER.MENU.PROFILE,
    icon: IconsClass.userMenuSvg,
    id: `${USER}/${SETTINGS}/${USER_INFO}`,
  },
  logs: {
    name: translate.HEADER.MENU.LOGS,
    icon: IconsClass.logSvg,
    id: `${USER}/${ACTIVITY_LOGS}`,
  },
  settings: {
    name: translate.HEADER.MENU.SETTINGS,
    icon: IconsClass.settingsSvg,
    id: USER_SETTINGS,
  },
  logOut: {
    name: translate.HEADER.MENU.LOG_OUT,
    icon: IconsClass.logOutSvg,
    id: 'log-out',
  },
};

export const CURRENCY_LIST = [
  {
    "name": "Afghani",
    "code": "AFN",
    "sign": "؋",
    "decimalDigits": 2
  },
  {
    "name": "Albanian Lek",
    "code": "ALL",
    "sign": "Lekë",
    "decimalDigits": 0
  },
  {
    "name": "Algerian Dinar",
    "code": "DZD",
    "sign": "د.ج.‏",
    "decimalDigits": 2
  },
  {
    "name": "Angolan Kwanza",
    "code": "AOA",
    "sign": "Kz",
    "decimalDigits": 2
  },
  {
    "name": "Argentine Peso",
    "code": "ARS",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Armenian Dram",
    "code": "AMD",
    "sign": "֏",
    "decimalDigits": 2
  },
  {
    "name": "Aruban Florin",
    "code": "AWG",
    "sign": "Afl.",
    "decimalDigits": 2
  },
  {
    "name": "Australian Dollar",
    "code": "AUD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Azerbaijani Manat",
    "code": "AZN",
    "sign": "₼",
    "decimalDigits": 2
  },
  {
    "name": "Bahamian Dollar",
    "code": "BSD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Bahraini Dinar",
    "code": "BHD",
    "sign": "د.ب.‏",
    "decimalDigits": 3
  },
  {
    "name": "Bangladeshi Taka",
    "code": "BDT",
    "sign": "৳",
    "decimalDigits": 2
  },
  {
    "name": "Barbadian Dollar",
    "code": "BBD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Belarusian Ruble",
    "code": "BYN",
    "sign": "Br",
    "decimalDigits": 2
  },
  {
    "name": "Belize Dollar",
    "code": "BZD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Bermudan Dollar",
    "code": "BMD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Bhutanese Ngultrum",
    "code": "BTN",
    "sign": "Nu.",
    "decimalDigits": 2
  },
  {
    "name": "Bolivian Boliviano",
    "code": "BOB",
    "sign": "Bs",
    "decimalDigits": 2
  },
  {
    "name": "Bosnia-Herzegovina Convertible Mark",
    "code": "BAM",
    "sign": "КМ",
    "decimalDigits": 2
  },
  {
    "name": "Botswanan Pula",
    "code": "BWP",
    "sign": "P",
    "decimalDigits": 2
  },
  {
    "name": "Brazilian Real",
    "code": "BRL",
    "sign": "R$",
    "decimalDigits": 2
  },
  {
    "name": "British Pound",
    "code": "GBP",
    "sign": "£",
    "decimalDigits": 2
  },
  {
    "name": "Brunei Dollar",
    "code": "BND",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Bulgarian Lev",
    "code": "BGN",
    "sign": "лв.",
    "decimalDigits": 2
  },
  {
    "name": "Burundian Franc",
    "code": "BIF",
    "sign": "FBu",
    "decimalDigits": 0
  },
  {
    "name": "Cambodian Riel",
    "code": "KHR",
    "sign": "៛",
    "decimalDigits": 2
  },
  {
    "name": "Canadian Dollar",
    "code": "CAD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Cape Verdean Escudo",
    "code": "CVE",
    "sign": "​",
    "decimalDigits": 2
  },
  {
    "name": "Cayman Islands Dollar",
    "code": "KYD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Central African CFA Franc",
    "code": "XAF",
    "sign": "FCFA",
    "decimalDigits": 0
  },
  {
    "name": "CFP Franc",
    "code": "XPF",
    "sign": "FCFP",
    "decimalDigits": 0
  },
  {
    "name": "Chilean Peso",
    "code": "CLP",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Chinese Yuan",
    "code": "CNY",
    "sign": "¥",
    "decimalDigits": 2
  },
  {
    "name": "Colombian Peso",
    "code": "COP",
    "sign": "$",
    "decimalDigits": 0
  },
  {
    "name": "Comorian Franc",
    "code": "KMF",
    "sign": "CF",
    "decimalDigits": 0
  },
  {
    "name": "Congolese Franc",
    "code": "CDF",
    "sign": "FC",
    "decimalDigits": 2
  },
  {
    "name": "Costa Rican Colón",
    "code": "CRC",
    "sign": "₡",
    "decimalDigits": 0
  },
  {
    "name": "Croatian Kuna",
    "code": "HRK",
    "sign": "kn",
    "decimalDigits": 2
  },
  {
    "name": "Cuban Peso",
    "code": "CUP",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Czech Republic Koruna",
    "code": "CZK",
    "sign": "Kč",
    "decimalDigits": 2
  },
  {
    "name": "Danish Krone",
    "code": "DKK",
    "sign": "kr.",
    "decimalDigits": 2
  },
  {
    "name": "Djiboutian Franc",
    "code": "DJF",
    "sign": "Fdj",
    "decimalDigits": 0
  },
  {
    "name": "Dominican Peso",
    "code": "DOP",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Eastern Caribbean Dollar",
    "code": "XCD",
    "sign": "EC$",
    "decimalDigits": 2
  },
  {
    "name": "Egyptian Pound",
    "code": "EGP",
    "sign": "ج.م.‏",
    "decimalDigits": 2
  },
  {
    "name": "Eritrean Nakfa",
    "code": "ERN",
    "sign": "Nfk",
    "decimalDigits": 2
  },
  {
    "name": "Ethiopian Birr",
    "code": "ETB",
    "sign": "Br",
    "decimalDigits": 2
  },
  {
    "name": "Euro",
    "code": "EUR",
    "sign": "€",
    "decimalDigits": 2
  },
  {
    "name": "Falkland Islands Pound",
    "code": "FKP",
    "sign": "£",
    "decimalDigits": 2
  },
  {
    "name": "Fijian Dollar",
    "code": "FJD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Gambian Dalasi",
    "code": "GMD",
    "sign": "D",
    "decimalDigits": 2
  },
  {
    "name": "Georgian Lari",
    "code": "GEL",
    "sign": "₾",
    "decimalDigits": 2
  },
  {
    "name": "Ghanaian Cedi",
    "code": "GHS",
    "sign": "GH₵",
    "decimalDigits": 2
  },
  {
    "name": "Gibraltar Pound",
    "code": "GIP",
    "sign": "£",
    "decimalDigits": 2
  },
  {
    "name": "Guatemalan Quetzal",
    "code": "GTQ",
    "sign": "Q",
    "decimalDigits": 2
  },
  {
    "name": "Guinean Franc",
    "code": "GNF",
    "sign": "FG",
    "decimalDigits": 0
  },
  {
    "name": "Guyanaese Dollar",
    "code": "GYD",
    "sign": "$",
    "decimalDigits": 0
  },
  {
    "name": "Haitian Gourde",
    "code": "HTG",
    "sign": "G",
    "decimalDigits": 2
  },
  {
    "name": "Honduran Lempira",
    "code": "HNL",
    "sign": "L",
    "decimalDigits": 2
  },
  {
    "name": "Hong Kong Dollar",
    "code": "HKD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Hungarian Forint",
    "code": "HUF",
    "sign": "Ft",
    "decimalDigits": 2
  },
  {
    "name": "Icelandic Króna",
    "code": "ISK",
    "sign": "ISK",
    "decimalDigits": 0
  },
  {
    "name": "Indian Rupee",
    "code": "INR",
    "sign": "₹",
    "decimalDigits": 2
  },
  {
    "name": "Indonesian Rupiah",
    "code": "IDR",
    "sign": "Rp",
    "decimalDigits": 0
  },
  {
    "name": "Iranian Rial",
    "code": "IRR",
    "sign": "ريال",
    "decimalDigits": 2
  },
  {
    "name": "Iraqi Dinar",
    "code": "IQD",
    "sign": "د.ع.‏",
    "decimalDigits": 2
  },
  {
    "name": "Israeli New Shekel",
    "code": "ILS",
    "sign": "₪",
    "decimalDigits": 2
  },
  {
    "name": "Jamaican Dollar",
    "code": "JMD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Japanese Yen",
    "code": "JPY",
    "sign": "¥",
    "decimalDigits": 0
  },
  {
    "name": "Jordanian Dinar",
    "code": "JOD",
    "sign": "د.ا.‏",
    "decimalDigits": 3
  },
  {
    "name": "Kazakhstani Tenge",
    "code": "KZT",
    "sign": "₸",
    "decimalDigits": 2
  },
  {
    "name": "Kenyan Shilling",
    "code": "KES",
    "sign": "Ksh",
    "decimalDigits": 2
  },
  {
    "name": "Kuwaiti Dinar",
    "code": "KWD",
    "sign": "د.ك.‏",
    "decimalDigits": 3
  },
  {
    "name": "Kyrgystani Som",
    "code": "KGS",
    "sign": "сом",
    "decimalDigits": 2
  },
  {
    "name": "Laotian Kip",
    "code": "LAK",
    "sign": "₭",
    "decimalDigits": 0
  },
  {
    "name": "Lebanese Pound",
    "code": "LBP",
    "sign": "ل.ل.‏",
    "decimalDigits": 2
  },
  {
    "name": "Liberian Dollar",
    "code": "LRD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Libyan Dinar",
    "code": "LYD",
    "sign": "د.ل.‏",
    "decimalDigits": 3
  },
  {
    "name": "Macanese Pataca",
    "code": "MOP",
    "sign": "MOP$",
    "decimalDigits": 2
  },
  {
    "name": "Macedonian Denar",
    "code": "MKD",
    "sign": "ден",
    "decimalDigits": 2
  },
  {
    "name": "Malagasy Ariary",
    "code": "MGA",
    "sign": "Ar",
    "decimalDigits": 0
  },
  {
    "name": "Malawian Kwacha",
    "code": "MWK",
    "sign": "MK",
    "decimalDigits": 2
  },
  {
    "name": "Malaysian Ringgit",
    "code": "MYR",
    "sign": "RM",
    "decimalDigits": 2
  },
  {
    "name": "Mauritanian Ouguiya",
    "code": "MRU",
    "sign": "أ.م.‏",
    "decimalDigits": 2
  },
  {
    "name": "Mauritian Rupee",
    "code": "MUR",
    "sign": "Rs",
    "decimalDigits": 0
  },
  {
    "name": "Mexican Peso",
    "code": "MXN",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Moldovan Leu",
    "code": "MDL",
    "sign": "L",
    "decimalDigits": 2
  },
  {
    "name": "Mongolian Tugrik",
    "code": "MNT",
    "sign": "₮",
    "decimalDigits": 0
  },
  {
    "name": "Moroccan Dirham",
    "code": "MAD",
    "sign": "د.م.‏",
    "decimalDigits": 2
  },
  {
    "name": "Mozambican Metical",
    "code": "MZN",
    "sign": "MTn",
    "decimalDigits": 2
  },
  {
    "name": "Myanmar Kyat",
    "code": "MMK",
    "sign": "K",
    "decimalDigits": 0
  },
  {
    "name": "Namibian Dollar",
    "code": "NAD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Nepalese Rupee",
    "code": "NPR",
    "sign": "रु",
    "decimalDigits": 2
  },
  {
    "name": "Netherlands Antillean Guilder",
    "code": "ANG",
    "sign": "NAf.",
    "decimalDigits": 2
  },
  {
    "name": "New Taiwan Dollar",
    "code": "TWD",
    "sign": "NT$",
    "decimalDigits": 2
  },
  {
    "name": "New Zealand Dollar",
    "code": "NZD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Nicaraguan Córdoba",
    "code": "NIO",
    "sign": "C$",
    "decimalDigits": 2
  },
  {
    "name": "Nigerian Naira",
    "code": "NGN",
    "sign": "₦",
    "decimalDigits": 2
  },
  {
    "name": "North Korean Won",
    "code": "KPW",
    "sign": "₩",
    "decimalDigits": 0
  },
  {
    "name": "Norwegian Krone",
    "code": "NOK",
    "sign": "kr",
    "decimalDigits": 2
  },
  {
    "name": "Omani Rial",
    "code": "OMR",
    "sign": "ر.ع.‏",
    "decimalDigits": 3
  },
  {
    "name": "Pakistani Rupee",
    "code": "PKR",
    "sign": "Rs",
    "decimalDigits": 0
  },
  {
    "name": "Panamanian Balboa",
    "code": "PAB",
    "sign": "B/.",
    "decimalDigits": 2
  },
  {
    "name": "Papua New Guinean Kina",
    "code": "PGK",
    "sign": "K",
    "decimalDigits": 2
  },
  {
    "name": "Paraguayan Guarani",
    "code": "PYG",
    "sign": "₲",
    "decimalDigits": 0
  },
  {
    "name": "Peruvian Nuevo Sol",
    "code": "PEN",
    "sign": "S/",
    "decimalDigits": 2
  },
  {
    "name": "Philippine Peso",
    "code": "PHP",
    "sign": "₱",
    "decimalDigits": 2
  },
  {
    "name": "Polish Zloty",
    "code": "PLN",
    "sign": "zł",
    "decimalDigits": 2
  },
  {
    "name": "Qatari Rial",
    "code": "QAR",
    "sign": "ر.ق.‏",
    "decimalDigits": 2
  },
  {
    "name": "Romanian Leu",
    "code": "RON",
    "sign": "RON",
    "decimalDigits": 2
  },
  {
    "name": "Rufiyaa",
    "code": "MVR",
    "sign": "ރ.",
    "decimalDigits": 2
  },
  {
    "name": "Russian Ruble",
    "code": "RUB",
    "sign": "₽",
    "decimalDigits": 2
  },
  {
    "name": "Rwandan Franc",
    "code": "RWF",
    "sign": "RF",
    "decimalDigits": 0
  },
  {
    "name": "Saint Helena Pound",
    "code": "SHP",
    "sign": "£",
    "decimalDigits": 2
  },
  {
    "name": "Samoan Tala",
    "code": "WST",
    "sign": "WS$",
    "decimalDigits": 2
  },
  {
    "name": "São Tomé and Príncipe Dobra",
    "code": "STN",
    "sign": "Db",
    "decimalDigits": 0
  },
  {
    "name": "Saudi Riyal",
    "code": "SAR",
    "sign": "ر.س.‏",
    "decimalDigits": 2
  },
  {
    "name": "Serbian Dinar",
    "code": "RSD",
    "sign": "дин.",
    "decimalDigits": 2
  },
  {
    "name": "Seychellois Rupee",
    "code": "SCR",
    "sign": "SR",
    "decimalDigits": 2
  },
  {
    "name": "Sierra Leonean Leone",
    "code": "SLL",
    "sign": "Le",
    "decimalDigits": 0
  },
  {
    "name": "Singapore Dollar",
    "code": "SGD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Solomon Islands Dollar",
    "code": "SBD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Somali Shilling",
    "code": "SOS",
    "sign": "S",
    "decimalDigits": 0
  },
  {
    "name": "South African Rand",
    "code": "ZAR",
    "sign": "R",
    "decimalDigits": 2
  },
  {
    "name": "South Korean Won",
    "code": "KRW",
    "sign": "₩",
    "decimalDigits": 0
  },
  {
    "name": "South Sudanese Pound",
    "code": "SSP",
    "sign": "£",
    "decimalDigits": 2
  },
  {
    "name": "Special Drawing Rights",
    "code": "XDR",
    "sign": "XDR",
    "decimalDigits": 2
  },
  {
    "name": "Sri Lankan Rupee",
    "code": "LKR",
    "sign": "රු.",
    "decimalDigits": 2
  },
  {
    "name": "Sudanese Pound",
    "code": "SDG",
    "sign": "ج.س.",
    "decimalDigits": 2
  },
  {
    "name": "Surinamese Dollar",
    "code": "SRD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Swazi Lilangeni",
    "code": "SZL",
    "sign": "E",
    "decimalDigits": 2
  },
  {
    "name": "Swedish Krona",
    "code": "SEK",
    "sign": "kr",
    "decimalDigits": 2
  },
  {
    "name": "Swiss Franc",
    "code": "CHF",
    "sign": "CHF",
    "decimalDigits": 2
  },
  {
    "name": "Syrian Pound",
    "code": "SYP",
    "sign": "ل.س.‏",
    "decimalDigits": 2
  },
  {
    "name": "Tajikistani Somoni",
    "code": "TJS",
    "sign": "смн",
    "decimalDigits": 2
  },
  {
    "name": "Tanzanian Shilling",
    "code": "TZS",
    "sign": "TSh",
    "decimalDigits": 0
  },
  {
    "name": "Thai Baht",
    "code": "THB",
    "sign": "฿",
    "decimalDigits": 2
  },
  {
    "name": "Tongan Paʻanga",
    "code": "TOP",
    "sign": "T$",
    "decimalDigits": 2
  },
  {
    "name": "Trinidad and Tobago Dollar",
    "code": "TTD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Tunisian Dinar",
    "code": "TND",
    "sign": "د.ت.‏",
    "decimalDigits": 3
  },
  {
    "name": "Turkish Lira",
    "code": "TRY",
    "sign": "₺",
    "decimalDigits": 2
  },
  {
    "name": "Turkmen manat",
    "code": "TMT",
    "sign": "m.",
    "decimalDigits": 2
  },
  {
    "name": "Ugandan Shilling",
    "code": "UGX",
    "sign": "USh",
    "decimalDigits": 0
  },
  {
    "name": "Ukrainian Hryvnia",
    "code": "UAH",
    "sign": "₴",
    "decimalDigits": 2
  },
  {
    "name": "United Arab Emirates Dirham",
    "code": "AED",
    "sign": "د.إ.‏",
    "decimalDigits": 2
  },
  {
    "name": "Uruguayan Peso",
    "code": "UYU",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "US Dollar",
    "code": "USD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Uzbekistani Som",
    "code": "UZS",
    "sign": "сўм",
    "decimalDigits": 0
  },
  {
    "name": "Vanuatu Vatu",
    "code": "VUV",
    "sign": "VT",
    "decimalDigits": 0
  },
  {
    "name": "Venezuelan Bolívar",
    "code": "VES",
    "sign": "Bs.S",
    "decimalDigits": 2
  },
  {
    "name": "Vietnamese Dong",
    "code": "VND",
    "sign": "₫",
    "decimalDigits": 2
  },
  {
    "name": "West African CFA Franc",
    "code": "XOF",
    "sign": "CFA",
    "decimalDigits": 0
  },
  {
    "name": "Yemeni Rial",
    "code": "YER",
    "sign": "ر.ي.‏",
    "decimalDigits": 2
  },
  {
    "name": "Zambian Kwacha",
    "code": "ZMW",
    "sign": "K",
    "decimalDigits": 2
  },
  {
    "name": "Afghani",
    "code": "AFN",
    "sign": "؋",
    "decimalDigits": 2
  },
  {
    "name": "Albanian Lek",
    "code": "ALL",
    "sign": "Lekë",
    "decimalDigits": 0
  },
  {
    "name": "Algerian Dinar",
    "code": "DZD",
    "sign": "د.ج.‏",
    "decimalDigits": 2
  },
  {
    "name": "Angolan Kwanza",
    "code": "AOA",
    "sign": "Kz",
    "decimalDigits": 2
  },
  {
    "name": "Argentine Peso",
    "code": "ARS",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Armenian Dram",
    "code": "AMD",
    "sign": "֏",
    "decimalDigits": 2
  },
  {
    "name": "Aruban Florin",
    "code": "AWG",
    "sign": "Afl.",
    "decimalDigits": 2
  },
  {
    "name": "Australian Dollar",
    "code": "AUD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Azerbaijani Manat",
    "code": "AZN",
    "sign": "₼",
    "decimalDigits": 2
  },
  {
    "name": "Bahamian Dollar",
    "code": "BSD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Bahraini Dinar",
    "code": "BHD",
    "sign": "د.ب.‏",
    "decimalDigits": 3
  },
  {
    "name": "Bangladeshi Taka",
    "code": "BDT",
    "sign": "৳",
    "decimalDigits": 2
  },
  {
    "name": "Barbadian Dollar",
    "code": "BBD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Belarusian Ruble",
    "code": "BYN",
    "sign": "Br",
    "decimalDigits": 2
  },
  {
    "name": "Belize Dollar",
    "code": "BZD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Bermudan Dollar",
    "code": "BMD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Bhutanese Ngultrum",
    "code": "BTN",
    "sign": "Nu.",
    "decimalDigits": 2
  },
  {
    "name": "Bolivian Boliviano",
    "code": "BOB",
    "sign": "Bs",
    "decimalDigits": 2
  },
  {
    "name": "Bosnia-Herzegovina Convertible Mark",
    "code": "BAM",
    "sign": "КМ",
    "decimalDigits": 2
  },
  {
    "name": "Botswanan Pula",
    "code": "BWP",
    "sign": "P",
    "decimalDigits": 2
  },
  {
    "name": "Brazilian Real",
    "code": "BRL",
    "sign": "R$",
    "decimalDigits": 2
  },
  {
    "name": "British Pound",
    "code": "GBP",
    "sign": "£",
    "decimalDigits": 2
  },
  {
    "name": "Brunei Dollar",
    "code": "BND",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Bulgarian Lev",
    "code": "BGN",
    "sign": "лв.",
    "decimalDigits": 2
  },
  {
    "name": "Burundian Franc",
    "code": "BIF",
    "sign": "FBu",
    "decimalDigits": 0
  },
  {
    "name": "Cambodian Riel",
    "code": "KHR",
    "sign": "៛",
    "decimalDigits": 2
  },
  {
    "name": "Canadian Dollar",
    "code": "CAD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Cape Verdean Escudo",
    "code": "CVE",
    "sign": "​",
    "decimalDigits": 2
  },
  {
    "name": "Cayman Islands Dollar",
    "code": "KYD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Central African CFA Franc",
    "code": "XAF",
    "sign": "FCFA",
    "decimalDigits": 0
  },
  {
    "name": "CFP Franc",
    "code": "XPF",
    "sign": "FCFP",
    "decimalDigits": 0
  },
  {
    "name": "Chilean Peso",
    "code": "CLP",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Chinese Yuan",
    "code": "CNY",
    "sign": "¥",
    "decimalDigits": 2
  },
  {
    "name": "Colombian Peso",
    "code": "COP",
    "sign": "$",
    "decimalDigits": 0
  },
  {
    "name": "Comorian Franc",
    "code": "KMF",
    "sign": "CF",
    "decimalDigits": 0
  },
  {
    "name": "Congolese Franc",
    "code": "CDF",
    "sign": "FC",
    "decimalDigits": 2
  },
  {
    "name": "Costa Rican Colón",
    "code": "CRC",
    "sign": "₡",
    "decimalDigits": 0
  },
  {
    "name": "Croatian Kuna",
    "code": "HRK",
    "sign": "kn",
    "decimalDigits": 2
  },
  {
    "name": "Cuban Peso",
    "code": "CUP",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Czech Republic Koruna",
    "code": "CZK",
    "sign": "Kč",
    "decimalDigits": 2
  },
  {
    "name": "Danish Krone",
    "code": "DKK",
    "sign": "kr.",
    "decimalDigits": 2
  },
  {
    "name": "Djiboutian Franc",
    "code": "DJF",
    "sign": "Fdj",
    "decimalDigits": 0
  },
  {
    "name": "Dominican Peso",
    "code": "DOP",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Eastern Caribbean Dollar",
    "code": "XCD",
    "sign": "EC$",
    "decimalDigits": 2
  },
  {
    "name": "Egyptian Pound",
    "code": "EGP",
    "sign": "ج.م.‏",
    "decimalDigits": 2
  },
  {
    "name": "Eritrean Nakfa",
    "code": "ERN",
    "sign": "Nfk",
    "decimalDigits": 2
  },
  {
    "name": "Ethiopian Birr",
    "code": "ETB",
    "sign": "Br",
    "decimalDigits": 2
  },
  {
    "name": "Euro",
    "code": "EUR",
    "sign": "€",
    "decimalDigits": 2
  },
  {
    "name": "Falkland Islands Pound",
    "code": "FKP",
    "sign": "£",
    "decimalDigits": 2
  },
  {
    "name": "Fijian Dollar",
    "code": "FJD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Gambian Dalasi",
    "code": "GMD",
    "sign": "D",
    "decimalDigits": 2
  },
  {
    "name": "Georgian Lari",
    "code": "GEL",
    "sign": "₾",
    "decimalDigits": 2
  },
  {
    "name": "Ghanaian Cedi",
    "code": "GHS",
    "sign": "GH₵",
    "decimalDigits": 2
  },
  {
    "name": "Gibraltar Pound",
    "code": "GIP",
    "sign": "£",
    "decimalDigits": 2
  },
  {
    "name": "Guatemalan Quetzal",
    "code": "GTQ",
    "sign": "Q",
    "decimalDigits": 2
  },
  {
    "name": "Guinean Franc",
    "code": "GNF",
    "sign": "FG",
    "decimalDigits": 0
  },
  {
    "name": "Guyanaese Dollar",
    "code": "GYD",
    "sign": "$",
    "decimalDigits": 0
  },
  {
    "name": "Haitian Gourde",
    "code": "HTG",
    "sign": "G",
    "decimalDigits": 2
  },
  {
    "name": "Honduran Lempira",
    "code": "HNL",
    "sign": "L",
    "decimalDigits": 2
  },
  {
    "name": "Hong Kong Dollar",
    "code": "HKD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Hungarian Forint",
    "code": "HUF",
    "sign": "Ft",
    "decimalDigits": 2
  },
  {
    "name": "Icelandic Króna",
    "code": "ISK",
    "sign": "ISK",
    "decimalDigits": 0
  },
  {
    "name": "Indian Rupee",
    "code": "INR",
    "sign": "₹",
    "decimalDigits": 2
  },
  {
    "name": "Indonesian Rupiah",
    "code": "IDR",
    "sign": "Rp",
    "decimalDigits": 0
  },
  {
    "name": "Iranian Rial",
    "code": "IRR",
    "sign": "ريال",
    "decimalDigits": 2
  },
  {
    "name": "Iraqi Dinar",
    "code": "IQD",
    "sign": "د.ع.‏",
    "decimalDigits": 2
  },
  {
    "name": "Israeli New Shekel",
    "code": "ILS",
    "sign": "₪",
    "decimalDigits": 2
  },
  {
    "name": "Jamaican Dollar",
    "code": "JMD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Japanese Yen",
    "code": "JPY",
    "sign": "¥",
    "decimalDigits": 0
  },
  {
    "name": "Jordanian Dinar",
    "code": "JOD",
    "sign": "د.ا.‏",
    "decimalDigits": 3
  },
  {
    "name": "Kazakhstani Tenge",
    "code": "KZT",
    "sign": "₸",
    "decimalDigits": 2
  },
  {
    "name": "Kenyan Shilling",
    "code": "KES",
    "sign": "Ksh",
    "decimalDigits": 2
  },
  {
    "name": "Kuwaiti Dinar",
    "code": "KWD",
    "sign": "د.ك.‏",
    "decimalDigits": 3
  },
  {
    "name": "Kyrgystani Som",
    "code": "KGS",
    "sign": "сом",
    "decimalDigits": 2
  },
  {
    "name": "Laotian Kip",
    "code": "LAK",
    "sign": "₭",
    "decimalDigits": 0
  },
  {
    "name": "Lebanese Pound",
    "code": "LBP",
    "sign": "ل.ل.‏",
    "decimalDigits": 2
  },
  {
    "name": "Liberian Dollar",
    "code": "LRD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Libyan Dinar",
    "code": "LYD",
    "sign": "د.ل.‏",
    "decimalDigits": 3
  },
  {
    "name": "Macanese Pataca",
    "code": "MOP",
    "sign": "MOP$",
    "decimalDigits": 2
  },
  {
    "name": "Macedonian Denar",
    "code": "MKD",
    "sign": "ден",
    "decimalDigits": 2
  },
  {
    "name": "Malagasy Ariary",
    "code": "MGA",
    "sign": "Ar",
    "decimalDigits": 0
  },
  {
    "name": "Malawian Kwacha",
    "code": "MWK",
    "sign": "MK",
    "decimalDigits": 2
  },
  {
    "name": "Malaysian Ringgit",
    "code": "MYR",
    "sign": "RM",
    "decimalDigits": 2
  },
  {
    "name": "Mauritanian Ouguiya",
    "code": "MRU",
    "sign": "أ.م.‏",
    "decimalDigits": 2
  },
  {
    "name": "Mauritian Rupee",
    "code": "MUR",
    "sign": "Rs",
    "decimalDigits": 0
  },
  {
    "name": "Mexican Peso",
    "code": "MXN",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Moldovan Leu",
    "code": "MDL",
    "sign": "L",
    "decimalDigits": 2
  },
  {
    "name": "Mongolian Tugrik",
    "code": "MNT",
    "sign": "₮",
    "decimalDigits": 0
  },
  {
    "name": "Moroccan Dirham",
    "code": "MAD",
    "sign": "د.م.‏",
    "decimalDigits": 2
  },
  {
    "name": "Mozambican Metical",
    "code": "MZN",
    "sign": "MTn",
    "decimalDigits": 2
  },
  {
    "name": "Myanmar Kyat",
    "code": "MMK",
    "sign": "K",
    "decimalDigits": 0
  },
  {
    "name": "Namibian Dollar",
    "code": "NAD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Nepalese Rupee",
    "code": "NPR",
    "sign": "रु",
    "decimalDigits": 2
  },
  {
    "name": "Netherlands Antillean Guilder",
    "code": "ANG",
    "sign": "NAf.",
    "decimalDigits": 2
  },
  {
    "name": "New Taiwan Dollar",
    "code": "TWD",
    "sign": "NT$",
    "decimalDigits": 2
  },
  {
    "name": "New Zealand Dollar",
    "code": "NZD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Nicaraguan Córdoba",
    "code": "NIO",
    "sign": "C$",
    "decimalDigits": 2
  },
  {
    "name": "Nigerian Naira",
    "code": "NGN",
    "sign": "₦",
    "decimalDigits": 2
  },
  {
    "name": "North Korean Won",
    "code": "KPW",
    "sign": "₩",
    "decimalDigits": 0
  },
  {
    "name": "Norwegian Krone",
    "code": "NOK",
    "sign": "kr",
    "decimalDigits": 2
  },
  {
    "name": "Omani Rial",
    "code": "OMR",
    "sign": "ر.ع.‏",
    "decimalDigits": 3
  },
  {
    "name": "Pakistani Rupee",
    "code": "PKR",
    "sign": "Rs",
    "decimalDigits": 0
  },
  {
    "name": "Panamanian Balboa",
    "code": "PAB",
    "sign": "B/.",
    "decimalDigits": 2
  },
  {
    "name": "Papua New Guinean Kina",
    "code": "PGK",
    "sign": "K",
    "decimalDigits": 2
  },
  {
    "name": "Paraguayan Guarani",
    "code": "PYG",
    "sign": "₲",
    "decimalDigits": 0
  },
  {
    "name": "Peruvian Nuevo Sol",
    "code": "PEN",
    "sign": "S/",
    "decimalDigits": 2
  },
  {
    "name": "Philippine Peso",
    "code": "PHP",
    "sign": "₱",
    "decimalDigits": 2
  },
  {
    "name": "Polish Zloty",
    "code": "PLN",
    "sign": "zł",
    "decimalDigits": 2
  },
  {
    "name": "Qatari Rial",
    "code": "QAR",
    "sign": "ر.ق.‏",
    "decimalDigits": 2
  },
  {
    "name": "Romanian Leu",
    "code": "RON",
    "sign": "RON",
    "decimalDigits": 2
  },
  {
    "name": "Rufiyaa",
    "code": "MVR",
    "sign": "ރ.",
    "decimalDigits": 2
  },
  {
    "name": "Russian Ruble",
    "code": "RUB",
    "sign": "₽",
    "decimalDigits": 2
  },
  {
    "name": "Rwandan Franc",
    "code": "RWF",
    "sign": "RF",
    "decimalDigits": 0
  },
  {
    "name": "Saint Helena Pound",
    "code": "SHP",
    "sign": "£",
    "decimalDigits": 2
  },
  {
    "name": "Samoan Tala",
    "code": "WST",
    "sign": "WS$",
    "decimalDigits": 2
  },
  {
    "name": "São Tomé and Príncipe Dobra",
    "code": "STN",
    "sign": "Db",
    "decimalDigits": 0
  },
  {
    "name": "Saudi Riyal",
    "code": "SAR",
    "sign": "ر.س.‏",
    "decimalDigits": 2
  },
  {
    "name": "Serbian Dinar",
    "code": "RSD",
    "sign": "дин.",
    "decimalDigits": 2
  },
  {
    "name": "Seychellois Rupee",
    "code": "SCR",
    "sign": "SR",
    "decimalDigits": 2
  },
  {
    "name": "Sierra Leonean Leone",
    "code": "SLL",
    "sign": "Le",
    "decimalDigits": 0
  },
  {
    "name": "Singapore Dollar",
    "code": "SGD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Solomon Islands Dollar",
    "code": "SBD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Somali Shilling",
    "code": "SOS",
    "sign": "S",
    "decimalDigits": 0
  },
  {
    "name": "South African Rand",
    "code": "ZAR",
    "sign": "R",
    "decimalDigits": 2
  },
  {
    "name": "South Korean Won",
    "code": "KRW",
    "sign": "₩",
    "decimalDigits": 0
  },
  {
    "name": "South Sudanese Pound",
    "code": "SSP",
    "sign": "£",
    "decimalDigits": 2
  },
  {
    "name": "Special Drawing Rights",
    "code": "XDR",
    "sign": "XDR",
    "decimalDigits": 2
  },
  {
    "name": "Sri Lankan Rupee",
    "code": "LKR",
    "sign": "රු.",
    "decimalDigits": 2
  },
  {
    "name": "Sudanese Pound",
    "code": "SDG",
    "sign": "ج.س.",
    "decimalDigits": 2
  },
  {
    "name": "Surinamese Dollar",
    "code": "SRD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Swazi Lilangeni",
    "code": "SZL",
    "sign": "E",
    "decimalDigits": 2
  },
  {
    "name": "Swedish Krona",
    "code": "SEK",
    "sign": "kr",
    "decimalDigits": 2
  },
  {
    "name": "Swiss Franc",
    "code": "CHF",
    "sign": "CHF",
    "decimalDigits": 2
  },
  {
    "name": "Syrian Pound",
    "code": "SYP",
    "sign": "ل.س.‏",
    "decimalDigits": 2
  },
  {
    "name": "Tajikistani Somoni",
    "code": "TJS",
    "sign": "смн",
    "decimalDigits": 2
  },
  {
    "name": "Tanzanian Shilling",
    "code": "TZS",
    "sign": "TSh",
    "decimalDigits": 0
  },
  {
    "name": "Thai Baht",
    "code": "THB",
    "sign": "฿",
    "decimalDigits": 2
  },
  {
    "name": "Tongan Paʻanga",
    "code": "TOP",
    "sign": "T$",
    "decimalDigits": 2
  },
  {
    "name": "Trinidad and Tobago Dollar",
    "code": "TTD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Tunisian Dinar",
    "code": "TND",
    "sign": "د.ت.‏",
    "decimalDigits": 3
  },
  {
    "name": "Turkish Lira",
    "code": "TRY",
    "sign": "₺",
    "decimalDigits": 2
  },
  {
    "name": "Turkmen manat",
    "code": "TMT",
    "sign": "m.",
    "decimalDigits": 2
  },
  {
    "name": "Ugandan Shilling",
    "code": "UGX",
    "sign": "USh",
    "decimalDigits": 0
  },
  {
    "name": "Ukrainian Hryvnia",
    "code": "UAH",
    "sign": "₴",
    "decimalDigits": 2
  },
  {
    "name": "United Arab Emirates Dirham",
    "code": "AED",
    "sign": "د.إ.‏",
    "decimalDigits": 2
  },
  {
    "name": "Uruguayan Peso",
    "code": "UYU",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "US Dollar",
    "code": "USD",
    "sign": "$",
    "decimalDigits": 2
  },
  {
    "name": "Uzbekistani Som",
    "code": "UZS",
    "sign": "сўм",
    "decimalDigits": 0
  },
  {
    "name": "Vanuatu Vatu",
    "code": "VUV",
    "sign": "VT",
    "decimalDigits": 0
  },
  {
    "name": "Venezuelan Bolívar",
    "code": "VES",
    "sign": "Bs.S",
    "decimalDigits": 2
  },
  {
    "name": "Vietnamese Dong",
    "code": "VND",
    "sign": "₫",
    "decimalDigits": 2
  },
  {
    "name": "West African CFA Franc",
    "code": "XOF",
    "sign": "CFA",
    "decimalDigits": 0
  },
  {
    "name": "Yemeni Rial",
    "code": "YER",
    "sign": "ر.ي.‏",
    "decimalDigits": 2
  },
  {
    "name": "Zambian Kwacha",
    "code": "ZMW",
    "sign": "K",
    "decimalDigits": 2
  }
]
