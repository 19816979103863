import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { APPLICATION_NAME, MONEY_BACK_GUARANTEE } from '@constants/environyment-pathes.const';
import { FooterEnableService } from '@pages/welcome/landing-page/footer-enable.service';
import { TranslationsService } from '@services/translations.service';
import { WebsiteService } from '@services/website.service';

@Component({
  selector: 'skd-user-footer',
  templateUrl: './user-footer.component.html',
  styleUrls: ['./user-footer.component.scss']
})
export class UserFooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  public content:boolean;
  public item:any;
  serverTime;
  id;
  public support = MONEY_BACK_GUARANTEE;
  public appName = APPLICATION_NAME


  constructor(
    public readonly translate: TranslationsService,
    private websiteService: WebsiteService,
    public cdr: ChangeDetectorRef,
    public enableService : FooterEnableService
  ) { }

  ngOnInit(): void {
      this.enableService.interFaceSocial().subscribe({next:(data:any)=>{
        this.item = data.siteName
        this.cdr.detectChanges();
      }})
      this.websiteService.getApplication().subscribe(data => {
        this.content=data;
        this.cdr.detectChanges();
      });
      this.id = setInterval(() => {
        this.getServerTime(); 
      }, 1000);
  }

    getServerTime(){
      this.serverTime = new Date().toLocaleString('en-US', {timeZone: 'Europe/Moscow'});
      this.cdr.detectChanges();
    }

}
