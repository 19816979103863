import { EventEmitter, Injectable } from '@angular/core';
import en from '@assets/i18n/en';
import ru from '@assets/i18n/ru';
import fr from '@assets/i18n/fr';
import ar from '@assets/i18n/ar';
import es from '@assets/i18n/es';
import ja from '@assets/i18n/ja';
import pl from '@assets/i18n/pl';
import tr from '@assets/i18n/tr';
import it from '@assets/i18n/it';
import pt from '@assets/i18n/pt';
import de from '@assets/i18n/de';
import vi from '@assets/i18n/vi';
import fil from '@assets/i18n/fil';
import id from '@assets/i18n/id';
import th from '@assets/i18n/th';
import lo from '@assets/i18n/lo';
import zhtw from '@assets/i18n/zh-tw';
import zhcn from '@assets/i18n/zh-cn';
import el from '@assets/i18n/el';
import uk from '@assets/i18n/uk';
import az from '@assets/i18n/az';
import bs from '@assets/i18n/bs';
import cs from '@assets/i18n/cs';
import da from '@assets/i18n/da';
import nl from '@assets/i18n/nl';
import et from '@assets/i18n/et';
import fi from '@assets/i18n/fi';
import hu from '@assets/i18n/hu';
import lv from '@assets/i18n/lv';
import lt from '@assets/i18n/lt';
import no from '@assets/i18n/no';
import ro from '@assets/i18n/ro';
import sr from '@assets/i18n/sr';
import sk from '@assets/i18n/sk';
import sl from '@assets/i18n/sl';
import sv from '@assets/i18n/sv';
import { BehaviorSubject, Subject } from 'rxjs';



export function GenericClass<Props>(): new () => Props {
  return class {} as any;
}

function concatIfExistsPath(path: string, suffix: string): string {
  return path ? `${path}.${suffix}` : suffix;
}

function transformObjectToPath<T extends object | string>(
  suffix: string,
  objectToTransformOrEndOfPath: T,
  path = ''
): T {
  return typeof objectToTransformOrEndOfPath === 'object'
    ? Object.entries(objectToTransformOrEndOfPath).reduce(
      (objectToTransform, [key, value]) => {
        objectToTransform[key] = transformObjectToPath(
          key,
          value,
          concatIfExistsPath(path, suffix)
        );

        return objectToTransform;
      },
      {} as T
    )
    : (concatIfExistsPath(path, suffix) as T);
}

@Injectable()
export class TranslationsService extends GenericClass<typeof en>() {
  popupActive = new Subject()
  subActive = new Subject();
  data = new BehaviorSubject('')
  emittingDialog = new Subject()
  boosterAcc = new Subject();
  dashboardCart = new BehaviorSubject('')
  shareDate = new BehaviorSubject('');
  constructor() {
    super();
    Object.assign(this, transformObjectToPath('', en));
  }
}
