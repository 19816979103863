import { SOCIAL_LOGIN_NAME_BY_TYPE as SOCIAL_PROVIDER_BY_TYPE } from '@constants/auth.const';
import { AuthService } from '@services/auth.service';
import { SocialAuthServiceConfig } from 'angularx-social-login';
import { map } from 'rxjs/operators';

export const socialConfigFactory = (authService: AuthService): Promise<SocialAuthServiceConfig> => {
  return authService.getLoginForm().pipe(
    map((authOptions) => {
      const loginProviders = authOptions.loginOptions.map((option) => {
        const Provider = SOCIAL_PROVIDER_BY_TYPE[option.type];
        return ({
          id: Provider.PROVIDER_ID,
          provider: new Provider(option.key)
        })
      });
      return {
        autoLogin: false,
        providers: loginProviders,
      };
    })
  ).toPromise();
}
