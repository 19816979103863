import { Component, OnInit,Input } from '@angular/core';
import { IconsClass } from '@assets/images/icons.class';
import { LANDING_PAGE_HOME } from '@constants/environyment-pathes.const';
import { IOption } from '@interfaces/selects.interface';
import { TranslateService } from '@ngx-translate/core';
import { LANGUAGES } from '@pages/user-main/user-main.const';
import { CurrencyService } from '@services/currency.service';
import { LocalStorageService } from '@services/local-storage.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'skd-select-option',
  templateUrl: './select-option.component.html',
  styleUrls: ['./select-option.component.scss']
})
export class SelectOptionComponent implements OnInit {
@Input() language: any
@Input() setLanguage: any;
  public searchValue: any;
  public filteredList: any = [];
  bindLanguage;
  valName;
  languageCode;
  languages;
  public landingHomePage = LANDING_PAGE_HOME + 'Home/Home' +`${'?'}${'culture='}`

  constructor(private currencyService: CurrencyService, public readonly translate: TranslateService,private storage: LocalStorageService) { }

  ngOnInit(): void {
    this.filteredList = this.language;
    this.bindLanguage = this.setLanguage
    this.languages = this.storage.getLocalStorageItem('SelectedLanguage');
    const selectedLang = LANGUAGES.find(c => c.id === this.setLanguage?.id);
   
    this.languageCode = this.storage.getLocalStorageItem('setLanguageCode');
    const selectedLangNew = LANGUAGES.find(c => c.id === this.languageCode);
   if (!this.languageCode) {
      if (!selectedLang) {
        this.setLanguage = {
          name: 'EN',
          id: 'en',
          icon: IconsClass.GBFlagSvg,
        }
        this.translate.use(this.setLanguage.id);
      }else{
        this.setLanguage = selectedLang;
        this.translate.use(this.setLanguage.id);
      }
  }else{
    this.setLanguage = selectedLangNew;
    this.translate.use(this.languageCode);
  }
  this.currencyService.setLanguage(this.setLanguage)
  }
  filterDropdown(e) {
    // window.scrollTo(window.scrollX, window.scrollY + 1);
    let searchString = e.toLowerCase();
    if (!searchString) {
      this.filteredList = this.language.slice();
      return;
    } else {
      this.filteredList = this.language.filter(user => user.name.toLowerCase().indexOf(searchString) > -1 );
   
    }
    // window.scrollTo(window.scrollX, window.scrollY - 1);
  }
  public onLanguageSelect(language: IOption<string>): void {
    let val = this.landingHomePage + language.id
    this.valName = val
    this.currencyService.languageSubject.next(this.valName)
    this.currencyService.setLanguage(language);
    this.translate.use(language.id);
    this.filteredList = this.language;
    this.searchValue = ''
  }
  selectValue(language) {
    this.setLanguage = language
   this.currencyService.setLanguage(language.id)
  }

}


