import {
  HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LOGIN } from '@constants/routes.const';
import { ILoginResponse, IPreLoginResponse } from '@interfaces/auth.interface';
import { AuthManagerService } from '@services/auth-manager.service';
import { AuthService } from '@services/auth.service';
import { UrlsService } from '@services/urls.service';
import { Observable, throwError } from 'rxjs';
import { catchError, share, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class RefreshInterceptor implements HttpInterceptor {
  private refresh$: Observable<IPreLoginResponse>;

  constructor(
    private router: Router,
    private urlsService: UrlsService,
    private authService: AuthService,
    private authManager: AuthManagerService,
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.urlsService.AUTH_EXCLUDE.includes(request.url) || error.status !== 401) {
          return throwError(error);
        }

        const processing$ = this.authService.refresh(
          this.authManager.refreshToken
        ).pipe(
          catchError((err) => {
            // this.router.navigate([LOGIN]);
            return throwError(err);
          }),
          tap((data) => this.authManager.setAuthData(data)),
          share(),
        );

        this.refresh$ = this.refresh$
          ? this.refresh$
          : processing$;

        return this.refresh$.pipe(
          switchMap(() => next.handle(request)),
        );
      })
    );
  }
}
