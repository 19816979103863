
<div class="loading-container" *ngIf="isVisible">
  <div class="loading-container-inner">
    <!-- <span class="circle"></span>
    <span class="circle"></span>
    <span class="circle"></span>
    <span class="circle"></span> -->
    <img src="../../../../assets/images/p-logo.gif">
  </div>
</div>

 