import summarySvg from '!!raw-loader!@assets/images/summary.svg';
import userSvg from '!!raw-loader!@assets/images/user.svg';
import dollarSvg from '!!raw-loader!@assets/images/dollar.svg';
import usersSvg from '!!raw-loader!@assets/images/users.svg';
import topUserSvg from '!!raw-loader!@assets/images/top-user.svg';
import statisticsSvg from '!!raw-loader!@assets/images/statistics.svg';
import bankSvg from '!!raw-loader!@assets/images/bank.svg';
import folderMusicSvg from '!!raw-loader!@assets/images/folder-music.svg';
import noteSvg from '!!raw-loader!@assets/images/note.svg';
import superBoostSvg from '!!raw-loader!@assets/images/super-boost.svg';
import circleArrowUpSvg from '!!raw-loader!@assets/images/circle-arrow-up.svg';
import clockSvg from '!!raw-loader!@assets/images/clock.svg';
import checkSvg from '!!raw-loader!@assets/images/check.svg';
import eyeSvg from '!!raw-loader!@assets/images/eye.svg';
import eyeOffSvg from '!!raw-loader!@assets/images/eye-off.svg';
import facebookSvg from '!!raw-loader!@assets/images/facebook.svg';
import googleSvg from '!!raw-loader!@assets/images/google.svg';
import twitterSvg from '!!raw-loader!@assets/images/twitter.svg';
import ukraineFlagSvg from '!!raw-loader!@assets/images/ukraine-flag.svg';
import GBFlagSvg from '!!raw-loader!@assets/images/GB-flag.svg';
import russiaFlagSvg from '!!raw-loader!@assets/images/russia-flag.svg';
import chinaFlagSvg from '!!raw-loader!@assets/images/china-flag.svg';
import franceFlagSvg from '!!raw-loader!@assets/images/france-flag.svg';
import germanyFlagSvg from '!!raw-loader!@assets/images/germany-flag.svg';
import arabicFlagSvg from '!!raw-loader!@assets/images/arabic-flag.svg';
import spainFlagSvg from '!!raw-loader!@assets/images/spain-flag.svg';
import indonesiaFlagSvg from '!!raw-loader!@assets/images/indonesia-flag.svg';
import italyFlagSvg from '!!raw-loader!@assets/images/italy-flag.svg';
import japanFlagSvg from '!!raw-loader!@assets/images/japan-flag.svg';
import philippinesFlagSvg from '!!raw-loader!@assets/images/philippines-flag.svg';
import portugalFlagSvg from '!!raw-loader!@assets/images/portugal-flag.svg';
import polandFlagSvg from '!!raw-loader!@assets/images/poland-flag.svg';
import turkeyFlagSvg from '!!raw-loader!@assets/images/turkey-flag.svg';
import vietnamFlagSvg from '!!raw-loader!@assets/images/vietnam-flag.svg';
import thailandFlagSvg from '!!raw-loader!@assets/images/thailand-flag.svg';
import logSvg from '!!raw-loader!@assets/images/log.svg';
import logOutSvg from '!!raw-loader!@assets/images/log-out.svg';
import settingsSvg from '!!raw-loader!@assets/images/settings.svg';
import userMenuSvg from '!!raw-loader!@assets/images/user-menu.svg';
import volumeMuteSvg from '!!raw-loader!@assets/images/volume-mute.svg';
import volumeDownSvg from '!!raw-loader!@assets/images/volume-down.svg';
import volumeUpSvg from '!!raw-loader!@assets/images/volume-up.svg';
import startEarningSvg from '!!raw-loader!@assets/images/start-earning.svg';
import closeSvg from '!!raw-loader!@assets/images/close.svg';
import dollarCircleSvg from '!!raw-loader!@assets/images/dollar-circle.svg';
import watchVideoSvg from '!!raw-loader!@assets/images/watch-video.svg';
import searchSvg from '!!raw-loader!@assets/images/search.svg';
import closeCircleSvg from '!!raw-loader!@assets/images/close-circle.svg';
import guideOneSvg from '!!raw-loader!@assets/images/guide-one.svg';
import guideTwoSvg from '!!raw-loader!@assets/images/guide-two.svg';
import guideThreeSvg from '!!raw-loader!@assets/images/guide-three.svg';
import balanceSvg from '!!raw-loader!@assets/images/balance.svg';
import infoCircleSvg from '!!raw-loader!@assets/images/info-circle.svg';
import successCircleSvg from '!!raw-loader!@assets/images/success-circle.svg';
import successfulIconSvg from '!!raw-loader!@assets/images/successful-icon.svg';
import declinedIconSvg from '!!raw-loader!@assets/images/declined-icon.svg';
import skadaLogoSvg from '!!raw-loader!@assets/images/skada-logo.png';
import downArrowSvg from '!!raw-loader!@assets/images/down-arrow.svg';
import bannerTitleSelectionSvg from '!!raw-loader!@assets/images/banner-title-selection.svg';
import bannerBGimageSvg from '!!raw-loader!@assets/images/banner-bg-img.svg';
import bannerimageSvg from '!!raw-loader!@assets/images/banner-image.svg';
import radioStationIconSvg from '!!raw-loader!@assets/images/radio-station-icon.svg';
import instantRewardIconSvg from '!!raw-loader!@assets/images/instant-reward-icon.svg';
import accuracyIconSvg from '!!raw-loader!@assets/images/accuracy-icon.svg';
import minusIconSvg from '!!raw-loader!@assets/images/minus-icon.svg';
import plusIconSvg from '!!raw-loader!@assets/images/plus-icon.svg';
import twitterIconSvg from '!!raw-loader!@assets/images/twitter-icon.svg';
import linkedinIconSvg from '!!raw-loader!@assets/images/linkedin-icon.svg';
import instagramIconSvg from '!!raw-loader!@assets/images/instagram-icon.svg';
import facebookIconSvg from '!!raw-loader!@assets/images/facebook-icon.svg';
import campaignsIconSvg from '!!raw-loader!@assets/images/campaigns-icon.svg';
import earningIconSvg from '!!raw-loader!@assets/images/earning-icon.svg';
import likeIconSvg from '!!raw-loader!@assets/images/like-icon.svg';
import bookmarkIconSvg from '!!raw-loader!@assets/images/bookmark-icon.svg';
import forwardIconSvg from '!!raw-loader!@assets/images/forward-icon.svg';
import likegrayIconSvg from '!!raw-loader!@assets/images/like-gray-icon.svg';
import dislikeIconSvg from '!!raw-loader!@assets/images/dislike-icon.svg';
import commentIconSvg from '!!raw-loader!@assets/images/comment-icon.svg';
import replyIconSvg from '!!raw-loader!@assets/images/reply-icon.svg';
import backIconSvg from '!!raw-loader!@assets/images/back-icon.svg';
import campaignArrowSvg from '!!raw-loader!@assets/images/campaign-arrow.svg';
import editIconSvg from '!!raw-loader!@assets/images/edit-icon.svg';
import activeIconSvg from '!!raw-loader!@assets/images/campaign-active-icon.svg';
import starIconSvg from '!!raw-loader!@assets/images/campaign-star.svg';
import viewIconSvg from '!!raw-loader!@assets/images/campaign-view.svg';
import rightarrowIconSvg from '!!raw-loader!@assets/images/right-arrow.svg';
import laoFlagSvg from '!!raw-loader!@assets/images/lao_flag.svg';
import ukaFlagSvg from '!!raw-loader!@assets/images/ukarine_flag.svg';
import greFlagSvg from '!!raw-loader!@assets/images/greece_flag.svg';
import azerFlagSvg from '!!raw-loader!@assets/images/azerbaijani_flag.svg';
import bosnianFlagSvg from '!!raw-loader!@assets/images/bosnia_flag.svg';
import czechFlagSvg from '!!raw-loader!@assets/images/czech_flag.svg';
import danishFlagSvg from '!!raw-loader!@assets/images/denmark_flag.svg';
import dutchFlagSvg from '!!raw-loader!@assets/images/dutch_flag.svg';
import estonianFlagSvg from '!!raw-loader!@assets/images/estonia_flag.svg';
import finlandFlagSvg from '!!raw-loader!@assets/images/finland_flag.svg';
import hungaryFlagSvg from '!!raw-loader!@assets/images/hungary_flag.svg';

import latvianFlagSvg from '!!raw-loader!@assets/images/latvia_flag.svg';
import lithuanianFlagSvg from '!!raw-loader!@assets/images/lithuania_flag.svg';
import norwegianFlagSvg from '!!raw-loader!@assets/images/norwegian_flag.svg';
import romanianFlagSvg from '!!raw-loader!@assets/images/romania_flag.svg';
import serbianFlagSvg from '!!raw-loader!@assets/images/serbia_flag.svg';
import slovakFlagSvg from '!!raw-loader!@assets/images/slovakia_flag.svg';
import slovenianFlagSvg from '!!raw-loader!@assets/images/slovenia_flag.svg';
import swedishFlagSvg from '!!raw-loader!@assets/images/sweden_flag.svg';
import perfectmoneySvg from '!!raw-loader!@assets/images/PerfectMoney.svg';
import payeerSvg from '!!raw-loader!@assets/images/payeer.svg';
import cryptocurrencySvg from '!!raw-loader!@assets/images/crypto.svg';
import bitcoinSvg from '!!raw-loader!@assets/images/bitcoin.svg';
import coinbaseSvg from '!!raw-loader!@assets/images/coinbase.svg';
import coinsph from '!!raw-loader!@assets/images/coinsph.svg';
import binance from '!!raw-loader!@assets/images/binance.svg';
import cpay from '!!raw-loader!@assets/images/cpay-icon.svg'
import gcash from '!!raw-loader!@assets/images/GCash.svg'
import remitano from '!!raw-loader!@assets/images/remitano.svg'
import indodax from '!!raw-loader!@assets/images/indodax.svg'

export class IconsClass {
  public static readonly remitano = remitano
  public static readonly indodax = indodax
  public static readonly gcash = gcash
  public static readonly cpay = cpay
  public static readonly binance = binance;
  public static readonly coinsph = coinsph;
  public static readonly coinbaseSvg = coinbaseSvg;
  public static readonly perfectmoneySvg = perfectmoneySvg;
  public static readonly payeerSvg = payeerSvg;
  public static readonly cryptocurrencySvg = cryptocurrencySvg;
  public static readonly bitcoinSvg = bitcoinSvg;
  public static readonly bankSvg = bankSvg;
  public static readonly summarySvg = summarySvg;
  public static readonly userSvg = userSvg;
  public static readonly usersSvg = usersSvg;
  public static readonly topUserSvg = topUserSvg;
  public static readonly dollarSvg = dollarSvg;
  public static readonly statisticsSvg = statisticsSvg;

  public static readonly folderMusicSvg = folderMusicSvg;
  public static readonly noteSvg = noteSvg;

  public static readonly superBoostSvg = superBoostSvg;
  public static readonly circleArrowUpSvg = circleArrowUpSvg;
  public static readonly clockSvg = clockSvg;

  public static readonly checkSvg = checkSvg;

  public static readonly eyeSvg = eyeSvg;
  public static readonly eyeOffSvg = eyeOffSvg;

  public static readonly facebookSvg = facebookSvg;
  public static readonly googleSvg = googleSvg;
  public static readonly twitterSvg = twitterSvg;

  public static readonly balanceSvg = balanceSvg;
  public static readonly logSvg = logSvg;
  public static readonly logOutSvg = logOutSvg;
  public static readonly settingsSvg = settingsSvg;
  public static readonly userMenuSvg = userMenuSvg;

  public static readonly volumeMuteSvg = volumeMuteSvg;
  public static readonly volumeDownSvg = volumeDownSvg;
  public static readonly volumeUpSvg = volumeUpSvg;

  public static readonly startEarningSvg = startEarningSvg;

  public static readonly closeSvg = closeSvg;
  public static readonly closeCircleSvg = closeCircleSvg;

  public static readonly dollarCircleSvg = dollarCircleSvg;
  public static readonly infoCircleSvg = infoCircleSvg;
  public static readonly successCircleSvg = successCircleSvg;
  public static readonly successfulIconSvg = successfulIconSvg;
  public static readonly declinedIconSvg = declinedIconSvg;
  public static readonly watchVideoSvg = watchVideoSvg;

  public static readonly searchSvg = searchSvg;

  public static readonly guideOneSvg = guideOneSvg;
  public static readonly guideTwoSvg = guideTwoSvg;
  public static readonly guideThreeSvg = guideThreeSvg;

  // flags
  public static readonly ukraineFlagSvg = ukraineFlagSvg;
  public static readonly GBFlagSvg = GBFlagSvg;

  public static readonly latvianFlagSvg = latvianFlagSvg;
  public static readonly lithuanianFlagSvg = lithuanianFlagSvg;
  public static readonly norwegianFlagSvg = norwegianFlagSvg;
  public static readonly romanianFlagSvg = romanianFlagSvg;
  public static readonly serbianFlagSvg = serbianFlagSvg;

  public static readonly russiaFlagSvg = russiaFlagSvg;
  public static readonly chinaFlagSvg = chinaFlagSvg;
  public static readonly franceFlagSvg = franceFlagSvg;
  public static readonly germanyFlagSvg = germanyFlagSvg;
  public static readonly arabicFlagSvg = arabicFlagSvg;
  public static readonly spainFlagSvg = spainFlagSvg;
  public static readonly indonesiaFlagSvg = indonesiaFlagSvg;
  public static readonly italyFlagSvg = italyFlagSvg;
  public static readonly japanFlagSvg = japanFlagSvg;
  public static readonly philippinesFlagSvg = philippinesFlagSvg;
  public static readonly portugalFlagSvg = portugalFlagSvg;
  public static readonly polandFlagSvg = polandFlagSvg;
  public static readonly laoFlagSvg = laoFlagSvg
  public static readonly ukaFlagSvg = ukaFlagSvg
  public static readonly azerFlagSvg = azerFlagSvg
  public static readonly estonianFlagSvg = estonianFlagSvg
  public static readonly finlandFlagSvg = finlandFlagSvg
  public static readonly hungaryFlagSvg = hungaryFlagSvg
  public static readonly bosnianFlagSvg = bosnianFlagSvg
  public static readonly slovakFlagSvg = slovakFlagSvg
  public static readonly slovenianFlagSvg = slovenianFlagSvg
  public static readonly swedishFlagSvg = swedishFlagSvg
  public static readonly czechFlagSvg = czechFlagSvg
  public static readonly danishFlagSvg = danishFlagSvg
  public static readonly dutchFlagSvg = dutchFlagSvg
  public static readonly greFlagSvg = greFlagSvg
  public static readonly turkeyFlagSvg = turkeyFlagSvg;
  public static readonly vietnamFlagSvg = vietnamFlagSvg;
  public static readonly thailandFlagSvg = thailandFlagSvg;
  public static readonly skadaLogoSvg = skadaLogoSvg;
  public static readonly downArrowSvg = downArrowSvg;
  public static readonly bannerTitleSelectionSvg = bannerTitleSelectionSvg;
  public static readonly bannerBGimageSvg = bannerBGimageSvg;
  public static readonly bannerimageSvg = bannerimageSvg;
  public static readonly radioStationIconSvg = radioStationIconSvg;
  public static readonly instantRewardIconSvg = instantRewardIconSvg;
  public static readonly accuracyIconSvg = accuracyIconSvg;
  public static readonly minusIconSvg = minusIconSvg;
  public static readonly plusIconSvg = plusIconSvg;
  public static readonly twitterIconSvg = twitterIconSvg;
  public static readonly linkedinIconSvg = linkedinIconSvg;
  public static readonly instagramIconSvg = instagramIconSvg;
  public static readonly facebookIconSvg = facebookIconSvg;

  public static readonly campaignsIconSvg = campaignsIconSvg;
  public static readonly earningIconSvg = earningIconSvg;
  public static readonly likeIconSvg = likeIconSvg;
  public static readonly bookmarkIconSvg = bookmarkIconSvg;
  public static readonly forwardIconSvg = forwardIconSvg;
  public static readonly likegrayIconSvg = likegrayIconSvg;
  public static readonly dislikeIconSvg = dislikeIconSvg;
  public static readonly commentIconSvg = commentIconSvg;
  public static readonly replyIconSvg = replyIconSvg;

  public static readonly backIconSvg = backIconSvg;
  public static readonly campaignArrowSvg = campaignArrowSvg;
  public static readonly editIconSvg = editIconSvg;
  public static readonly activeIconSvg = activeIconSvg;
  public static readonly starIconSvg = starIconSvg;
  public static readonly viewIconSvg = viewIconSvg;
  public static readonly rightarrowIconSvg = rightarrowIconSvg;
}
