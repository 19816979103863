import { ChangeDetectionStrategy, Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserDetailsService } from '@services/user-details.service';
import { IUserDetails } from '@interfaces/header.interface';
import { LANGUAGES } from '@pages/user-main/user-main.const';
import { NUV_LIST } from '@kit/side-nav-panel/side-nav-panel.const';
import { ITab } from '@interfaces/tab.interface';
import { CurrencyService } from '../../core/services/currency.service';
import { WebsiteService } from '@services/website.service';
import { WelcomeMessageDialogComponent } from './welcome-message-dialog/welcome-message-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { LocalStorageService } from '@services/local-storage.service';
import * as $ from 'jquery';
declare var $: any;
@Component({
  selector: 'skd-user-main',
  templateUrl: './user-main.component.html',
  styleUrls: ['./user-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserMainComponent implements OnInit {
  public languages = LANGUAGES;
  public tabsList$ = new BehaviorSubject(NUV_LIST[0].list);
  public userDetails$: Observable<IUserDetails>;
  timeElapsed:number;
  // clickEvent(){
  //     this.status = !this.status;
  // }


  // TODO temporary


  public language = {
    name: 'EN',
    id: 'en',
    icon: '',
  };

  constructor(
    private websiteService: WebsiteService,
    private readonly dialog: MatDialog,
    private readonly storageService: LocalStorageService,
    private readonly userDetailsService: UserDetailsService,
    private cdr : ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.websiteService.successAnimation.subscribe((data:number)=>{
      this.timeElapsed = data
      let interval = setInterval(()=>{
        document.getElementById('animation').style.display = 'none';
        clearInterval(interval)
      },5000)
      this.cdr.detectChanges();
    })
    this.websiteService.getWelcomeMessage().subscribe(
      (data: any) => {
        if (!this.storageService.getLocalStorageItem('welcomeMessage')) {
          if (data.isPopupEnabled) {
            this.openConfirmationDialog(data);
            this.storageService.setLocalStorageItem('welcomeMessage', true);
          }
        }
      }
    )

 
      // $(".user-main-nav").css("left", "-120px");

    $(document).click(function(){

      if ($(".user-main").hasClass("toggle")) {

        $( ".user-main" ).addClass( "toggle-disable" );
        $( ".user-main" ).removeClass( "toggle" );
        }
        $(".exp-close").click(function(e){
          e.stopPropagation();
          $( ".account-expried-block" ).hide();
        });
     });

    //  $(".user-main-nav").click(function(e){
    //    e.stopPropagation();
    //  });
     $(".user-main-menu").click((e)=>{
      e.stopPropagation();
      if ($(".user-main").hasClass("toggle-disable")) {
        $( ".user-main" ).removeClass( "toggle-disable" );
        $( ".user-main" ).addClass( "toggle" );
        }
        else{
          $( ".user-main" ).addClass( "toggle-disable" );
          $( ".user-main" ).removeClass( "toggle" );
        }

    });


  }
 
  public onNavMenuClick(tabList: ITab[]): void {
    this.tabsList$.next(tabList);
  }


  private openConfirmationDialog(data: any): Observable<void> {
    return this.dialog.open<WelcomeMessageDialogComponent>(WelcomeMessageDialogComponent, {
      width: WelcomeMessageDialogComponent.width,
      height: WelcomeMessageDialogComponent.hight,
      data: data
    }).afterClosed().pipe(take(1));
  }
}
