import { Injectable } from '@angular/core';
import { ErrorType } from '@interfaces/validations/error-type.enum';
import { TranslationsService } from '@services/translations.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorsResolverService {
  constructor(
    private translations: TranslationsService
  ) {

  }
  public getDefaultErrorMessage(errorType: ErrorType, messageCode: string): string | null {
    const errorsByType = this.translations.ERRORS[`ERROR_TYPE_${errorType}`];
    if (errorsByType) {
      return errorsByType[`ERROR_MSG_${messageCode}`];
    }
    return null;
  }
}
