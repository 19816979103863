
  <div class="loading-container">
    <div class="loading-container-inner">
       <span class="circle"></span>
      <span class="circle"></span>
      <span class="circle"></span>
      <span class="circle"></span> 
      <!-- <img src="../../../../assets/images/profile-crizal.png"> -->
    </div>
</div>
 