<div class="deposit-confirmation-dialog">
    <div class="deposit-confirmation-dialog__title">
        <span class="deposit-confirmation-dialog__title_icon"><i class="fe fe-check"></i></span>
        <span class="deposit-confirmation-dialog__title_text">
            <!-- {{ translationsService.FINANCES.REVIEW_AND_CONFIRM_MODAL.TITLE | translate }} -->
            {{ title }}
        </span>
    </div>

    <div [innerHtml]="content" class="content-ch"></div>
    <!-- <div class="deposit-confirmation-dialog__content">
        <div class="form-table">
  
        </div>
    </div> -->

    <div class="deposit-confirmation-dialog__buttons">
        <button skdButton mat-dialog-close class="dialog-button cancel-button" [size]="buttonSizes.middle"
            [mode]="buttonModes.primary">
            {{ translationsService.COMMON.BUTTONS.OKAY | translate | uppercase }}
        </button>
        <!-- <button skdButton class="dialog-button confirm-button" [size]="buttonSizes.middle" [mode]="buttonModes.primary">
            {{ translationsService.COMMON.BUTTONS.CONFIRM | translate | uppercase }}
        </button> -->
    </div>
</div>