import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SkdPortalService } from './portal.service';

@Component({
  selector: 'skd-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class SkdPortalComponent implements OnInit {
  @Input() public name: string;

  public componentPortal: ComponentPortal<unknown>;
  public visible$: Observable<boolean>;

  constructor(
    private portalService: SkdPortalService,
  ) {}

  public ngOnInit(): void {
    const options = this.portalService.getOptions(this.name);
    this.componentPortal = new ComponentPortal(options.component);
    this.visible$ = options.visible$;
  }
}
