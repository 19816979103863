import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_GET_ABOUT, API_GET_CUSTOM_COUNTER, API_GET_FAQ, API_GET_HOW_IT_WORKS, API_GET_LANGUAGES, API_GET_LOGO, API_GET_PRIVACY_POLICY, API_GET_TERMS_OF_SERVICES, API_GET_WELCOME_MESSAGE, BASE_SOUND_MATE_URL, API_GET_APPLICATION, API_GET_NEWS} from '@constants/request.const';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {

  successAnimation = new Subject()

  constructor(private http: HttpClient) { }


  public getWelcomeMessage(): Observable<void> {
    return this.http.get<void>(`${BASE_SOUND_MATE_URL}${API_GET_WELCOME_MESSAGE}`);
  }

  public getFAQ(): Observable<any> {
    return this.http.get<any>(`${BASE_SOUND_MATE_URL}${API_GET_FAQ}`);
  }

  public getHowItWorks(): Observable<any> {
    return this.http.get<any>(`${BASE_SOUND_MATE_URL}${API_GET_HOW_IT_WORKS}`);
  }

  public getTermsServices(): Observable<any> {
    return this.http.get<any>(`${BASE_SOUND_MATE_URL}${API_GET_TERMS_OF_SERVICES}`);
  }

  public getPrivacyPolicy(): Observable<any> {
    return this.http.get<any>(`${BASE_SOUND_MATE_URL}${API_GET_PRIVACY_POLICY}`);
  }

  public getAbout(): Observable<any> {
    return this.http.get<any>(`${BASE_SOUND_MATE_URL}${API_GET_ABOUT}`);
  }
  public getApplication(): Observable<any> {
    return this.http.get<any>(`${BASE_SOUND_MATE_URL}${API_GET_APPLICATION}`);
  }

  public getLanguages(): Observable<any> {
    return this.http.get<any>(`${BASE_SOUND_MATE_URL}${API_GET_LANGUAGES}`);
  }

  public getCustomCounter(): Observable<any> {
    return this.http.get<any>(`${BASE_SOUND_MATE_URL}${API_GET_CUSTOM_COUNTER}`);
  }

  public getLogo(): Observable<any> {
    return this.http.get<any>(`${BASE_SOUND_MATE_URL}${API_GET_LOGO}`);
  }
  public getNews(): Observable<any> {
    return this.http.get<any>(`${BASE_SOUND_MATE_URL}${API_GET_NEWS}`);
  }

}
