
export enum ButtonSizes {
  large = 'large',
  middle = 'middle',
  small = 'small'
}

export enum ButtonModes {
  primary = 'primary',
  secondary = 'secondary',
  danger = 'danger'
}
