import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { ButtonSizes, ButtonModes } from './button.const';

@Directive({
  selector: 'button[skdButton]'
})
export class ButtonDirective implements OnInit, OnChanges {
  @Input()
  public size: ButtonSizes = ButtonSizes.middle;

  @Input()
  public mode: ButtonModes = ButtonModes.primary;
  public defaultClassNamePart = 'skd-button';

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) { }

  public ngOnInit(): void {
    this.renderer.addClass(this.elementRef.nativeElement, this.defaultClassNamePart);
    this.renderer.addClass(this.elementRef.nativeElement, this.getClassName(this.mode));
    this.renderer.addClass(this.elementRef.nativeElement, this.getClassName(this.size));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.mode?.currentValue) {
      if (changes.mode.previousValue) {
        this.renderer.removeClass(this.elementRef.nativeElement, this.getClassName(changes.mode.previousValue));
      }
      this.renderer.addClass(this.elementRef.nativeElement, this.getClassName(changes.mode.currentValue));
    }
    if (changes.size?.currentValue) {
      if (changes.size.previousValue) {
        this.renderer.removeClass(this.elementRef.nativeElement, this.getClassName(changes.size.previousValue));
      }
      this.renderer.addClass(this.elementRef.nativeElement, this.getClassName(changes.size.currentValue));
    }
  }

  private getClassName(type: ButtonSizes | ButtonModes): string {
    return `${this.defaultClassNamePart}--${type.toLowerCase()}`;
  }
}
