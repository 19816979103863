<div class="toggle-disable user-main">
  <!-- <skd-side-nav-panel class="user-main-nav" (tabsList)="onNavMenuClick($event)"></skd-side-nav-panel> -->
  <!-- <skd-side-nav-panel class="user-main-nav"></skd-side-nav-panel> -->
  <skd-side-menu class="side-menu"></skd-side-menu>
  <div class="user-main-menu">
    <span><i class="fe fe-menu"></i></span>
  </div>
  <div class="user-main-page">
    <skd-user-header [languages]="languages" [language]="language">
    </skd-user-header>

    <!--    TODO need move to separate components-->
    <!--    <skd-tabs [tabsList]="tabsList$ | async"></skd-tabs>-->
    <div class="user-main-page__content">
   
      <div class="col-12 p-0">
        <router-outlet></router-outlet>
      </div>

    </div>
    <skd-user-footer></skd-user-footer>
  </div>
</div>

<!-- <div id='animation' [ngClass]="timeElapsed <= 1 ? 'success-animation':'d-none'">
  <img src="../../../../../../assets/images/blast.gif">
  </div> -->
  <div id='animation'[ngClass]="timeElapsed <= 1 ? 'success-animation firework-block-main':'d-none'">
    <div class="firework"></div>
    <div class="firework"></div>
    <div class="firework"></div>
    <div class="firework"></div>
    <div class="firework"></div>
    <div class="firework"></div>
  </div>