<div class="page-sidebar">
    <a class="logo-box" [href]="landingHomePage">
        <span class="logo-block-main"><img src="../../../../../assets/images/crizal-logo-white.png"></span>
        <i class="icon-radio_button_unchecked" id="fixed-sidebar-toggle-button"></i>
        <i class="icon-close" id="sidebar-toggle-button-close"></i>
    </a>

    <div class="page-sidebar-inner">
        <div class="page-sidebar-menu">
            <ul class="accordion-menu">
                <li *ngFor="let item of menuItem; index as i">
                    <ng-container *ngIf="item.isVisible">
                        <a *ngIf="item?.subModules.length === 0" href="javascript:void(0)">
                            <span (click)="mainMenuToggle(item)" [routerLink]="item?.link" routerLinkActive="nav-list-item-active"  [routerLinkActiveOptions]="{exact: true}" (click)="activeMenu=false"> <i [innerHTML]="item.icon | safe" class="sidemenu-icon"></i> {{item.name | translate}}</span><i class="accordion-icon"></i>
                         </a>
                         <a *ngIf="item?.subModules.length > 0" href="javascript:void(0)" >
                            <span (click)="menuActive(item,i);item.subModules.length === 0 ? activeMenu=false :''" [ngClass]="activeMenu == item?.name ? 'nav-list-item-active':''"> <i [innerHTML]="item.icon | safe" class="sidemenu-icon"></i> {{item.name | translate}}<i [ngClass]="item?.subModules.length > 0 ? 'accordion-icon fa fa-angle-left':'accordion-icon'"></i></span>
                        </a>
                    </ng-container>
                   <ul>
                       <li *ngFor="let list of item.list" (click)="hamberganMenu(item)">
                        <ng-container *ngIf="list?.isVisible">
                            <a href="javascript:void(0)"  [routerLink]="list?.link" routerLinkActive="nav-list-item-active"  [routerLinkActiveOptions]="{exact: true}"  *ngIf="list?.isVisible && list?.name !== 'My_Profile'" >
                                {{list.name | translate}}
                            </a>
                        </ng-container>
                        <span *ngIf="list?.name  === 'Youtube' " class="menu-count">{{list.count}}  </span>
                        </li>
                   </ul>
               </li>
           </ul>
        </div>
    </div>
    <div class="col-12">
        <button class="sidebar__user-btn" type="button" (click)="logoutBtn()">
            <i class="fe fe-power mr-2"></i>
           {{ translations.HEADER.MENU.LOG_OUT | translate }}
            <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"></path></svg> -->
        </button>
    </div>

</div>