import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SOUND_MATE_DEV_PATH } from '@constants/environyment-pathes.const';
import { AuthManagerService } from '@services/auth-manager.service';
import { LocalStorageService } from '@services/local-storage.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {

  baseUrl = SOUND_MATE_DEV_PATH;

  constructor(
    private readonly authManager: AuthManagerService, private localStorageService: LocalStorageService, private router : Router
  ) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const BearerCheck  = this.localStorageService.getLocalStorageItem('unicId');
   
   

    if(BearerCheck) {
     return next.handle(request).pipe(
      catchError((error : HttpErrorResponse)=>{
        if (error.status == 401 && request.url.includes(this.baseUrl)) {
          this.router.navigateByUrl('/login');
        }
        return throwError(error);
      })
    )
    } else {
      
      request = this.authManager.isAuthorized
    
      ? request.clone({

          setHeaders: {

            Authorization: `Bearer ${this.authManager.accessToken}`,

          }

        })

      : request;
     

      return next.handle(request).pipe(
        catchError((error : HttpErrorResponse)=>{
          if (error.status == 401 && request.url.includes(this.baseUrl)) {
            this.router.navigateByUrl('/login');
          }
          return throwError(error);
        })
      )

    }
    
  }
}
