import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IconsClass } from '@assets/images/icons.class';
import { USER_MEMBERSHIP_PLANS } from '@constants/routes.const';
import { UnSubscriber } from '@constants/unSubscriber';
import { ICurrency } from '@interfaces/currency.interface';
import { IUserDetails } from '@interfaces/header.interface';
import { IOption } from '@interfaces/selects.interface';
import { HEADER_MENU_LIST } from '@kit/user-header/user-header.const';
import { TranslateService } from '@ngx-translate/core';
import { AuthManagerService } from '@services/auth-manager.service';
import { AuthService } from '@services/auth.service';
import { CurrenciesService } from '@services/currencies.service';
import { DataSharingService } from '@services/data-sharing.service';
import { LocalStorageService } from '@services/local-storage.service';
import { MyProfileService } from '@services/my-profile.service';
import { TranslationsService } from '@services/translations.service';
import { UserDetailsService } from '@services/user-details.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { finalize, take, takeUntil } from 'rxjs/operators';
import { CurrencyService } from '../../services/currency.service';

import { FRONT_END_API_PROD_PATH, LANDING_PAGE_HOME, MONEY_BACK_GUARANTEE } from '@constants/environyment-pathes.const';
import { LANGUAGES } from '@pages/user-main/user-main.const';
import { DynamicTranslatonService } from '@pages/welcome/dynamic_trans_service/dynamic-translaton.service';
import { FooterEnableService } from '@pages/welcome/landing-page/footer-enable.service';
import { ModulesService } from '@services/modules.service';

declare var $;
@Component({
  selector: 'skd-user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserHeaderComponent extends UnSubscriber implements OnInit {
  public profileUrl = FRONT_END_API_PROD_PATH
  public support = MONEY_BACK_GUARANTEE;
  public landingHomePage = LANDING_PAGE_HOME + 'Home/Home' +`${'?'}${'culture='}`
  public valName;
  @Input() public languages;
  @Input() public language;
  @Input() public userDetails;
  urlCurrency:any;
  activityData:any [] = [];
  pageNumber:number = 1;
  public readonly pageSize = 5;
  public notificationCount = 0;
  public userDetails$ = new BehaviorSubject<IUserDetails>(null);

  public updatePlanUrl = USER_MEMBERSHIP_PLANS;
  public currencyOptions: IOption<string>[] = [];
  public headerMenuList$ = new BehaviorSubject(Object.values(HEADER_MENU_LIST));

  public icons = IconsClass;
  public myProfileImage: string;

  public selectedCurrencyOption$ = new BehaviorSubject<IOption<string>>(null);
  public currenciesByCode = new Map<string, ICurrency>();
  public currentCurrencyCode;
  public languageCode;
  content;
  contentCheck:boolean;
  myAdmin:any[]=[];
  loadingCheck = false;
  current_url: string;
  res: any;
  val: any;
  currenyCodeData
  public redirectUrl: string;



  // userDetails;

  // userDetails;

  constructor(
    public readonly translate: TranslateService,
    public readonly translations: TranslationsService,
    public readonly router: Router,
    private readonly auth: AuthService,
    private readonly authManager: AuthManagerService,
    private currencyService: CurrencyService,
    private currenciesService: CurrenciesService,
    private readonly userDetailsService: UserDetailsService,
    private readonly myProfileService: MyProfileService,
    private cdr: ChangeDetectorRef,
    private storage: LocalStorageService,
    public dataSharingService: DataSharingService,
    public dynamicTrans : DynamicTranslatonService,
    public enableService : FooterEnableService,
    private moduleService: ModulesService,
    private activateRoute : ActivatedRoute
  ) { super(); }
 
  // redirectUrlFind(){
  //   this.redirectUrl = this.router.url;
  //   this.valName = this.valName + `&redirect_url=${this.redirectUrl}`
  // }

  public ngOnInit(): void { 
    this.activityLogs();
this.currencyService.languageSubject.subscribe(val => this.valName = val )

    this.currentCurrencyCode = this.currencyService.getCurrentCurrency()?.code;
     this.myProfileService.getMyProfileData().subscribe((data) => {
      this.myProfileImage = data.avatarUrl;
    });

    this.enableService.interFaceEnable().subscribe({next:(data:any)=>{
      this.contentCheck = true;
      this.content = data;
      this.cdr.detectChanges();
    }});

    this.dataSharingService.userHeaderDetails$.subscribe(() =>
      combineLatest([
        this.currenciesService.getCurrencies(),
        this.userDetailsService?.getUserHeaderDetails(this.currentCurrencyCode)
      ]).pipe(
        takeUntil(this.destroy$)
      ).subscribe(([currencies, headerDetails]) => {
        this.currenyCodeData = currencies
        this.enableService.subject.next(headerDetails)
        this.userDetails$.next(headerDetails);
        this.currenciesByCode = currencies.reduce((currMap, value) => currMap.set(value.code, value), new Map<string, ICurrency>());
        this.currencyOptions = currencies.map(c => ({
          name: `${c.code} (${c.sign})`,
          id: c.code
        }));
        this.currencyOptions = this.currencyOptions.sort((a, b) => a.name.localeCompare(b.name));
        this.language = this.storage.getLocalStorageItem('SelectedLanguage');
        const selectedLang = LANGUAGES.find(c => c.id === this.language?.id);

        this.activateRoute.queryParams.pipe(take(1)).subscribe(data=> this.storage.setLocalStorageItem('setLanguageCode',data.culture))
     
        this.languageCode = this.storage.getLocalStorageItem('setLanguageCode');
        const selectedLangNew = LANGUAGES.find(c => c.id === this.languageCode);
       

        this.activateRoute.queryParams.pipe(take(1)).subscribe(data=>{
          this.urlCurrency = this.currenciesByCode.get(data.currencyCode);
          if(this.urlCurrency != undefined){
            this.currencyService.setCurrency(this.urlCurrency);
            this.currentCurrencyCode = this.currencyService.getCurrentCurrency()?.code;
            const selectedOption = this.currencyOptions.find(c => c.id === this.currentCurrencyCode);
            this.selectedCurrencyOption$.next(selectedOption);
          }
          else{
            const selectedCurrency = this.currenciesByCode.get(this.currentCurrencyCode);
            this.currencyService.setCurrency(selectedCurrency);
            this.currentCurrencyCode = this.currencyService.getCurrentCurrency()?.code;
            const selectedOption = this.currencyOptions.find(c => c.id === this.currentCurrencyCode);
            this.selectedCurrencyOption$.next(selectedOption);
          }
        })
       
     
        if (!this.languageCode) {
            if (!selectedLang) {
              this.language = {
                name: 'EN',
                id: 'en',
                icon: IconsClass.GBFlagSvg,
              }
              this.translate.use(this.language.id);
            }else{
              this.language = selectedLang;
              this.translate.use(this.language.id);
            }
        }else{
          this.language = selectedLangNew;
          this.translate.use(this.languageCode);
        }
        const lang = selectedLangNew && selectedLangNew?.id ? selectedLangNew?.id : this.languageCode ?? 'en'
        let val = this.landingHomePage + lang
        this.valName = val + `&currencyCode=${this.currentCurrencyCode}`
        this.cdr.detectChanges();
      })
    )
   this.refreshCount();
   this.userDetailsService.activityCountUpdated.subscribe(()=>this.refreshCount())
  }
  refreshCount(){
    this.moduleService.getMenuItems().pipe(take(1)).subscribe(items=>{
      this.notificationCount = items.find(item => item.name == "Notifications")['count']
      this.cdr.detectChanges();
    })
  }
  public onMenuClick(point: string): void {
    if (point === HEADER_MENU_LIST.logOut.id) {
      this.signOut();
    } else {
      this.router.navigate([point]);
    }
  }

  public onCurrencySelect(currency: IOption<string>): void {
    this.loadingCheck = true;
    this.currenciesService.setCurrency(currency.id).pipe(
      takeUntil(this.destroy$)
    ).subscribe((data) => {
      const selectedCurrency = this.currenciesByCode.get(currency.id);
      this.currencyService.setCurrency(selectedCurrency);
      this.loadingCheck = false;
      window.location.reload();
    },
      err => {
       this.loadingCheck = false;
        if (err?.error?.defaultErrorMsgKey) {
          this.dynamicTrans.dynamicTranslationService(err.error.defaultErrorMsgKey);
        }
      }
    );

  }

  public onLanguageSelect(language: IOption<string>): void {
    let val = this.landingHomePage + language.id
    this.valName = val
    this.currencyService.setLanguage(language);
    this.translate.use(language.id);
  }

  private signOut(): void {
    this.storage.removeLocalStorageItem('sociallogin');
    this.storage.removeLocalStorageItem('welcomeMessage');
    this.storage.removeLocalStorageItem('CURRENCY');
    this.storage.removeLocalStorageItem('accessToken');
    this.auth.signOut(this.authManager.refreshToken).pipe(
      takeUntil(this.destroy$),
      finalize(() => {
        this.authManager.clearAuthData();
        window.location.href = this.landingHomePage;
      })
    ).subscribe();
  }

  activityLogs(){
    this.userDetailsService.getActivityStatus(this.pageNumber, this.pageSize).pipe(take(1),takeUntil(this.destroy$)).subscribe((data:any)=>{
      this.activityData = data.body;
      this.cdr.detectChanges();
    })
  }
}
