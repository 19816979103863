import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkdPortalComponent } from './portal.component';
import { PortalModule } from '@angular/cdk/portal';



@NgModule({
  declarations: [SkdPortalComponent],
  exports: [SkdPortalComponent],
  imports: [
    CommonModule,
    PortalModule,
  ]
})
export class SkdPortalModule {}
