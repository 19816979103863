import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  public setLocalStorageItem<T>(key: string, value: T): void {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  public getLocalStorageItem<T>(key: string): T {
    const data = localStorage.getItem(key);

    return data ? JSON.parse(data) : null;
  }

  public updateLocalStorageItem(key: string, value: string): void {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  public removeLocalStorageItem(key: string): void {
    localStorage.removeItem(key);
  }

  public clearLocalStorage(): void {
    localStorage.clear();
  }
}
