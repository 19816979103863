import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IMyProfileInterface } from '@interfaces/profile.interface';
import { API_PROFILE_INFO, API_PROFILE_SAVE_INFO, BASE_SOUND_MATE_URL } from '@constants/request.const';

@Injectable()
export class MyProfileService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  public getMyProfileData(): Observable<IMyProfileInterface> {
    return this.http.get<IMyProfileInterface>(`${BASE_SOUND_MATE_URL}${API_PROFILE_INFO}`);
  }



  public MyProfileData(img: string): Observable<void>  {
    return this.http.post<void>(`${BASE_SOUND_MATE_URL}${API_PROFILE_SAVE_INFO}`, {
      base64: img,
      extensions: 1
    });
  }
}

