export const LOGIN = 'login';
export const SIGN_UP = 'sign-up';
export const RESET_PASSWORD = 'reset-password';
export const COUNTRY_BLOCK_MESSAGE = 'country-block-message';
export const FAILED_ACTIVATION_REQUEST = 'failed-activation-request';
export const SUCCESS_ACTIVATION_REQUEST = 'success-activation-request';
export const FAILED_RESET_REQUEST = 'failed-reset-request';
export const SUCCESS_RESET_REQUEST = 'success-reset-request';
export const SUCCESS_FORGOT_REQUEST = 'success-forgot-request';
export const SUCCESS_SOCIAL_REQUEST = 'success-scoial-request';
export const RESET_FROM_LINK = 'reset-from-link';
export const LOGIN_BY_LINK = 'login-by-link';
export const FAILED_LOGIN = 'failed-login';

export const TUTORIALS = 'torurials'
export const TUTORIAL_PAGE = 'tutorial-page'
export const USER = '/user';
export const CALCULATOR = 'calculator';
export const EARN = 'earn';
export const DIRECT = 'direct';
export const PROMO = 'promo';
export const STATISTICS = 'statistics';
export const LEADER = 'leader-board';
export const LOGS = 'logs';
export const REPRESENTATIVES = 'representatives';
export const REPRESENTATIVES_COUNTRY_AGENTS = 'country-agents';
export const REPRESENTATIVES_APPLICATION_FORM = 'application-form';
export const REPRESENTATIVES_AGENT_APP_HISTORY = 'agent-application-history';
export const REPRESENTATIVES_MANAGE_CODE = 'manage-code';
export const REPRESENTATIVES_SALES_REPORT = 'sales-report';
export const REPRESENTATIVES_BUY_VOUCHER = 'buy-voucher';
export const STATISTICS_EARNING = 'earning';
export const STATISTICS_MYEARNING = 'my-earning';
export const MONEY = 'money';
export const RADIO = 'radio';
export const BOOSTER = 'booster'
export const YOUTUBE = 'youtube';
export const MOVIE = 'movie';
export const TOTURIALS = 'torurials';
export const UNLOCK_ACTIVITY = 'unlock-activity';
export const STATIONS = 'stations';
export const STATION = 'station';
export const MY_PROFILE = 'my-profile';
export const ACTIVITY_LOGS = 'activity-logs';
export const RADIO_EARN_GUIDE = 'radio-earn-guide';
export const CHOICE_FAVORITE_RADIO_GENRES = 'choice-favorite-radio-genres';
export const TRENDING_RADIO_STATIONS = 'trending-radio-stations';

export const YOU_TUBE_ADVERT_LIST = 'you-tube-advert-list'
export const MOVIE_ADVERT_LIST = 'movie-advert-list'
export const SETTINGS = 'settings';
export const Leaderboard = 'leaderboard';
export const USER_INFO = 'user-info';
export const PASSWORD = 'password';
export const PIN = 'pin';
export const TWO_FA = '2fa';
export const PREFERENCES = 'preferences';
export const PAYMENTS = 'payments';
export const RECENT_PAYMENTS = 'recent-payments'
export const MY_PROFILES ='My Profile'
export const ACTIVITY_LOG = 'Activity Logs'

export const REFERRALS = 'referrals';
export const REFERRALS_DIRECT = 'direct';
export const REFERRALS_PROMO = 'promo';
export const REFERRALS_RENTED = 'rented';
export const REFERRALS_INDIRECT = 'indirect';
export const REFERRALS_PROMO_HOME = 'promo-tools';
export const REFERRALS_PROMO_REGISTER = 'register';
export const REFERRALS_PROMO_TREE_CHART = 'indirect-chart';
export const REFERRALS_CALCULATOR = 'ref-calculator';
export const REFERRALS_COMPETITIONS = 'ref-competition';

export const MEMBERSHIP = 'membership';
export const MEMBERSHIPS = 'memberships';
export const MEMBERSHIP_PLANS = 'plans-list';
export const MEMBERSHIP_PLANS_DETAILS = 'plans-details';
export const SUBSCRIPTION_PLANS = 'Subscription_Plans';
export const INCOME_CALCULATOR = 'Income_Calculator'

export const FINANCES = 'finances';
export const DEPOSIT_RESULT = 'deposit-result';
export const TRANSFER_RESULT = 'transfer-result';
export const WITHDRAW_RESULT = 'withdraw-result';
export const WITHDRAW_RESULT_NEW = 'user/finances/withdraw-result'
export const EXTRA_MINUTES_RESULT = 'extra-minutes-result';
export const DEPOSIT = 'deposit';
export const TRANSFER= 'transfer';
export const WITHDRAW = 'withdraw';
export const VOUCHER ='voucher';
export const RADIO_NAME="Radio";
export const YOUTUBE_NAME = 'Youtube'
export const MOVIE_NAME = 'Movie'
export const DEPOSIT_NAME='Deposit'
export const TRANSFER_NAME='Send'
export const WITHDRAW_NAME='Withdraw'
export const VOUCHER_NAME ='Vouchers'
export const EXTRA_MINUTES_NAME='Extra Minutes'
export const LOGS_NAME ='Logs'
export const DIRECT_REFERRALS='Direct referrals'
export const INDIRECT_REFERRALS='Indirect referrals'
export const HOME='home'
export const YOU_TUBE_ADVERT = 'you-tube-advert-list'
export const MOVIE_ADVERT = 'movie-advert-list'
export const COUNTRY_AGENT ='Country Agent'
export const MANAGE_CODE = 'Manage Code';
export const SALES_REPORT = 'Sales Report'
export const BUY_AIRTIME_CODES = 'Buy Airtime Codes'
export const APPLICATION_FORM = 'Application Form'
export const TOP_TEN_EARNERS = 'top-ten-earners'
export const TOP_TEN_REFERRAL = 'top-ten-referrals'
export const TOP_TEN_EAR_DAYS = 'top-ten-ear-days'
export const TOP_TEN_REF_DAYS = 'top-ten-ref-days'
export const PAID_CATEGORY = 'paid-category'



export const EARN_RADIO = `${EARN}/${RADIO}`;
export const EARN_RADIO_EARN_RADIO_GUIDE = `${EARN_RADIO}/${RADIO_EARN_GUIDE}`;
export const EARN_RADIO_CHOICE_FAVORITE_RADIO_GENRES = `${EARN_RADIO}/${CHOICE_FAVORITE_RADIO_GENRES}`;
export const EARN_YOUTUBE = `${EARN}/${YOUTUBE}/${YOU_TUBE_ADVERT}`;
export const PAID_CATEGORYS = `${EARN}/${YOUTUBE}/${PAID_CATEGORY}`
export const EARN_MOVIE = `${EARN}/${MOVIE}/${MOVIE_ADVERT}`;
export const BOOSTER_ACCOUNT = `${EARN}/${'booster'}/${'booster-landing-page'}`
export const TUTORIALS_LINK = `${EARN}/${TUTORIALS}/${TUTORIAL_PAGE}`
export const UNLOCKED_VIDEO_LOGS_LINK= `${EARN}/${UNLOCK_ACTIVITY}`;
export const CURRENT_STATION = `${STATIONS}/${STATION}`;
export const USER_EARN_RADIO_STATIONS = `${USER}/${EARN}/${RADIO}/${STATIONS}`;

// export const USER_FINANCES = `${USER}/${FINANCES}`;
// export const USER_FINANCES = `${USER}/${FINANCES}/${'withdraw'}`;
export const USER_FINANCES = `${USER}/${FINANCES}/${'logs'}`;

export const USER_SETTINGS = `${USER}/${SETTINGS}`;
export const USER_SETTINGS_PAYMENTS = `${USER}/${SETTINGS}/${PAYMENTS}`;

export const USER_MEMBERSHIP_PLANS = `${USER}/${MEMBERSHIP}/${MEMBERSHIP_PLANS}`;

export const PLAY_VIDEO = 'play-available-video'
export const USER_EARN_YOUTUBE_PLAY_VIDEO = `${USER}/${EARN}/${YOUTUBE}/${PLAY_VIDEO}`;
export const DIRECT_REF = `${REFERRALS}/${DIRECT}`;
export const PROMO_REF = `${REFERRALS}/${PROMO}/${REFERRALS_PROMO_HOME}`
export const PROMO_TREE_CHART = `${REFERRALS}/${PROMO}/${REFERRALS_PROMO_TREE_CHART}`
export const  COUNTRY_CODE_LINK = `${REPRESENTATIVES}/${'country-agents'}`
export const  MANAGE_CODE_LINK = `${REPRESENTATIVES}/${'manage-code'}`
export const SALES_REPORT_LINK = `${REPRESENTATIVES}/${'sales-report'}`
export const BUY_AIRTIME_CODES_LINK = `${REPRESENTATIVES}/${'buy-voucher'}`
export const APPLICATION_FORM_LINK = `${REPRESENTATIVES}/${'application-form'}`
export const MEMBERSHIP_INCOME_CALCULATOR = `${'plans-list'}/${'income-calculator'}`
export const MEMBERSHIP_BOOSTER_ACCOUNT = `${'booster-page'}`
export const MEMBERSHIP_BOOSTER_ACCOUNT_PLANS = `${'booster-plan'}`

export const Referral_Calculator = `${REFERRALS}/${'ref-calculator'}/${'ref-calculator-showing-page'}`
export const Referral_Competition = `${REFERRALS}/${'ref-competition'}/${'competition-view'}`