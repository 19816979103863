import { environment } from '@environments/environment';
import { MEMBERSHIP, MEMBERSHIPS } from './routes.const';

export const BASE_SOUND_MATE_URL = environment.baseSoundMateUrl + '/';
export const BASE_RADIO_STREAMING_URL = environment.baseRadioStreamingUrl + '/';
export const BASE_YOUTUBE_THUMBNAIL_URL = environment.baseYouTubeThumbnailUrl;
export const BASE_IP_CURRENCY_URL = environment.baseIPCurrencyUrl;
export const BASE_IP_CURRENCY_NEW_URL = environment.baseIPNewCurrencyUrl;
export const API = 'api';
export const USER = 'User';
export const User = 'user';
export const ME = 'me';
export const AUTH = 'auth';
export const VERSION1 = 'v1'
export const LOGIN = 'login';
export const REFRESH = 'refresh';
export const REFRESH_TOKEN = 'refreshToken';
export const GET_LOGIN_FORM = 'GetLoginForm';
export const LOGOUT = 'logout';
export const SIGNUP = 'SignUp';
export const SIGNUP_WITH_PASSWORD = 'SignUpWithPassword';
export const LOGIN_WITH_PASSWORD = 'loginWithPassword';
// export const CREATE = 'Create';
export const LOGIN_WITH = 'LoginWith';
export const RADIO_STATION = 'Radiostation';
export const GENRE = 'Genre';
export const FAVORITE = 'Favorite';
export const TRENDS = 'trends'
export const RATING = 'rating';
export const LISTENING = 'Listening';
export const DETAILS = 'details';
export const HEADER_DETAILS = 'HeaderDetails';
export const HEALTH_CHECK = 'healthCheck';
export const START = 'start';
export const UPDATE = 'update';
export const RADIO_STREAMING = 'radiostreaming';
export const GUIDE_SHOWN = 'guide-shown';
export const GENRES_SELECTION_SHOWN = 'genres-selection-shown';
export const TOP = 'top';
export const RECENTLY_LISTEN_RADIO_STATIONS = 'RecentlyListenRadiostations';

export const START_LISTENING = 'StartListening';
export const STOP_LISTENING = 'StopListening';

export const START_PAID_LISTENING = 'StartPaidListening';
export const COMPLETE_PAID_LISTENING = 'CompletePaidListening';
export const UPDATE_PAID_MINUTES = 'UpdatePaidMinutesListenedToday';
export const LISTENED_PAID_MIN = 'listenedPaidMinutes';

export const PROFILE = 'profile';
export const INFO = 'info';

export const FILE = 'File';
export const SAVE_INFO = 'profile_img';

export const SETTINGS = 'Settings';

export const MODULES = 'Modules';
export const MENU_ITEMS = 'menuitems';
export const  DEPOSIT_QR = 'DepositQRCode'
export const GET_ONLINE_USERS = 'GetOnlineUsers'
export const TUTORIALS = 'Tutorials'

export const WORLD_BUBBLE_CHART = `${API}/${VERSION1}/${USER}/${GET_ONLINE_USERS}`
export const API_LOGIN_V1 = `${API}/${VERSION1}/${AUTH}`;
export const API_USER_SIGNUP_V1 = `${API}/${VERSION1}/${User}`;
export const API_SIGNUP_SIGNUP_WITH_PASSWORD = `${API}/${SIGNUP}/${SIGNUP_WITH_PASSWORD}`;
export const API_LOGIN_V1_PASSWORD = `${API_LOGIN_V1}/${LOGIN}`;
export const API_GENRE = `${API}/${GENRE}`;
export const API_RADIO_STATION = `${API}/${RADIO_STATION}`;
export const API_FAVORITE_RADIO_STATIONS = `${API}/${RADIO_STATION}/${FAVORITE}`;
export const API_LISTENING = `${API}/${LISTENING}`;
export const API_LISTENING_DETAILS = `${API}/${LISTENING}/${DETAILS}`;
export const API_SETTINGS_RADIO_STREAMING = `${API}/${SETTINGS}/${RADIO_STREAMING}`;
export const API_USER_VERSION1_DETAILS = `${API}/${VERSION1}/${User}/${HEADER_DETAILS}`;
export const API_PROFILE_INFO = `${API}/${VERSION1}/${FILE}/${INFO}`;

export const API_PROFILE_SAVE_INFO = `${API}/${VERSION1}/${FILE}/${SAVE_INFO}`;
export const API_REFRESH_TOKEN_REFRESH = `${API}/${REFRESH_TOKEN}/${REFRESH}`;

export const API_RADIO_STATION_HEALTH_CHECK = `${API}/${RADIO_STATION}/${HEALTH_CHECK}`;
export const API_TRENDING_RADIO_STATIONS = `${API}/${RADIO_STATION}/${TRENDS}`;
export const API_RECENTLY_LISTEN_RADIO_STATIONS = `${API}/${RADIO_STATION}/${RECENTLY_LISTEN_RADIO_STATIONS}`;

export const PAYMENT = `Payments`;
export const PAYMENT_PROCESSORS = `PaymentProcessors`;
export const DEPOSIT_PAGE_DATA = `DepositPageData`;
export const DEPOSIT_LINK = `DepositLink`;
export const API_DEPOSIT_DEPOSIT_PAGE_DATA = `${API}/${VERSION1}/${PAYMENT}/${DEPOSIT_PAGE_DATA}`;
export const API_DEPOSIT_VERSION1_DEPOSIT_LINK = `${API}/${VERSION1}/${PAYMENT_PROCESSORS}/${DEPOSIT_LINK}`;
export const DEPOSIT_QRCODE = `${API}/${VERSION1}/${PAYMENT_PROCESSORS}/${DEPOSIT_QR}`

export const BALANCES = 'Balances';
export const ACTIVITIES = 'Activities';
export const GET_USER_NAME_BY_NAME = 'GetUserNameByName';
export const GET_USERNAME_BY_ID = "GetUserNameById";
export const GET_DUPLICATE_USERNAME = 'DuplicateUsername';
export const SET_USERNAME = 'SetUserName';
export const GET_BALANCE_SETTINGS = 'GetBalanceWalletsConfigurationSettings';
export const GET_WEBSITE_SETTING_APPLICATION = 'GetApplicationSettings'

// GET /api/Websites/GetWelcomePopup
export const WEBSITES = 'Websites';
export const WELCOME_POPUP = 'GetWelcomePopup';
export const ADDONS = 'addons';
export const GET_HOW_IT_WORKS = 'GetHowItWorks';
export const GET_FAQ = 'GetFAQ'
export const GET_TERMS_OF_SERVICES = 'GetTermsofService';
export const GET_PRIVACY_POLICY = 'GetPrivacyPolicy';
export const GET_ABOUT = 'GetAbout';
export const GET_APPLICATION = 'GetApplicationSettings';
export const GET_LANGUAGES = 'GetLanguages';
export const GET_CUSTOM_COUNTER = 'GetCustomCounter';
export const GET_LOGO = 'GetLogoAndFavicon';
export const GET_WEBSITE_SETTING = 'GetWebsiteSettings';
export const PRE_LOGIN = 'prelogin';
export const SET_PIN_NUMBER = 'SetPinNumber';

export const API_USER_VERSION1_BALANCES = `${API}/${VERSION1}/${User}/${BALANCES}`;
export const API_USER_VERSION1_ACTIVITIES = `${API}/${VERSION1}/logs/${USER}${ACTIVITIES}`;
export const API_VERSION1_USER_NAME = `${API}/${VERSION1}/${User}/${GET_USER_NAME_BY_NAME}`;
export const API_VERSION1_GET_USERNAME = `${API}/${VERSION1}/${User}/${GET_USERNAME_BY_ID}`;
export const API_VERSION1_USER_EXIST = `${API}/${VERSION1}/${User}/${GET_DUPLICATE_USERNAME}`;
export const API_VERSION1_USER_ENABLE = `${API}/${VERSION1}/${ADDONS}/${GET_WEBSITE_SETTING}`;
export const API_VERSION1_USER_ENABLE_APPLICATION = `${API}/${VERSION1}/${ADDONS}/${GET_WEBSITE_SETTING_APPLICATION}`;
export const API_VERSION1_USER_SET_USERNAME = `${API}/${VERSION1}/${User}/${SET_USERNAME}`;
export const API_VERSION1_USER_SET_PIN_NUMBER = `${API}/${VERSION1}/${PRE_LOGIN}/${SET_PIN_NUMBER}`;
export const API_BALANCE_WALLETS_SETTINGS = `${API}/${SETTINGS}/${GET_BALANCE_SETTINGS}`;

// GET /api/Modules/MenuItems
export const API_MODULES_MENU_ITEMS = `${API}/${VERSION1}/memberships/${MENU_ITEMS}`
export const YOUTUBE_LIKE = 'PostYouTubeLike'
export const GET_YOUTUBE_LIKES = 'GetYouTubeLikes'
export const YOU_TUBE = 'youtube';
export const YOU_TUBE_ID = '?youTubeId=';
export const ADVERTISING = 'advertising';
export const YOUTUBE_SETTINGS = 'YouTubeSettings'
export const POST_YOUTUBE_REVIEW = 'PostYouTubeReview'
export const POST_YOUTUBE_GETREVIEW = 'GetYouTubeReviews'
export const MOVIE_ADD_DADDY = 'PostMovieScore';
export const GET_USER_ADVERTS_BY_TITLE = 'GetUserAdvertsBytitle'
export const GET_USER_ADVERTS = 'GetUserAdverts'
export const MAXIMUM_RESOLUTION_THUMBNIL = 'maxresdefault.jpg';
export const YOU_TUBE_VIDEO_URL = 'https://www.youtube-nocookie.com/embed';
export const GET_EXCEPT_ONE = 'GetExceptOne';
export const GET_WATCH_RESULT = 'GetWatchResult';
export const PAUSE = 'Pause';
export const RESUME = 'Resume'
export const GetById = 'GetById';
export const CREATE = 'Create';
export const YOUTUBE_CAMPAIGN_BY_USER = 'YoutubeCampaignByUser';
export const GET_YOUTUBE_PACKS = 'GetYoutubeAdvertsPacks';
export const GET_YOUTUBE_CATEGORIES = 'YoutubeCategories';
export const GET_ADS_BY_CATEGORY = 'GetAdsByCategoryId'
export const GET_YOUTUBE_VIDEO_BY_CATEGORY = 'GetYoutubeAdvertsByCategoryId';
export const CREDIT_AMOUNT_WATCH_VIDEO = 'CreditAmountWatchVideo';
export const MOVIE_AD = 'MovieAd';
export const MOVIE_CARDS = 'GetMovieCards';
export const MOVIE_CARDS_DETAILS = 'GetMovieDetails';
export const MOVIE_CARDS_RECOMMENDATION = 'GetRecommendationMovies';
export const MOVIE_REVIEW = 'GetMovieReviews';
export const MOVIE_ADD_REVIEW = 'PostMovieReview';
export const MOVIE_TRENDING = 'GetTrendingMovies';
export const MOVIE_CARDS_CREDIT = 'MovieSettings';
export const MOVIE_CREDIT = 'CreditAmountWatchedMovie';

export const YOUTUBE_CAMPAIGN_PAUSE = 'Pause';
export const YOUTUBE_CAMPAIGN_RESUME = 'Resume';
export const GET_USER_BOOSTER_PACKS = 'GetUserBoosterPacks'
export const GET_USER_BOOSTER_PACK_ID = 'GetUserBoosterPackById'

export const REFERRALS = 'Referrals'
export const GET_REFERRAL_LEADER_BOARD = 'GetReferralLeaderBoard'
export const GET_REFERRAL_RANK =  'GetReferralRank'
export const GET_REFERRAL_RULES = 'GetReferralCompetitionRules'
export const GET_EARNERS = 'GetEarners'
export const GET_REFERRERS = 'GetReferrers'
export const COMPETITION_DETAILS = 'ReferralCompetitionHistory'
export const REF_COMP_WINNERS = 'ReferralCompetitionWinners'
export const GET_REF_DETAILS = 'GetReffererDatails'
export const TRANSFER_TO_CASHOUT = 'TransferToCashout'
export const CASHOUT = 'Cashout'

// export const API_YOU_TUBE_ADVERT_GET_ALL = `${API}/${YOU_TUBE_ADVERT}/${GET_ALL}`;
export const API_YOU_TUBE_USER_ADVERT = `${API}/${YOU_TUBE}/${GET_USER_ADVERTS}`;

export const API_YOU_ADVERT_TUBE_BY_ID = `${API}/${YOU_TUBE}/${GetById}`;
export const API_YOU_ADVERT_CREATE = `${API}/${YOU_TUBE}/${CREATE}`;
export const API_YOU_TUBE_VERSION_CAMPAIGN_BY_USER = `${API}/${VERSION1}/${YOU_TUBE}/${YOUTUBE_CAMPAIGN_BY_USER}`;

export const API_YOU_TUBE_VERSION_CAMPAIGN_PAUSE= `${API}/${VERSION1}/${YOU_TUBE}/${YOUTUBE_CAMPAIGN_PAUSE}`;

export const API_YOU_TUBE_VERSION_RESUME= `${API}/${VERSION1}/${YOU_TUBE}/${YOUTUBE_CAMPAIGN_RESUME}`;
export const API_YOU_TUBE_ADVERT_PACKS = `${API}/${VERSION1}/${YOU_TUBE}/${GET_YOUTUBE_PACKS}`;
export const API_YOU_TUBE_SAVE_CAMPAIGN = `${API}/${VERSION1}/${YOU_TUBE}/${CREATE}`;
export const API_MOVIE_ADD_DADDY = `${API}/${VERSION1}/${MOVIE_AD}/${MOVIE_ADD_DADDY}`;

export const GET_YOUTUBE_TIMER = 'GetYoutubeTimer';
export const API_YOU_TUBE_VERSION1_GET_BY_TITLE = `${API}/${VERSION1}/${YOU_TUBE}/${GET_USER_ADVERTS_BY_TITLE}`;
export const API_YOU_TUBE_VERSION1_USER_ADVERT = `${API}/${VERSION1}/${YOU_TUBE}/`;

export const API_MOVIE_USER_ADVERT = `${API}/${VERSION1}/${MOVIE_AD}/${MOVIE_CARDS}`;
export const API_MOVIE_USER_DETAILS = `${API}/${VERSION1}/${MOVIE_AD}/${MOVIE_CARDS_DETAILS}`;
export const API_MOVIE_USER_RECOMMENDATION = `${API}/${VERSION1}/${MOVIE_AD}/${MOVIE_CARDS_RECOMMENDATION}`;
export const API_MOVIE_REVIEW = `${API}/${VERSION1}/${MOVIE_AD}/${MOVIE_REVIEW}`;
export const API_MOVIE_TRENDING = `${API}/${VERSION1}/${MOVIE_AD}/${MOVIE_TRENDING}`;
export const API_MOVIE_ADD_REVIEW = `${API}/${VERSION1}/${MOVIE_AD}/${MOVIE_ADD_REVIEW}`;
export const API_MOVIE_USER_CREDIT_SETTINGS = `${API}/${VERSION1}/${MOVIE_AD}/${MOVIE_CARDS_CREDIT}`;
export const API_MOVIE_CREDIT = `${API}/${VERSION1}/${MOVIE_AD}/${MOVIE_CREDIT}`;




export const API_YOU_TUBE_VERSION1_EXCEPT_ONE = `${API}/${VERSION1}/${YOU_TUBE}/${GET_EXCEPT_ONE}`
export const API_YOU_TUBE_VERSION1_ADVERT_WATCH_RESULT = `${API}/${VERSION1}/${YOU_TUBE}/${GET_WATCH_RESULT}`;
export const API_YOU_TUBE_ADVERT_PAUSE = `${API}/${YOU_TUBE}/${PAUSE}`;
export const API_YOU_TUBE_ADVERT_RESUME = `${API}/${YOU_TUBE}/${RESUME}`;
export const API_YOU_ADVERT_VERSION1_TUBE_BY_ID = `${API}/${VERSION1}/${YOU_TUBE}`;
export const API_YOU_ADVERT_VERSION1_TUBE_BY_ID_LIKE_AND_DISLIKE = `${API}/${VERSION1}/${YOU_TUBE}/${YOUTUBE_LIKE}`;
export const API_YOU_ADVERT_VERSION1_TUBE_BY_ID_LIKE_AND_DISLIKE_GET_COUNT = `${API}/${VERSION1}/${YOU_TUBE}/${GET_YOUTUBE_LIKES}`;
export const API_YOU_TUBE_ADVERT_CATEGORIES = `${API}/${VERSION1}/${YOU_TUBE}/${GET_YOUTUBE_CATEGORIES}`;
export const API_YOU_TUBE_ADVERT_CATEGORIES_ID = `${API}/${VERSION1}/${YOU_TUBE}/${GET_ADS_BY_CATEGORY}`;
export const API_YOUTUBE_VIDEO_BY_CATEGORY = `${API}/${YOU_TUBE}/${GET_YOUTUBE_VIDEO_BY_CATEGORY}`;
export const API_YOUTUBE_VERSION1_CREDIT_AMOUNT_WATCH_VIDEO = `${API}/${VERSION1}/${YOU_TUBE}/${CREDIT_AMOUNT_WATCH_VIDEO}`;
export const API_YOUTUBE_VERSION1_GET_CREDIT_TIME = `${API}/${VERSION1}/${YOU_TUBE}/${YOUTUBE_SETTINGS}`
export const API_YOUTUBE_VERSION1_ADDREVIEW = `${API}/${VERSION1}/${YOU_TUBE}/${POST_YOUTUBE_REVIEW}`
export const API_YOUTUBE_VERSION1_GETREVIEW = `${API}/${VERSION1}/${YOU_TUBE}/${POST_YOUTUBE_GETREVIEW}${YOU_TUBE_ID}`

export const API_GET_WELCOME_MESSAGE = `${API}/${VERSION1}/${ADDONS}/${WELCOME_POPUP}`;
export const API_GET_HOW_IT_WORKS = `${API}/${VERSION1}/${ADDONS}/${GET_HOW_IT_WORKS}`;
export const API_GET_FAQ = `${API}/${VERSION1}/${ADDONS}/${GET_FAQ}`;
export const API_GET_TERMS_OF_SERVICES = `${API}/${VERSION1}/${ADDONS}/${GET_TERMS_OF_SERVICES}`;
export const API_GET_PRIVACY_POLICY = `${API}/${VERSION1}/${ADDONS}/${GET_PRIVACY_POLICY}`;
export const API_GET_ABOUT = `${API}/${VERSION1}/${ADDONS}/${GET_ABOUT}`;
export const API_GET_APPLICATION = `${API}/${VERSION1}/${ADDONS}/${GET_APPLICATION}`;
export const API_GET_LANGUAGES = `${API}/${VERSION1}/${ADDONS}/${GET_LANGUAGES}`;
export const API_GET_CUSTOM_COUNTER = `${API}/${VERSION1}/${ADDONS}/${GET_CUSTOM_COUNTER}`;
export const API_GET_LOGO = `${API}/${VERSION1}/${ADDONS}/${GET_LOGO}`;
export const API_GET_NEWS = `${API}/${VERSION1}/${ADDONS}/${'GetNews?cultureCode=en-US'}`

// New APIS
export const API_VERSION1_USER_DETAILS = `${API}/${VERSION1}/${User}/${ME}`;
export const API_VERSION1_REF_DETAILS = `${API}/${VERSION1}/${REFERRALS}/${GET_REF_DETAILS}`;
export const MY_RANK = `${API}/${VERSION1}/${User}/${GET_REFERRAL_RANK}`
export const TRANSFER_CASHOUT = `${API}/${VERSION1}/${PAYMENT}/${TRANSFER_TO_CASHOUT}`
export const CASHOUT_API = `${API}/${VERSION1}/${PAYMENT}/${CASHOUT}`

// Tutorials

export const GET_TUTORIAL_VIDEOS =`${API}/${VERSION1}/${YOU_TUBE}/${TUTORIALS}`

// booster pack

export const GET_ALL_PACKS = `${API}/${VERSION1}/${MEMBERSHIPS}/${GET_USER_BOOSTER_PACKS}`

export const GET_BY_ID_PACKS = `${API}/${VERSION1}/${MEMBERSHIPS}/${GET_USER_BOOSTER_PACK_ID}`

// competition api

export const GET_ALL_COMPETITIONS = `${API}/${VERSION1}/${REFERRALS}/${GET_REFERRAL_LEADER_BOARD}`
export const GET_ALL_COMPETITIONS_RULES = `${API}/${VERSION1}/${REFERRALS}/${GET_REFERRAL_RULES}`
export const GET_COMPETITION_DETAILS = `${API}/${VERSION1}/${REFERRALS}/${COMPETITION_DETAILS}`
export const GET_COMPETITION_WINNERS = `${API}/${VERSION1}/${REFERRALS}/${REF_COMP_WINNERS}`

// leaderboard api

export const EARNERS_LAST_SEVEN_DAYS = `${API}/${VERSION1}/${REFERRALS}/${GET_EARNERS}?days=7` 

export const EARNERS_LAST_ONE_DAYS = `${API}/${VERSION1}/${REFERRALS}/${GET_EARNERS}?days=1` 

export const REFERRERS_LAST_SEVEN_DAYS = `${API}/${VERSION1}/${REFERRALS}/${GET_REFERRERS}?days=7`

export const REFERRERS_LAST_ONE_DAYS = `${API}/${VERSION1}/${REFERRALS}/${GET_REFERRERS}?days=1`