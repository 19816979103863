// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironmentConfig } from '@interfaces/environment-interface';
import {
  IP_CURRENCIES_NEW_PATH,
  IP_CURRENCIES_PATH,
  RADIO_STREAMING_DEV_PATH,
  SOUND_MATE_DEV_PATH,
  YOU_TUBE_THUMBNAIL_PATH
} from '@constants/environyment-pathes.const';

export const environment: IEnvironmentConfig = {
  production: false,
  baseSoundMateUrl: SOUND_MATE_DEV_PATH,
  baseRadioStreamingUrl: RADIO_STREAMING_DEV_PATH,
  baseYouTubeThumbnailUrl: YOU_TUBE_THUMBNAIL_PATH,
  baseIPCurrencyUrl: IP_CURRENCIES_PATH,
  baseIPNewCurrencyUrl: IP_CURRENCIES_NEW_PATH
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
