import { ComponentType } from '@angular/cdk/portal';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { IPortalOptions } from './portal.interface';

@Injectable({
  providedIn: 'root'
})
export class SkdPortalService implements OnDestroy {
  private optionsMap = new Map<string, IPortalOptions>();
  private visibleSubjectsMap = new Map<string, Subject<boolean>>();

  public ngOnDestroy(): void {
    this.optionsMap.clear();
    this.visibleSubjectsMap.clear();
  }

  public setComponent<T>(component: ComponentType<T>, portalName: string): void {
    const visibleSubject$ = new BehaviorSubject(false);
    const visible$ = visibleSubject$.asObservable();

    this.visibleSubjectsMap.set(portalName, visibleSubject$);
    this.optionsMap.set(portalName, {
      component,
      visible$,
    });
  }

  public getOptions(portalName: string): IPortalOptions {
    return this.optionsMap.get(portalName);
  }

  public show(portalName: string): void {
    const visible$ = this.visibleSubjectsMap.get(portalName);
    visible$.next(true);
  }

  public hide(portalName: string): void {
    const visible$ = this.visibleSubjectsMap.get(portalName);
    visible$.next(false);
  }
}
