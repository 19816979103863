import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  API_LOGIN_V1,
  API_LOGIN_V1_PASSWORD,
  API_USER_SIGNUP_V1, BASE_SOUND_MATE_URL,
  CREATE,
  GET_LOGIN_FORM, LOGOUT, USER
} from '@constants/request.const';
import {
  IGetNewPasswordOptions, ILoginForm, ILoginUser,
  IPreLoginResponse,
  IResetPasswordFormSettings, ISetNewPasswordOptions, ISetPasswordFormSettings, ISignUpFormOptions
} from '@interfaces/auth.interface';
import { SocialUser } from 'angularx-social-login';
import { Observable } from 'rxjs';
import { CurrencyService } from './currency.service';
import { LocalStorageService } from './local-storage.service';
import { UrlsService } from './urls.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private readonly urls: UrlsService,
    private readonly http: HttpClient,
    private currencyService: CurrencyService,
    private readonly storageService: LocalStorageService,
  ) { }

  public register(form){
    return this.http.post(`${BASE_SOUND_MATE_URL}${API_USER_SIGNUP_V1}`, form,{responseType:'text'});
  }

  public login(user: ILoginUser): Observable<IPreLoginResponse> {
    return this.http.post<IPreLoginResponse>(`${BASE_SOUND_MATE_URL}${API_LOGIN_V1_PASSWORD}`, user);
  }

  public refresh(refreshToken: string): Observable<IPreLoginResponse> {
    return this.http.post<IPreLoginResponse>(this.urls.SOUND_MATE.API_REFRESH.refresh, {
      refreshToken,
    });
  }

  public afterSocialSignUp(user: SocialUser) {
    const currency = this.currencyService.getCurrentCurrency();
    const signInUser = {
      username: user.name,
      [`${user.provider.slice(0).toLowerCase()}Id`]: user.id,
      email: user.email,
      countryCode: 'IN',
      birthDate: '',
      gender: 0,
      registeredWithIP: this.storageService.getLocalStorageItem('IP'),
      currency: currency?.code
    };

    return this.http.post(`${BASE_SOUND_MATE_URL}${API_USER_SIGNUP_V1}/${CREATE}${user.provider.charAt(0) + user.provider.slice(1).toLowerCase()}${USER}`, signInUser, { responseType: 'text' });
  }

  public socialLogin(id: string, social: string): Observable<IPreLoginResponse> {
    const loginUser = {
      [`${social.toLowerCase()}Id`]: id,
      loginIP: this.storageService.getLocalStorageItem('IP')
    }
    return this.http.post<IPreLoginResponse>(`${BASE_SOUND_MATE_URL}${API_LOGIN_V1}/${social.charAt(0) + social.slice(1).toLowerCase()}login`, loginUser);
  }

  public signOut(refreshToken: string): Observable<void> {
    return this.http.post<void>(`${BASE_SOUND_MATE_URL}${API_USER_SIGNUP_V1}/${LOGOUT}`, {
      refreshToken,
    });
  }

  public getLoginForm(): Observable<ILoginForm> {
    return this.http.get<ILoginForm>(`${BASE_SOUND_MATE_URL}${API_LOGIN_V1}/${GET_LOGIN_FORM}`);
  }

  public getSignUpForm(): Observable<ISignUpFormOptions> {
    return this.http.get<ISignUpFormOptions>(this.urls.SOUND_MATE.API_SIGN_UP_V1.GET_SIGN_UP_FORM);
  }

  public getResetPasswordForm(): Observable<IResetPasswordFormSettings> {
    return this.http.get<IResetPasswordFormSettings>(this.urls.SOUND_MATE.API_SIGN_IN.GET_RESET_PASSWORD_FORM);
  }

  public getBlackedList(): Observable<any> {
    return this.http.get<any>(this.urls.SOUND_MATE.API_SIGN_IN.blackList);
  }

  public getPasswordSetForm(): Observable<ISetPasswordFormSettings> {
    return this.http.get<ISetPasswordFormSettings>(this.urls.SOUND_MATE.API_SIGN_IN.GET_NEW_PASSWORD_FORM);
  }

  public sendResetPasswordLink(email: string): Observable<void> {
    const params = new HttpParams()
      .set('email', email);

    return this.http.post<void>(this.urls.SOUND_MATE.API_SIGN_IN.SEND_REST_PASSWORD_LINK, null, { params });
  }

  public sendNewPasswordOnEmail(options: IGetNewPasswordOptions): Observable<void> {
    return this.http.post<void>(this.urls.SOUND_MATE.API_SIGN_IN.GET_NEW_PASS_ON_EMAIL, options);
  }

  public setNewPassword(options: ISetNewPasswordOptions) {
    return this.http.post(this.urls.SOUND_MATE.API_SIGN_IN.SET_NEW_PASSWORD, options,{responseType:'text'});
  }

  // GET /api/Login/Google2FAAuthenticationURLforQR
  public getG2FAQR(): Observable<string> {
    return this.http.get(this.urls.SOUND_MATE.API_G2FA.GET_G2FA_QR_CODE, {responseType: 'text'})
  }

  public getG2FAVerificationQR(passcode: string , data?): Observable<string> {
    const headers = new HttpHeaders().set("Authorization", "Bearer " + data);
    // return this.http.get<string>(this.urls.SOUND_MATE.API_G2FA.GET_G2FA_VERIFICATION_QR + `?passcode=${passcode}`);
    return this.http.get<string>(this.urls.SOUND_MATE.API_G2FA.GET_G2FA_VERIFICATION_QR_NEW + `?token=${passcode}`,{headers});

  }
  applicationCount(){
    return this.http.get(`${this.urls.SOUND_MATE.API_SIGN_IN.base}/${'UpdateAndroidDownloadCount'}`)
  }
}
