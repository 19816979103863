export const LOADING_NAMES = {
  MAIN: 'MAIN',
  USER_PROFILE: {
    ACTIVITY_LOGS: 'ACTIVITY_LOGS',
    USER_SETTINGS: 'USER_SETTINGS',
  },
  FINANCES: {
    EXTRA_ACTIONS: 'EXTRA_ACTIONS',
    DEPOSIT_SEND_WITHDRAW: 'DEPOSIT_SEND_WITHDRAW',
    FINANCES_HISTORY: 'FINANCES_HISTORY'
  },
  DASHBOARD: {
    ACTIVITIES: 'BALANCES_AND_ACTIVITIES',
  },
  REFERRALS: {
    TABS: 'REFERRALS_TABS',
  },
  REPRESENTATIVES: {
    TABS: 'REPRESENTATIVES_TABS',
  },
  RADIO: {
    ALL_GENRES: 'ALL_GENRES',
    TRENDING_RADIO: 'TRENDING_RADIO',
    CHOICE_FAVORITE_RADIO_GENRES: 'CHOICE_FAVORITE_RADIO_GENRES'
  },
  YOUTUBE: {
    ADVERT_LIST: 'ADVERT_LIST',
    PLAY_AVAILABLE_VIDEO: 'PLAY_AVAILABLE_VIDEO',
    CAMPAIGN: 'CAMPAIGN'
  },
  PAYMENT: {
    RECENT_PAYMENT: 'RECENT_PAYMENT'
  },
  STATISTICS: {
    STATISTICS: 'STATISTICS_TABS'
  }
};
