import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxCaptchaModule } from 'ngx-captcha';
import { TranslationsService } from '@services/translations.service';
import {
  SocialLoginModule
} from 'angularx-social-login';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconService } from '@services/icon.service';
import { AccessTokenInterceptor } from './core/interceptors/access-token.interceptor';
import { RefreshInterceptor } from './core/interceptors/refresh.interceptor';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { ToasterService } from '@services/toaster.service';
import { MomentService } from '@services/moment.service';
import { socialConfigFactory } from '@utils/auth.util';
import { AuthService } from '@services/auth.service';
import { SkdPortalModule } from '@kit/portal/portal.module';
import { LoadingModule } from '@kit/loading/loading.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MyProfileService } from '@services/my-profile.service';
import { LoadingSpinnerModule } from '@kit/loading-spinner/loadingspinner.module';
import { DynamicTranslationModule } from '@pages/welcome/dynamic_trans_service/dynamic-translation.module';

export class WebpackTranslateLoader implements TranslateLoader {
  public getTranslation(lang: string): Observable<any> {
    return from(import(`../assets/i18n/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SocialLoginModule,
    MatMenuModule,
    MatSelectModule,
    NgxCaptchaModule,
    SkdPortalModule,
    LoadingModule,
    LoadingSpinnerModule,
    MatDialogModule,
    DynamicTranslationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
      defaultLanguage: 'en'
    }),
    ToastrModule.forRoot({
      toastClass: 'skd-toast',
    })
  ],
  providers: [
    TranslationsService,
    IconService,
    ToasterService,
    MyProfileService,
    MomentService,
    Meta,
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: socialConfigFactory,
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AccessTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(iconService: IconService) {
    iconService.initSvgIcons();
  }
}
