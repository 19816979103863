import { PAYMENT } from "@constants/request.const";
import { MOVIE, VOUCHER } from "@constants/routes.const";

export default {
  DATE_EXPIRE:'Your account will expire in {{value}} Days.Ensure you',
  DATE_EXPIRE_MSG:'your account on time to avoid losing your earnings.',
  DATE_EXPIRE_BTN:'Upgrade',
  WARNING_MSG:'WARNING Your Account has expired & cannot earn.',
  WARNING_MSG1:'to any of the paid plans to continue earning.',
  WARNING_MSG_BTN:'Upgrade Now',
  BOOSTER_ACCOUNT:{
    WARNING_MSG:'Boosted account should stop crediting. Please',
    DATE_EXPIRE_BTN:'Upgrade',
    WARNING_MSG1:'master account to continue earning.',
    BOOSTER_ACCOUNT_FEATURED:'A booster account is an advanced feature that allows users to maximize their earnings by tapping into a collective pool of referral income. This income is generated from users without an upline, or direct referrer, and is redistributed among booster account holders.',
    BOOSTER_ACCOUNT_FEATURED1:'Earn upto 670$ weekly with our booster accounts.',
    BOOSTER_PLAN_START_EARNING:'1. Click on the Booster plans to Buy a booster account today & start earning',
    BOOSTER_PLAN_START_EARNING1:'every hour.',
    BOOSTER_ACCOUNT_WORKS:'2. To Understand How Booster Accounts Work',
    CLICK_HERE:'CLICK HERE'

  },
  QUICK_BUTTONS:{
    START_EARNING: 'Start Earning',
    // COMPARE_PLANS: 'Compare Plans',
    UPGRADE_BTN:'Upgrade',
    DEPOSIT: 'Deposit',
    DEPOSIT_BY: 'Deposit By Agent',
    WITHDRAW: 'Withdraw'
  },
  COMMON: {
    BUTTONS: {
      CPAY:'Cpay',
      OKAY: 'Okay',
      CANCEL: 'Cancel',
      CONFIRM: 'Confirm',
      SAVE: 'Save',
      COPY: 'Copy',
      SUBMIT: 'Submit',
      REMOVE: 'Delete {{value}}',
      CONTINUE: 'Continue',
      SEND: 'Send',
      BUY: 'Buy',
      VERIFY: 'Verify',
      UPGRADE_ACCOUNT:'Upgrade Account',
      FORGOT_PIN:'Forgot Pin',
      TOPUP_SPENDING_WALLET: 'Top Up Spending Wallet'
    },
    LABELS: {
      ENTER_PIN: 'Enter PIN to confirm'
    },
    VALIDATION_MESSAGES: {
      MATCH_PASSWORD: 'Passwords should be the same',
      MATCH_PIN: 'Pins should be the same',
      MORE_THAN: 'Field length should be more than {{value}}',
      LESS_THAN: 'Field length should be less than {{value}}',
      REQUIRED: 'Please fill in the required field',
      EMAIL_VALID: 'Please give a valid Email Address'
    }
  },
  VALIDATION_MESSAGES: {
    REQUIRED: 'Please fill in the required field'
  },
  WELCOME: {
    LOGIN: 'Login',
    SIGN_UP: 'Sign-Up',
    LOGIN_PAGE: {
      HAVE_ACCOUNT: 'Don’t have an account?',
      FORGOT_PASSWORD: 'Reset Password/Pin'
    },
    SIGN_UP_PAGE: {
      TITLE: 'Create Account',
      USERNAME: 'Username',
      USERNAME_OR_EMAIL: 'Username or Email',
      USERNAME_PLACEHOLDER: 'Enter Username',
      FIRST_NAME_LABEL: 'First Name',
      FIRST_NAME_PLACEHOLDER: 'Enter First Name',
      LAST_NAME_LABEL: 'Last Name',
      LAST_NAME_PLACEHOLDER: 'Enter Last Name',
      USERNAME_OR_EMAIL_PLACEHOLDER: 'Enter Username or Email',
      EMAIL: 'Email',
      EMAIL_PLACEHOLDER: 'Enter Email',
      PASSWORD: 'Password',
      PASSWORD_PLACEHOLDER: 'Enter Password',
      CONFIRM_PASSWORD: 'Confirm Password',
      PIN: 'PIN',
      PIN_PLACEHOLDER: 'Enter A 4 digit pin',
      PIN_TEXT:'Enter a 4 digit Secret Pin for your account',
      PHONE: 'Phone number',
      REFERRER: 'Referrer (Optional)',
      REFERRER_TEXT: 'Username of the person who referred you. Left blank if no referrer',
      REFERRER_PLACEHOLDER: 'None',
      TERMS: {
        TERMS_TEXT: 'By creating an account, I agree to the',
        TERMS: 'Terms of Service',
        AND: 'and',
        PRIVACY: 'Privacy Notice'
      },
      SUBSCRIBE: 'I want to subscribe to the ',
      SUBSCRIBE_TEXT:'newsletter',
      CONTINUE: 'or continue with',
      ACCOUNT: 'Already have an account?',
      CREATE_ACCOUNT: 'Create Account',
    },
    LANDING_PAGE: {
      BUY_VOUCHER_CODE:'Buy Voucher Code',
      BUY_VOUCHER_TEXT:'Deposit Through Agent',
      BUY_ACCOUNT:'Buy Account',
      HOW_IT_WORKS: 'How it works',
      DASHBOARD:'Dashboard',
      HOME:'Home',
      ABOUT:'About',
      FAQ: 'FAQS',
      INCOME_CALCULATOR: 'Income Calculator',
      BIRD_ACCESS: 'Get Early Access',
      DONT_GET_BORED: " Don't Get Bored  ",
      GET_PAID: 'Get Paid!',
      MOVIEDADDY_TEXT: "At Crizal we aim to be one of the world's most trusted recommendation resource for quality entertainment. So, Stop Dreaming & Cash rewards while you find your favourite movies and Tvshows with Crizal",
      WATCH:' Watch',
      REWARD:' Reward',
      WITHDRAW: 'Withdraw',
      PLATFORM_TEXT:'Our platform comes packed with options to make you money while you get entertained in 3 Simple Steps',
      WATCH_TEXT: 'With over 20,000+ movie trailers, movie reviews, video ads and much more. Our inventory refreshes on a daily basis',
      REWARD_TEXT: 'At Crizal your opinion counts & Pays whether you are a Movie critic or A Creative Analyst',
      WITHDRAW_TEXT: 'Get paid how you want, when you want. Turn those cash rewards into cash and withdraw directly to Paypal, Crypto or any of our supported wallets',
      FEATURES_CONTENT:{
        AMAZING_FEATURES: 'Our Amazing Features',
        AMAZING_FEATURES_CONTENT: " If you are an entertainment fan looking for a recommendation or looking to share a recommendation, you've come to the right place. In addition to that, at Crizal your opinion Pays",
        WHAT_NEW: "What's New",
        WHAT_NEW_TEXT: "Our content refreshes on a daily basis. You are kept up to date with what's new on the most popular streaming platforms, in theatres & at home",
        WHAT_HOT: "What's Hot",
        WHAT_HOT_TEXT: " With 20,000+ Movie trailers available from Classics to Latest, we show you where you can legally watch Movies & Tv Shows that you love + You Reward $$$ Along The Way",
        TRAILER_CAMPAIGNS: 'Trailer Campaigns',
        TRAILER_CAMPAIGNS_TEXT: "For our partners, Our AI system collects millions of profiles of Movie fans and learn their individual taste. We then run highly efficient Trailer campaigns for Studios, Distributors and VOD(Video-On-Demand) services",
        FAST_PAYMENTS: 'Fast Payments',
        FAST_PAYMENTS_TEXT: " We provide fast and secure payments ensuring orders & withdrawals are seamless and fully Automated",
        TRANSPARENCY: 'Transparency',
        TRANSPARENCY_TEXT: "Track all your Rewards, Ad-spend, transfers, Withdrawals in a simple secure dashboard",
        LIVE_CHAT: 'Live Chat',
        LIVE_CHAT_TEXT: "Our team is available 24/7 to answer all your questions and provide support. Talk To Us!",
        WHY_CHOOSE: 'Why Choose Crizal?',
        WHY_CHOOSE_TEXT: "We show you what you love watching & Allow you to make some extra cash from it. start earning cash rewards now!",
        MOVIE_TRAILER: 'Movie Trailers & Shows',
        COUNTRIES: 'Countries',
        STAFF: 'Staff',
        WORK: 'How We Work!',
        WORK_TEXT: " We offer Next generation movie marketing for our clients & partners",
        HOW_WE_WORK:{
          INTELLIGENCE: 'Artificial Intelligence',
          INTELLIGENCE_TEXT: "Our Crizal Robots collect millions of profiles of Movie fans and learn their individual taste",
          TRAILER_CAMPAIGNS: 'Trailer Campaigns',
          TRAILER_COMPAIGNS_TEXT: "Crizal then uses this data to run highly efficient trailer campaigns for Distributors, Studios & Video On Demand Services. We DO NOT sell/transfer our user data, we run the campaigns (DSP)",
          TARGET_CAMPAIGNS: 'Re-Targeting Campaigns',
          TARGET_CAMPAIGNS_TEXT: "We also Re-target people on Youtube, Facebook, Instagram,Tiktok and other platforms where they spend time discovering new content",
          REVENUE_SHARE: 'Revenue Share',
          REVENUE_SHARE_TEXT: "Our Fans get Rewarded on Revenue share the more they engage with our platform",
          MOVIEDADDY: 'Crizal: Next Generation Movie Marketing',
          MOVIEDADDY_TEXT: 'Sign Up For Free, find the latest Movies & Shows and Reward with Crizal',
          RIGHTS: 'All Rights Reserved',
        }
      },
      PAYMENT_PROOFS: {
        USERNAME: 'UserName',
        TITLE: 'Payment proofs',
        RECENT_PAYOUTS: 'Recent Payouts',
        DATE: 'Date',
        COUNTRY: 'Country',
        TYPE: 'Type',
        AMOUNT: 'Amount',
        ACCOUNT: 'Account',
      },
      SIGN_UP: 'Sign up',
      LOGIN: 'Login',
      RADIO: {
        LISTEN_RADIO: 'Listen to the radio & earn',
        ABOUT_SCADA: 'Scada is a revolutionary radio curation service that rewards your opinion on the music. Simply sign up and start earning',
        TRY_FOR_FREE: 'Try for free',
        BEST_RADIO: 'Best radio stations',
        INSTANT_REWARD: 'Instant reward',
        ACCURACY: 'Accuracy',
        TRIPGRAM: 'Tripgram Allows you to track your Ratings & Earnings with our inbuilt statistics dashboard.'
      },
      PEOPLE: 'People always asking',
      CUSTOMER: {
        CUSTOMERS_THINK: 'What our customers think of us'
      },
      MORE_QUESTIONS: 'Have more questions or propositions?',
      CONTACT_US: 'Contact Us',
      FOOTER: {
        SUPPORT: 'Support',
        SECURITY: 'Security',
        ABOUT: 'About',
        FAQ: 'FAQS',
        HOW_IT_WORKS: 'How it works?',
        PRIVACY_POLICY: 'Privacy Policy',
        TERMS_SERVICE: 'Terms of Service',
        PAYMENT_PROOFS: 'Payment Proofs',
        WE_SOCIAL: 'We are Social',
      }
    }
  },
  HEADER: {
    BOOST_EARNINGS: 'Boost Earning Rate',
    HOME:'Home',
    SUPPORT:'Support',
    NOTIFICATIONS:'Notifications',
    LANGUAGES: {
      EN: 'English',
      RU: 'Russian'
    },
    MENU: {
      PROFILE: 'My Profile',
      LOGS: 'Activity Logs',
      SETTINGS: 'Settings',
      LOG_OUT: 'Log Out',
    },
    EXPIRES: 'Expires',
    UPGRADE_PLAN: 'Upgrade plan',
  },
  FOOTER:{
    EARNING_GUIDE: 'Earning Guide',
    NEWS: 'News',
    HOW_IT_WORKS: 'How it Works?',
    TERMS_OF_SERVICES: 'Terms of Services',
    PRIVACY_POLICY: 'Privacy Policy',
    PAYMENT_PROOFS: 'Payment Proofs',
    FAQS: 'FAQs',
    SUPPORT:'Support'
  },
  SIDE_NAV: {
    SUMMARY: 'Dashboard',
    SUBSCRIPTION_PLANS: {
      HEADER: 'Plans',
      PRICING: 'Pricing Plans',
      INCOME_CALCULATOR: 'Income Calculator'
    },
    RATE_EARN: {
      HEADER: 'Earn',
      RADIO: 'Radio',
      YOUTUBE: 'Youtube',
      MOVIE:'Movie',
      TUTORIAL: 'Tutorials',
      UNLOCKED_VIDEO_LOGS: 'Unlocked Video Logs',
    },
    MY_TEAM: {
      HEADER: 'Referrals',
      DIRECT: 'My Direct',
      PROMO: 'Promo Materials',
      CHART:'Chart',
      CAL:'Ref calculator',
      COMPETITION:'Competition'
    },
    MY_MONEY: {
      HEADER: 'Money',
      DEPOSIT: 'Deposit',
      TRANSFER: 'Transfer',
      WITHDRAW: 'Withdraw',
      VOUCHER: 'Voucher',
      EXTRA_MINUTES: 'Extra Minutes',
      LOGS: 'LOGS',
      PAYMENT_PROOFS:'Payment Proofs',
    },
    LEADERS: {
      HEADER: 'Airtime Distributors',
      COUNTRY: 'Approved Country Agents'
    },
    STATISTICS: {
      HEADER: 'My Reports',
      MY_MONEY: 'My Money',
      STATISTIC:'STATISTICS'
    },
    SETTINGS:{
      SETTINGS: 'Settings',
      MyProfile : 'My Profile',
      ACTIVITYLOGS: 'Activity Logs',

    },
    LEADERBOARD :{
      Top_10_Today_Earners : 'Top 10 Today Earners',
      Top_10_Last_7_Days_Earners : 'Top 10 Last 7 Days Earners',
      Top_10_Referrers_Today : 'Top 10 Referrers Today',
      Top_10_Last_7_Days_Referrers : 'Top 10 Last 7 Days Referrers',
    }
  },
  MAIN: {
    TABS: {
      RADIO: 'Radio',
      YOUTUBE: 'Tv shows',
      MOVIE: 'Movies',
    },
    RADIO_STREAM: {
      TITLE: 'All Genres',
      LOAD_MORE: 'Load More',
      FAVORITES: 'Favorites',
      FOUND: 'Stations found',
      SEARCH: 'Search...',
      SHOW_ALL: 'Show All',
      RECENTLY_LISTEN_RADIO_STATIONS: 'Recently Played',
      INFO_PANEL: {
        EARNING_RATE: 'Earning Rate',
        TOTAL_PAID_TIME: 'Today Paid Time',
        SEARCH: 'Search',
        MINUTE: 'min',
        POINT: 'pts',
      },
      GUIDE: {
        TITLE_ONE: 'Earn while listening',
        TITLE_TWO: 'Boost your earning rate',
        TITLE_THREE: 'Cash out',
        TEXT_ONE: 'You get money for every minute of music you play. There more you listen, the more you earn',
        TEXT_TWO: 'Watch videos, buy extra minutes, do offers, and more to increase your music earning rate and earn thousands of bonus points',
        TEXT_THREE: 'Redeem your points directly through Paypal, gift cards, and popular products',
        BUTTON_TEXT: 'Get Started',
      },
      GENRES_CHOICE: {
        TITLE: 'Available Radio Genres',
        TEXT: 'Please select from 3 to 10 genres in order to create your personal recommendations',
        BUTTON_TEXT: 'Continue',
      },
      TRENDING_RADIO: {
        TITLE: 'Trending Radio Stations',
      },
      MODALS: {
        GREAT: 'Great',
        GREAT_TEXT: 'You’ve listened enough to start earning money',
        START_EARNING: 'Start Earning',
        STOP_EARNING_TITLE: 'You’ve reached max paid minutes for today',
        STOP_EARNING_TEXT: 'You can boost the earning rate. Please select one of the options below.',
        BUY_EXTRA_MINUTES: 'Buy extra minutes',
        UPGRADE_PLAN: 'Upgrade your membership plan',
        WATCH_VIDEO: 'Watch a video',
        LISTENING_WITHOUT_EARNING: 'Listen Without Earning',
      }
    },
    YOU_TUBE: {
      PAID_CAT:{
        MSG1:"Currently we don't have any paid tasks for you in this category. These tasks are normally Geo-located and can only be completed by users in certain countries as per the task publisher requirements.",
        MSG2:"If you have an upgraded account, we will send you a notification when we have this type of tasks available for you."
      },
      GUIDE: {
        TITLE: "Don't Get Bored, Get Paid & Find Your Favorite Tvshows",
        TEXT: 'You have to watch at least 1 minute of video, to get earnings.',
        BUTTON_TEXT: 'Get Started',
      },
      SEARCH: 'Search...',
      ADVERT_LIST: {
        TOTAL_ALREADY_UNLOCK:'Unlocked On Registration + Unlocked on Upgrade + Unlocked By Referrals',
        CASHOUT_ALL_VIDEOS: 'Instead of having to watch all your unlocked videos you can convert them to cash. This will be credited to your Earnings wallet instantly and you can withdraw. Then refer more friends and unlock more videos.',
        TOTAL_TO_BE_UNLOCKED:'<b>Total Available Per Year - Already Unlocked.</b> You have 1 year to unlock them so no worries. Take your time. ',
        DAILY_QUOTA_TEXT: 'You can increase your daily quota by upgrading to higher account or by referring more friends"',
        PERSONAL_TASK_TEXT:'Personal tasks are the tasks unlocked by you when you upgrade to any of the paid plans',
        TEAM_TASK_TEXT:'Team Tasks are the videos that are unlocked when your referral upgrades. If you dont have any Team Videos allocated, Dont worry, you will still earn from referral upgrade commissions.',
        AVAILABLE_VIDEOS: 'Available Videos',
        PLAY_AND_EARN:'Play and Earn',
        // START_EARNING_TEXT: 'You can start earning in ',
        START_EARNING_TEXT: 'This video will earn you cash in',
        CAMPAIGNS: 'Campaigns',
        REFER_FRIEND_TITLE:'Refer A Friend to Unlock More Videos and Complete Your Total Allocated Videos Sooner!',
        UNDESTANDING_TOTAL_VIDEO: '1. <b>Understanding Total Videos</b>: This refers to the total number of videos remaining to be unlocked in your account.',
        UNLOCK_VIDEOS:'2. <b>Unlocking Videos</b>: Each referral who upgrades to any of our paid plans will unlock more videos for you, the number of videos they unlock for you depends on the subscription plan your referral purchases.',
        SUBS_PERIOD:'3. <b>Subscription Period</b>: You have 1 year to unlock all the videos during your subscription period. However, you can complete unlocking them in a shorter time depending on how many friends you refer to unlock the videos for you.',
        MAX_EARNING:'4. <b>Maximize Earnings</b>: You will achieve the total potential earnings of your subscription plan after watching all the videos in your plan.',
        NO_VIDEOS_AVAILABLE: 'No videos available.',
        COMMENTS: 'Comments',
        TITLE:'Title',
        RATINGS:'Ratings',
        DESCRIPTION: 'Description',
        SUBMIT: 'SUBMIT',
        CONFORM_MESSAGE: 'Amount has been credited to the main balance.'
      },
      PLAY_VIDEO: {
        RELATED_VIDEOS: 'Related Videos'
      },
      CAMPAIGNS: {
        CREATE_CAMPAIGN: 'Create New Campaign',
        EDIT_CAMPAIGN: 'Edit Campaign',
        BACK_CAMPAIGN: 'Back to Campaign',
        TITLE: 'Title',
        VIDEO_TITLE: 'Video Title',
        URL: 'URL',
        VIDEO_URL: 'Video URL',
        VIDEO_CATEGORY: 'Video Category',
        DESCRIPTION: 'Description',
        PACK: 'Pack',
        CREATE_CAMPAIGN_BUTTON: 'Create Campaign',
        RECOMMENDED: 'Recommended',
        CANCEL: 'Cancel',
        SAVE_RETUEN: 'Save & Return',
        BACK_AVAILABLE_VIDEOS: 'Back to available videos',
        START_EARNING_TEXT: 'You can start earning in',
        NEW_CAMPAIGN: ' New Campaign',
        ACTIVE: 'Active',
        PAUSE: 'Pause',
      }
    },
    MOVIE: {
      GUIDE: {
        TITLE: "Don't Get Bored, Get Paid & Find Your Favorite Movies",
        TEXT: 'You have to watch at least 1 minute of video, to get earnings.',
        BUTTON_TEXT: 'Get Started',
      },
      SEARCH: 'Search...',
      ADVERT_LIST: {
        POPULAR_MOVIES: 'Popular Movies',
        NOW_PLAYING: 'Now Playing',
        UPCOMING: 'UpComing',
        TOP_RATED: 'Top Rated',
        LOAD_MORE: 'Load More...',
        AVAILABLE_VIDEOS: 'Available Videos',
        START_EARNING_TEXT: 'You can start earning in ',
        NO_VIDEOS_AVAILABLE: 'No videos available.',
        UNLOCK_MORE_VIDEO:'Ooops! You Need To Unlock More Videos!',
        REFERRAL_REQUIRED_TO_UNLOCK_VIDEOS: 'You have reached your AutoAllocated Ready-To-Watch videos limit. You need to refer 1 or more people to unlock more paid videos.',
        REFERRAL_BENEFITS: 'Each person you refer with an upgraded account will:',
        REFERRAL_VIDEO_UNLOCK_RANGE: '1. Unlock 10 to 60 more paid videos for you to watch & earn depending on the membership plan your referral has bought.',
        REFERRAL_DAILY_LIMIT_INCREASE: '2. Increase your Daily limit on the number of Paid videos you can watch each day.',
        INVITE_FRIENDS_PROMOTION: 'Invite your Friends Today To Maximize Your Earnings',
        REACHED_DAILY_LIMIT: 'You Have Reached Your Daily Limit',
        GET_MORE_VIDEOS: '1. You can Come back again Tomorrow to get more paid videos.',
        UPGRADE_UNLOCK_VIDEOS: '2. If you are on a Free plan, You can Upgrade your account to any of our Paid plans to Unlock more paid videos.',
        INVITE_FRIENDS_INCREASE_VIDEOS: '3. If you are on a Paid plan you can Refer A Friend to Unlock for you more videos and increase the number of videos you can watch every day.',
        // UPGRADE_PLAN_MORE_VIDEOS: '4. If you are on any of the Paid plans, you can stil upgrade to a higher plan to increase your Daily video limit & get more Paid videos.'
      },
      PLAY_VIDEO: {
        RELATED_VIDEOS: 'Related Videos',
        USER_SCORE: 'User Score',
        PLAY_TRAILER: 'Play Trailer',
        YOU_CAN_EARN: 'Funds Will Be Credited In',
        // YOU_CAN_EARN: 'You Can Start Earning In'
        OVER_VIEW: 'Overview',
        FULL_CAST: 'Full Cast & Crew',
        RECOMMENDED_MOVIIES: 'Recommended Movies',
        LOAD_MORE: 'Load More...',
        SOCIAL: 'Social',
        REVIEWS: 'Reviews',
        REVIEW: 'Review',
        TITLE: 'Title',
        RATINGS: 'Ratings',
        DESCRIPTION: 'Description',
        SUBMIT: 'SUBMIT',
        SCEONDS: 'Seconds',
        AMOUNT_CREDIT_SUCCESS_MESSAGE: 'Amount Credited Sucessfully'
      },
    },
    UNLOCKED_LOGS: {
      TITLE: 'Unlocked Video Logs',
      DATE:'Date',
      LOGS_DETAILS: 'Details',
      COUNT: 'Unlocked Count',
      TOTAL_TEXT: 'Total Unlocked'
    }
  },
  USER_SETTINGS: {
    TITLE: 'Settings',
    DAYS_EXPIRES_DATE:'Your Account expires in {{days}} days. On {{date}}.',
    DAYS_EXPIRES_TEXT:'Your Account has been Expired on {{date}}.',
    PROFILE_SUBS_TEXT:'Subscription Valid For {{days}} Days(1year) From Date Of Purchase.',
    PROFILE_SUBS_STUDENT_TEXT:'Subscription Valid For {{days}} Days From Date Of Purchase.',
    SUCCESS_SAVE_DEFAULT_MESSAGE: 'Your changes have been successfully saved.',
    FAILED_SAVE_DEFAULT_MESSAGE: 'Unfortunately, your changes have not been saved. Please try again.',
    TABS: {
      USER_INFO: 'User Info',
      PASSWORD: 'Password Settings',
      PIN: 'Pin Settings',
      TWO_FA: '2FA',
      PREFERENCES: 'Preferences Settings',
      PAYMENT_ADDRESSES: 'Payment Settings',
      MY_RANK :'My Rank',
    },
    USER_INFO_TAB: {
      NAME: 'Name',
      FIRST_NAME: 'First Name',
      LAST_NAME: 'Last Name',
      EMAIL: 'Email',
      PHONE_NUMBER: 'Phone Number',
      MODALS: {
        BYE_TITLE: 'We’re sorry to see you go',
        BYE_DESCRIPTION: 'Please notice that you have {{money}} and {{point}} points. Once you remove your account you won’t be able to send, deposit or withdraw your funds.',
        BYE_DESCRIPTION_TEXT: ' “Please notice that all your funds will be lost once you delete your account and you won’t be able to send, deposit or withdraw your funds. We cannot recover a deleted account”',
        CONFIRM_TITLE: 'Confirm Removal',
        CONFIRM_DESCRIPTION: 'Enter the code we’ve sent your on your email. Code will be active for 3 minutes. The removal action can’t be undone!',
        CONFIRM_BUTTON: 'Confirm and Remove',
      }
    },
    PREFERENCES_TAB: {
      PROFILE_VISIBILITY: 'Profile Visibility',
      PUBLIC_LABEL: 'Public (visible to everyone)',
      PRIVATE_LABEL: 'Private (visible to me only)',
      COMMUNICATION_LABEL: 'Communication',
      COMMUNICATION_OPTION: 'Receive Skada news via email',
    },
    PASSWORD_TAB: {
      OLD_PASSWORD: 'Old Password',
      NEW_PASSWORD: 'New Password',
      CONFIRM_PASSWORD: 'Confirm Password',
    },
    PIN_TAB: {
      OLD_PIN: 'Old Pin',
      NEW_PIN: 'New Pin',
      CONFIRM_PIN: 'Confirm Pin',
      PIN_PLACEHOLDER:'Enter 4 digit Pin. It will be required during withdrawals.'
    },
    TWO_FA_TAB: {
      TITLE: 'Two-Factor Authentication',
      VERIFY: 'Verification Token',
      QR_LABEL: 'Scan QR code or type code below to your Google Authenticator App, then confirm your verification token.',
      VERIFIED_SUCCESSFULLY: 'User is verified successfully.',
      NOT_VERIFIED: 'Unable to verify this user!'
    },
    PAYMENT_TAB: {
      FAILED_SAVE_DEFAULT_MESSAGE: 'An unknown error occurred while saving payment details.',
      PAYMENT_ACCOUNT_NUMBER:'Account Number'
    }
  },
  ACTIVE_LOGS: {
    TITLE: 'Activity Logs',
    NO_DATA:'No data related to Activity Logs.',
    DESCRIPTION: 'Browse your history easily. All your major actions are logged and shown here. The log below shows latest history from the last 10 days.',
    TABLE_HEADERS: {
      DATE: 'Date',
      ENTRY: 'Entry'
    }
  },
  FINANCES: {
    NEWS_UPDATE:'News Updates',
    // BUY_CRYPTO:'Buy Crypto',
    VIEW_CASH_DEPOSIT:'View Gcash Deposit Steps',
    BUY_CRYPTO_WITH_CARD:'Buy Crypto With Card',
    BUY_CRYPTO_INFO:'Dont Have Crypto? Dont Worry!. You can buy Crypto Using Visa/Mastercard',
    DEPOSIT: 'Deposit',
    SEND: 'Send',
    WITHDRAW: 'Withdraw',
    DEPOSIT_BUTTON: 'Confirm',
    DEPOSIT_AMOUNT:'Enter Deposit Amount',
    CONFIRM:'Confirm',
    PAYMENT_PROCESSOR: 'Choose Payment Method',
    SPENDING_WALLET_BALANCE:'Spending Wallet Balance: ',
    COIN_TYPE: 'Coin Type',
    BALANCE_TYPE: 'Choose Balance',
    EARNING_WALLET_BALANCE:'Earning Wallet Balance',
    CHOOSE_PAYMENT_METH:'Choose Payment Method',
    WITH_CONFIRM:'Confirm',
    WITHDRAW_AMOUNT:'Withdraw Amount',
    SELECT_PLACEHOLDER: 'Select',
    SELECT_PLACEHOLDER_COUNTRY_AGENT:'Filter Country',
    AMOUNT: 'Amount',
    AMOUNT_ERROR:'Insufficient funds',
    PAYMENT_DETAILS_ERROR:'Please enter your payment details in the settings page',
    MIN_DEPOSIT_AMOUNT: 'Minimum deposit amount',
    GO_TO_FINANCES: 'Go to Finances',
    FINANCE_SUCCESS_MSG:'Your Deposit Was Successful',
    GO_TO_TRANSACTIONS: 'Go To Transactions',
    DEPOSIT_HELP_MESSAGE: 'Your balance will reflect shortly',
    TRANSFER: 'Transfer',
    TRANSFER_TYPE_OPTIONS: {
      MY_BALANCES: 'My Balances',
      POINTS: 'Points',
      OTHER: 'Other'
    },
    RECIPIENT: 'Recipient',
    RECIPIENT_USERNAME: 'Recipient Username',
    FROM_BALANCE_TYPE: 'From Balance',
    TO_BALANCE_TYPE: 'To Balance',
    TRANSFER_FEE: 'Transfer Fee',
    REVIEW_AND_CONFIRM_MODAL: {
      TITLE: 'Review and Confirm',
    },
    TRANSFER_RESULT: {
      SUCCESS_TITLE: 'Transfer has been successful',
      SUCCESS_MESSAGE: 'Your balance will reflect shortly',
      DECLINED_TITLE: 'Transfer has been declined',
      DECLINED_MESSAGE: 'An error occured while performing the operation. Please try again or contact the system administrator if the problem remains'
    },
    DEPOSITE_RESULT: {
      DECLINED_TITLE: 'Deposit Failed',
    },
    WITHDRAW_RESULT: {
      SUCCESS_TITLE: 'Your Payout request has been submitted.',
      SUCCESS_DESCRIPTION1: 'You can track the status in the withdrawal history page.',
      SUCCESS_DESCRIPTION2: 'You will recieve an email notification immediately its processed.',
      SUCCESS_MESSAGE: 'You will receive...',
      DECLINED_TITLE: 'Withdrawal Failed',
      DECLINED_MESSAGE: 'Please try again or contact the support team',
    },
    VIEW_GCASH_WITH:'View Gcash Withdrawal Steps',
    PIN_ERROR: 'Error occured while sending request to check pin.',
    WITHDRAW_BUTTON: 'Withdraw',
    PAYMENT_DETAILS: 'Payment Details',
    MINIMUM: 'Minimum',
    MAXIMUM: 'Maximum',
    FEES: 'Fees',
    AMOUNT_RECEIVABLE: 'Amount Receivable',
    FUNDS:'Insufficient funds',
    PAYOUT_STATUSES: {
      PENDING: 'Pending',
      FINISHED: 'Finished',
      REJECTED: 'Rejected',
    },
    VOUCHERS: {
      SUCCESS: 'Success',
      DEPOSIT_HISTORY: 'Deposit Voucher History',
      CODE: 'Code',
      AMOUNT: 'Amount',
      USED_DATE: 'Used Date',
      STATUS: 'Status',
      RECIEVED_FROM: 'Recieved From',
      VOUCHER:'Membership',
      TAB_NAME: ' Airtime codes',
      DESCRIPTION: 'Redeem your codes into real money or Buy one from our authorised resellers.',
      CODE_PLACEHOLDER: 'Enter Code',
      BALANCE_LABEL: 'Select Balance',
      REDEEM_CODE_BUTTON: 'Redeem Code',
      REDEEM_SUCCESSFUL_RESULT: 'Redeeming of voucher code has been successful',
      REDEEM_FAILED_RESULT: 'Redeeming of voucher code has failed',
      TRANSFER: {
        TITLE: 'Transfer',
        SUCCESS: 'Voucher code has been sent!',
        ERRORS: {
          UNKNOWN: 'Unknown',
          CODE_NOT_EXISTS: 'Voucher code don\'t exist',
          TRANSFER_CODE_FAILED_DUE_TO_USERNAME_OR_CODE: 'Transfer code failed due to username or code',
          USER_DOES_NOT_OWN_CODE: 'User does not own code',
          CODE_IS_EXPIRED: 'Sorry, this code is already expired',
          REPRESENTATIVE_COULD_NOT_REDEEM: 'Representatives can\'t redeem Voucher Codes. Please visit deposit page to deposit funds',
          CODE_IS_IN_ACTIVE: 'This code is not Active any more',
          USER_ALREADY_USED_CODE: 'User already used that code',
          TARGET_BALANCE_IS_NOT_SUPPORTED: 'Target balance is not supported',
          FEATURE_IS_DISABLED: 'Voucher code feature is disabled',
          ERROR_RELATED_TO_BUYING: 'Error related to buying voucher codes',
          AMOUNT_OF_VOUCHER_CODE_IS_TOO_LOW: 'Amount of voucher code is lower than minimal transfer amount',
          USER_ALREADY_REPRESENTATIVE: 'User is already a representative.'
        }
      },
    },
    EXTRA_MINUTES: {
      TAB_NAME: 'Extra Minutes',
      DESCRIPTION: 'According to your plan you can buy up to ',
      EXTRA_MINUTES_VALUE: '{{value}} minutes.',
      MIN: 'min',
      PTS: 'pts',
      BUYING_LABEL: 'Buying',
      EARNING_LABEL: 'Earning',
      BALANCE_LABEL: 'Balance',
      MINUTES_TO_BUY_LABEL: 'Minutes to Buy',
      TOTAL_PRICE_LABEL: 'Total Price',
      BUY_MINUTES_BUTTON: 'Buy Minutes',
      RESULT_PAGE: {
        SUCCESS_TITLE: 'Buying extra minutes has been successful',
        SUCCESS_MESSAGE: 'Your paid minutes will reflect shortly',
        DECLINED_TITLE: 'Buying extra minutes has failed',
        DECLINED_MESSAGE: 'An error occured while performing the operation. Please try again or contact the system administrator if the problem remains',
        ERRORS: {
          RS_BEM_1: 'Radio streaming extra minutes feature is disabled.',
          RS_BEM_2: 'Balance is not available for buying extra minutes.',
          RS_BEM_3: 'Count of minutes to buy must be greater than zero.',
          RS_BEM_4: 'Extra minutes purchase limit exceeded.',
          RS_BEM_5: 'Not enough funds to buy extra minutes.',
        }
      }
    },
    TRANSACTIONS_HISTORY: {
      DASHBOARD_PLAN_NAME:'Membership',
      USER_NAME:'User Name',
      PLAN_VALIDITY:'Subscription Valid Till',
      DAYS:'({{value}} days remaining)',
      TAB_NAME: 'Transactions',
      NO_DATA: 'No data related to transactions history.',
      NO_RECORDS: 'No records found.',
      HEADERS: {
        DATE_AND_TIME: 'Date & Time',
        ENTRY: 'Entry',
        AMOUNT: 'Amount',
        WALLET:'Wallet',
        BALANCE: 'Balance'
      }
    },
    WITHDRAWAL_HISTORY: {
      TAB_NAME: 'Withdrawal history',
      NO_DATA: 'No data related to withdrawal history.',
      NO_RECORDS: 'No records found.',
      HEADERS: {
        DATE: 'Date',
        AMOUNT: 'Amount',
        PAYMENT_PROCESSOR: 'Payment Processor',
        ADDRESS: 'Address',
        STATUS: 'Status',
        BALANCE: 'Balance'
      }
    },
  },
  MEMBERSHIP: {
    TABS: {
      PLANS: 'Subscription Plans',
      EARNINGS: 'Earnings Calculator',
      BOOSTER:'Booster Plans',
      BOOSTER_ACCOUNT:'Booster Account',
      BACK_BTN : 'Back'
    },

    PLAN_CARD: {
      PLAN_UPDATED: {
        VALID_FOR_DAYS:'Valid for {{value}} days',
        EVERY_DAY_BEN:"EVERYDAY BENEFITS",
        POTENTIAL_EARNING:'POTENTIAL EARNINGS',
        EARN:'Earn <b>{{value}}</b> / Video',
        PAID_VID:'<b>{{value}}</b> Paid Videos Daily',
        HAS_PAID_VID:'Has <b>{{value}}</b> Paid Videos',
        WELCOME_BONUS:'Earn <b>{{value}}</b> (Incl Welcome Bonus)',
        WELCOME_BONUS_TWO:'Earn <b>{{value}}</b> Per Plan',
        REF_ON_THIS_PLAN:'<b>{{value}}</b> / Referral On This Plan',
        EAR_DAILY:'Earn <b>{{value}}</b> Daily',
        EARN_TRAIL:'Earn <b>{{value}}</b> Trial Period',
        EARN_WEEKLY:'Earn <b>{{value}}</b> Weekly',
        SEVEN_DAYS_TRAIL:'7 Days Trial',
        EARN_THIRTY_DAYS:'Earn <b>{{value}}</b> In 30 Days',
        EARN_SIXTY_DAYS:'Earn <b>{{value}}</b> In 60 Days',
        MIN_WITHDRAW:'Minimum Withdrawal <b>{{value}}</b>',
        NO_MIN_REF:'No Minimum Referrals',
        MAX_REF:'Maximum Referrals: <b>Unlimited</b>',
        BUY_NOW:'Buy Now',
        MIN_INCOME:'Min Income: <b>{{value}}</b> Per Account',
        EARN_PER_REF:'Earn <b>{{value}}</b> Per Referral On This Plan(10%).'
      },
      NEW_PLAN:{
        RENEW_SUB:'Renew Subscription Yearly or After You Finish Watching all Total Videos Allocated. (Whichever Comes earlier)',
        UPGRADE_HIG_PLAN:'Upgrade to higher plan at anytime',
        BASE_INCOME_TEXT:'The Base Income on each of the plans is calculated based on a standard scenario where you unlock all of your personal tasks and team tasks with 8 referrals on the same plan as you over the 8 Weeks( 60 days subscription period). However, this is just the base earning potential—you can absolutely earn much more by continuing to grow your earning team and by unlocking tasks and bonuses with more referrals or higher-level referrals joining your earning team',
        INITIAL_INVESMENT_TXT:"In PlayPayouts, the initial investment refers to the price you pay to purchase a plan, which acts as your rotating capital. This means that you only need to invest this amount once. After every 60 days (the plan cycle), you will have made your profits, which you can cash out. Then, you simply reinvest the original capital, allowing you to keep earning profits all year round. The key benefit is that this initial capital continues to work for you—enabling you to earn profits consistently without needing new funds, as long as you keep the same plan. It’s like having money that constantly regenerates, helping you build sustainable passive income!",
        HEADER:{
          GROWTH:'GROWTH',
          BUSINESS:'BUSINESS',
          MEMBERSHIP_PLAN:'MEMBERSHIP PLAN',
          DAYS:'valid for {{value}} days',
          BUY_NOW:'Buy Now',
          EVERYDAY_BENEFITS:'EVERYDAY BENEFITS',
          EARNING_PER_TASK:'Earnings Per Task/Video Viewed',
          MAX_NO_VID:'Maximum Number of Daily Paid Videos',
          MAX_DAILY_PAID_VID:'Daily Earnings On Completing Maximum Daily Paid Videos',
          SOURCE_OF_INCOME :'Can Earn From 3 Sources of Income?(Personal Tasks, Team Tasks & Referral Bonuses)',
          MAX_DAILY_VID : 'Maximum Daily Earnings From Referral Upgrade Bonuses',
          MIN_WATCH_TIME:'Minimum Watch Time To Earn From Video',
          TASK_ALLOCATION : 'TASK ALLOCATION',
          PERSONAL_TASK_ALLOCATED:'Personal Tasks Allocated(Do Not Require A Referral To Unlock)',
          TEAM_TASK_ALLOCATED:'Team Tasks Allocated  (Team Tasks Are Unlocked By Your Referrals)',
          EACH_REF:'Each Referral On This Plan Unlocks',
          POTENTIAL_EARNING:'POTENTIAL EARNINGS',
          BASE_INCOME:'Base Income',
          INITIAL_INVESEMENT:'Initial Investment (Rotating Capital)',
          EARNING_FROM_PERSONAL_TASK:'Earnings From All Personal Tasks',
          EARNING_FROM_TEAM_TASK:'Earnings From All Team Tasks',
          REF_UPGRADE_BONUS:'Referral Upgrade Bonuses From 1st 8 Referrals On This Plan(10% Upgrade Bonus)',
          MAX_EAR_UPGRADE_BONUS:'Maximum Earnings From Upgrade Bonuses',
          CASHOUT_TEAM_TASK:'Can I Unlock & Cashout All Team Tasks In Less Than 60 days?',
          AVERAGE_DAILY_EAR:'Average Daily Earning Rate & ROI',
          REF_COMMISSION_STAGE_ONE:'REFERRAL COMMISSIONS (Stage 1)',
          UNLOCK_YOUR_TEAM_TASK:'This is the instant commission earned Before you unlock all your Team Tasks.',
          INSTANT_UPGRADE_BONUS:'Stage 1 Instant Referral Upgrade Bonus',
          INSTANT_UPGRADE_BONUS_AMOUNT:'Stage 1 Instant Upgrade Bonus Amount Earned From Each Referral',
          REF_COMMISSION_STAGE_TWO:'REFERRAL COMMISSIONS (Stage 2)',
          EAR_AFTER_UNLOCK_YOUR_TEAM_TASK:'This is the instant commission earned After you unlock all your Team Tasks.',
          STAGE_TWO_REF_UPGRADE_BONUS:'Stage 2  Referral Upgrade Bonus Is: No Stage 2 Upgrade Bonus',
          STAGE_TWO_REF_UPGRADE_BONUS_AMOUNT:'Stage 2 Referral Upgrade Bonus Amount Earned From Each Referral',
          ADDITIONAL_ACC_FEATURES:'ADDITIONAL ACCOUNT FEATURES',
          TEAM_TASK_TO_CASH:'Convert to cash feature Enabled',
          CONVERT_TO_CASH_FEES:'Convert to cash Fees',
          ENABLE_AUTOMATIC_VID:'Can I Enable Automatic Video Watching Feature? ( COMING SOON)',
          UNLOCK_TEAM_TASK:'Can I Rent Referrals To Unlock Team Tasks? ( COMING SOON)',
          TEAM_TASK_AUTOMATIC_UNLOCK:'Are TeamTasks Automatically Unlocked When My Referral Upgrades/Renews Subscription?',
          UNLOCKED_ALL_MY_TEAM_TASKS:"Do I Earn 20% Upgrade Bonus For Any New Referral I Get After I've Unlocked All My Team Tasks?",
          AFTER_UNLOCKED_ALL_MY_TEAM_TASKS:'Do I Need To Refer Any One Else After Unlocking All My Team Tasks?',
          UPGRADE_TO_HIGHER_PLAN:"Can I Upgrade To Higher plan at anytime and get more Tasks?",
          MIN_WITHDRAWAL:'Minimum Withdrawal',
          DAILY_WITHDRAWAL_LIMIT:'Daily Withdrawal Limit',
          AUTOMATIC_WITHDRAWAL:'Automated Withdrawals',
          COUSTOMER_SUPPORT:'Customer Support'
        },
        PLAN_NAME:{
         EARN_PER_VODEOS:'Earn {{value}} Per Task/Video',
         PAID_VIDEOS_DAILY:'{{value}} Paid Videos Daily',
         PER_DAY:'{{value}} Per Day',
         SOURCE_OF_INCOME:'Only 1 Source of income',
         YES:'Yes',
         NO_REF_EARNING:'No Referral Earnings',
         UNLIMITED:'Unlimited',
         VID_SECOND:'20 Seconds',
         VALID_DAYS:'VALID {{value}} DAYS',
         PERSONAL_TASK:'{{value}} Personal Tasks',
         TEAM_TASK:'{{value}} Team Tasks',
         NO_EARNING_FROM_TEAM_TASKS:'No Earnings From Team Tasks',
         NO:'No',
         LIMITED:'Limited',
         DAILY:'{{value}}% Daily',
         STAGE_ONE_UPGRADE_BONUS:'No Stage 1 Upgrade Bonus',
         STAGE_TWO_UPGRADE_BONUS:'No Stage 2 Upgrade Bonus',
         COMING_SOON:'Yes(Coming Soon)',
         NOT_MUST:'Not A Must',
         REGULAR_SUPPORT:'Regular Support',
         APP_CHAT:'Priority In-App Chat'
        } 
      },
      MONTH_TASK_ALLO:'Monthly Task Allocation',
      WITHDRAW_LIMIT: 'WITHDRAWAL LIMITS',
      VID_TOKEN_DIS: 'VIDEO FARMING',
      DAILYQUOTA:'DAILYQUOTA',
      MONEY_BACK_GUARANTEE_TEXT:'30day Money Back Guarantee',
      RECOMMENDED: 'Recommended',
      REFERRAL_DEPOSIT:'{{value}}% Commission for each Referral Deposit',
      DAILY_PAID_MINUTES: 'Get {{value}} Videos Daily',
      DAILY_PAID_MINUTES_TEXT: 'Get {{value}} Videos Daily',
      MINUTE_RATE: 'Earn: {{value}} Per Day',
      MINUTE_RATES: 'Earn <b>{{value}}</b> Daily',
      MINUTE_RATE_TEXT: 'Not Applicable Paid Playtime Per Month',
      AVG_DAILY_INCOME: 'Earn {{value}} Per Video',
      AVG_DAILY_INCOME_TEXT: 'Earn <b>{{value}}</b> Per Video',
      TOTAL_VIDEO_TOKENS : 'Earnings From Total Video Tokens Redeemed By Watching <b>{{value}}</b>',
      FLOATING_TOKENS : '<b>{{value}}</b> Floading Tokens',
      LOCKED_VID_TOKENS:'Locked Video Tokens(LVDTs) - Unlock To Watch - Not Applicable',
      LOCKED_VID_TOKENS_TWO :'Locked Video Tokens(LVDTs) - Unlock To Watch <b>{{value}}</b> Tokens To Unlock',
      HOW_MANY_VIDEO_TOKEN : 'Each Referral Unlocks How Many Video Tokens(LVDTs) <b>{{value}}</b>',
      UNLOCK_PER_REF : 'Number of Videos You Will Unlock Per Referral On this Plan <b>{{value}}</b>',
      VID_UNLOCK_BY_REF : 'Earnings Per Video Unlocked By Referral <b>{{value}}</b>',
      REF_ON_THIS_PLAN:'Earnings from Each Referral On This Plan <b>{{value}}</b>',
      INCREASES_DAILY_VID : 'Each Referral Increases Daily Video Quota By: <b>{{value}}</b>',
      MINIMUM_WID:'Minimum Withdrawal  <b>{{value}}</b>',
      MAXIMUM_WID:'Not Applicable',
      MAXIMUM_WID2:'Maximun Withdrawal Per Day - <b>{{value}}</b>',
      MAXIMUM_EARNING:'Max Weekly Earning <b>{{value}}</b>',
      PAID_VID:'<b>1</b> VDT = <b>1</b> Paid Videos',
      AVG_MONTHLY_INCOME: 'Monthly Earnings <b>{{value}}</b>',
      AVG_MONTHLY_INCOME_TEXT: '<b>{{value}}</b> Days Free Trial',
      REFERRAL_MINUTE_RATE: '3 Months Earnings {{value}}',
      REFERRAL_MINUTE_RATE_TEXT: 'Minimum Withdrawal <b>USD $ 10.00</b>',
      REFERRAL_MINUTE_RATE_TEXT_MINI: 'Minimum Withdrawal <b>USD $ 15.00</b>',
      COMMISION_MINUTE:'Referral Commission {{value}} / Per Minute',
      UPGRADE_HIGHER_PLAN:'Upgrade To Higher Plan Any Time',
      COMMISION_MINUTE_TEXT:'Referral Commission {{value}} / Per Minute',
      EXTRA_UPGRADE:'Each Upgrade Extra 365 days',
      RENEW_SUBSCRIBE:'Renew Subscription After 365 days',
      RENEW_SUBSCRIBE_TEXT:'Renew Subscription After 14 days',
      ACCOUNT_VALID_FOR: '{{value}} Days Membership',
      ACCOUNT_VALID_FOR_TEXT: '10 Days Membership',
      BASIC_PLAN_PRICE: '{{value}}',
      PLAN_PRICE: '{{value}}',
      TRIAL: 'Free {{value}} Day Trial',
      DETAILS_BUTTON: 'Plan Details',
      BUY_NOW: 'Buy Now',
      CURRENT_PLAN:'Current plan',
      PACK_PRICE: 'Pack Price',

      PER_VIDEOS:'<b>{{value}}</b> Paid Videos Per Day',
      WEEKLY_VIDEOS:'Earn <b>{{value}}</b> Weekly',
      MINIMUM_WITH:'Minimum Withdrawal {{value}}',
      SUBS_DAYS:'Subscription Valid for {{value}} Days',
      TOP_HIGHER_PLAN:'Top-up Difference To Move To Higher Plan',
      COMMISSION:'Commission <b>{{value}}</b>% Of What your Referral Earns/Video',
      VID_ACC : 'You Get <b>{{value}}</b> Video Tokens(VDTs)',
      FREE_TRIAL_TEXT:'Free Trial Valid For <b>{{value}}</b> Days',
      FREE_ACCOUNTS_TEXT:'Free Accounts Are Frozen Upon Expiry',
      UPGRADE_HIGHER_TEXT:'Upgrade To Higher Plan to Boost Earnings',
      REFFERAL_COMM_TEXT:'No Referral Commission',
      SUB_UPDATED:'Subscription Valid For {{value}} Days From Date Of Purchase',
      Earn_for_60days:'Earn {{value}} Every {{day}} Days',
      PERCENT_UPGRADE1:'Earn Extra {{value}}',
      PERCENT_UPGRADE2:'Per Referral Upgrade ({{percent}}%)',

      YEARLY_EARNINGS:'Earn <b>{{value}}</b> Yearly',
      REFFERAL_UPGRADE1:'Earn <b>{{value}}</b>',
      REFFERAL_UPGRADE2:' When Your Referral Upgrades <b>({{percent}}%)</b>',
      SUB_YEAR_DAYS:'Subscription Valid For <b>1</b> Year'
    },
    BOOSTER_PLANS:{
      SUBS_TEXT:'Subscription Valid for {{value}} Days',
      EARN_PER_HOUR:'Earn {{value}} Per Hour',
      EARN_PER_DAYS:'Earn {{value}} Per Day',
      EARN_WEEKLY:'Earn {{value}} Weekly',
      EARN_MONTHLY:'Total Earnings {{value}}',
      MIN_MEMBERSHIP:'Minimum Membership required {{value}}',
      PACK_NAME:'Booster Plan Name',
      TOTAL_EARNED: 'Total Earned',
      MEMBERSHIP_PURCHASE_DATE:'Booster Purchase Date',
      MEMBERSHIP_EXPIRE_DATE:'Booster Expired Date',
      TOTAL_EARNINGS: 'Total Earnings',
      ACTIVE_COUNT:'Active Count',
      EXPIRE_COUNT:'Expire Count',
      VIEW_BTN:'View',
      NO_RECORD : 'There is no booster plans purchased.',
      PURCHASED_PACK:'Purchased Packs',
      BOOSTER_TABLE:{
        BOOSTER_NO: 'Boosterback No',
        BOOSTER_NAME:'Booster Name',
        PURCHASE_DATE: 'Purchase Date',
        EXPIRE_DATE: 'Expire Date',
        TOTAL_EARNINGS: 'Total Earnings',
        TOTAL_STATUS: 'Total Status',
      }
    },
    PLANS: {
      HEADER_TITLE: 'Important Notes:',
      HEADER_DESCRIPTION: 'Upgrade your account to benefit more. Check our plans below',
      DETAILS_BUTTON: 'Plans Details',
      MORE_INFO:'More Info',
      SHOW_LESS:'Show Less',
      HEADER_TEXT:"<b>Please Note</b>: The Base Income on each of the plans is calculated based on a standard scenario where you unlock all of your personal tasks and team tasks with 8 referrals on the same plan as you over the 8 Weeks( 60 days subscription period).",
      HEADER_TEXT_NEW:'However, this is just the base earning potential you can absolutely earn much more by continuing to grow your earning team and by unlocking tasks and bonuses with more referrals or higher-level referrals joining your earning team',
      HEADER_LINK:'click here'
    },
    UPDATE_MEMBERSHIP_MODAL: {
      TITLE: '{{value}} Memberships',
      CONFIRM_BUTTON: 'Confirm Purchase',
      BALANCE_PLACEHOLDER: 'Select',
      BALANCE_LABEL: 'Balance',
      PACK_BALANCE :'Pack Price',
      BOOSTER_TITLE:`{{value}} Booster`
    },
    ENOUGH_MONEY_MODAL: {
      TITLE: 'Oops...',
      MESSAGE: 'It seems you do not have enough money to change your current membership. Go to finance to deposit or transfer',
      CONFIRM_BUTTON: 'Go to Finance',
    },
    PLANS_DETAILS: {
      FEATURES_BUTTON: 'Options and Features',
      COMMISSIONS_BUTTON: 'Commissions',
      FREE_BUTTON: 'Free',
      BACK_TO_PLANS_BUTTON: 'Back to plans',
      COMMISSIONS: {
        RADIO_STREAMING: 'Direct Referral Radio streaming Listen',
        MEMBERSHIP_PERCENT: 'Membership Purchase',
      },
      OPTIONS: {
        DIRECT_REFERRALS_LIMIT: 'Direct referrals limit',
        MINIMUM_PAYOUT_CASH_OUT_LIMIT: 'First minimum payout (cash out limit)',
        MAXIMUM_DAILY_PAYOUT: 'Maximum daily payout',
        MIN_DAILY_VIDEOS_TO_EARN_FROM_REFS: 'Min Daily Videos To Earn From Refs',
        REFERRAL_TOOLS_AVAILABLE: 'Referral Tools available',
        MAX_NUMBER_OF_WATCHED_VIDEOS_DAILY: 'Maximum YouTube videos daily views',
        EARNING_FOR_YOUTUBE_VIDEO_MONEY: 'Earning for YouTube video (money)',
        EARNING_FOR_YOUTUBE_VIDEO_POINTS: 'Earning for YouTube video (points)',
        FEE_FOR_RECEIVING_TRANSFERS_TO_MAIN_BALANCE_FROM_MAIN_BALANCE_FROM_OTHER_USERS: 'Fee for receiving transfers to Main Balance from Main Balance from other users',
        MONEY_EARNING_PER_INTERVAL_FOR_RADIO_STREAMING: 'Money Earning Per Interval for radio streaming',
        POINTS_EARNING_PER_INTERVAL_FOR_RADIO_STREAMING: 'Points Earning Per Interval for radio streaming',
        MAX_MINUTES_DAILY_FOR_RADIO_STREAMING: 'Max minutes daily for radio streaming',
        MIN_MINUTES_DAILY_FOR_RADIO_STREAMING_TO_START_EARNING: 'Min minutes daily for radio streaming to start earning',
        MIN_DAILY_MINUTES_TO_EARN_FROM_REFS_RADIO_STREAMING: 'Min Daily Minutes To Earn From Refs Radio streaming',
        FEE_FOR_RECEIVING_TRANSFERS_TO_PURCHASE_BALANCE_FROM_PURCHASE_BALANCE_FROM_OTHER_USERS: 'Fee for receiving transfers to Purchase Balance from Purchase Balance from other users',
        WITHDRAWALS_PER_DAY: 'Withdrawals per day',
        MAXIMUM_WITHDRAWAL_OF_DEPOSITED_AMOUNT: 'Maximum withdrawal of deposited amount',
        MINIMUM_WAIT_TIME_BETWEEN_EACH_WITHDRAWAL: 'Minimum wait time between each withdrawal',
        BONUS_OF_DEPOSITED_VALUE: 'Bonus of deposited value',
        MINIMUM_DIRECT_REFERRALS_COUNT_TO_ALLOW_MAKE_PAYOUT: 'Minimum Direct Referrals count to allow make payout',

      }
    },
    DETAILS_DATA: {
      DAYS: 'days',
    },
    INCOME_CALCULATOR: {
      MEMBERSHIP_SELECTOR_LABEL: 'Your Membership Plan',
      REFS_MEMBERSHIP_SELECTOR_LABEL: 'Referrals Membership Plan',
      EARNINGS : 'Earnings',
      TOTAL_EARNINGS:'Total Earnings',
      YOUTUBE_AD: 'Paid videos',
      MOVIE_AD : 'Movie Ad',
      LABELS: {
        USER_DAILY_WATCHES: 'My Daily Watched Videos',
        YOUTUBE_USER_LIMIT: 'Your Paid Video Limit',
        USER_DAILY_LISTEN: 'My Daily Listened Minutes',
        USER_MOVIE_LIMIT: 'Movie User Watch Limit',
        USER_MAX_REFS: 'Number Of Upgrade Direct Referrals',
        REFS_DAILY_WATCHES: 'Referrals Daily Watched Videos',
        YOUTUBE_REFRAL_LIMIT: 'My Referral Daily Number of videos',
        REFS_DAILY_LISTEN: 'Referrals Daily Listened Minutes',
        MOVIE_REFRAL_LIMIT: 'Movie referral Watch Limit',
      },
      ERRORS: {
        USER_DAILY_WATCHES: 'Your max number of daily watched videos - {{value}}',
        USER_DAILY_LISTEN: 'Your max number of daily listened minutes - {{value}}',
        USER_MAX_REFS: 'Your max number of referrals - {{value}}',
        REFS_DAILY_WATCHES: 'Your max number of referrals daily watched videos - {{value}}',
        REFS_DAILY_LISTEN: 'Your max number of referrals daily listened minutes - {{value}}',
      }
    },
    INCOME_CARD: {
      USER_TITLE: 'User',
      REFS_TITLE: 'REFERRAL',
      TOTAL_TITLE: 'TOTAL',
      LABELS: {
        DAILY: 'Daily',
        WEEKLY: 'Weekly',
        MONTHLY: 'Monthly',
      }
    },
    MESSAGES: {
      UPDATE_PLAN_SUCCESS: 'Your membership has been successfully changed to {{value}}',
    },
  },
  DASHBOARD: {
    UPGRADE_COMMISSION_EARNED : 'Upgrade Commissions Earned',
    UPGRADE_COMMISSION_EARNED_TEXT:'You have earned upgraded commissions <b>{{value}}</b>',
    SHOW_REMAINING:'Show Remaining',
    TOTAL_VIDEOS_WATCHED:'Total Videos Watched',
    DAILY_QUOTA_TEXT:'Your Daily Paid videos limit is: <b>{{value}}</b',
    WORK_PROGRESS:'Work Progress',
    PERSONAL_TASK_TITLE:'PERSONAL VIDEOS',
    TOTAL_TASK_UNLOCKED_TITLE:'TOTAL VIDEOS UNLOCKED',
    // TOTAL_TASK_COMPLETED_TITLE:'TOTAL VIDEOS COMPLETED',
    LEFT_TO_WATCH_TITLE:'LEFT TO WATCH',
    PERSONAL_TASK:'You unlocked <b>{{value}}</b>  Paid Videos upon Upgrade to <b>{{value2}}</b> plan',
    // TEAM_TASK:'You unlocked <b>{{value}}</b> Team Videos Out Of <b>{{value2}}</b> eam Videos To Be Unlocked By Referrals.',
    TOTAL_TASK_UNLOCKED: 'You have a total of <b>{{value}}</b> videos worth <b>{{total}}</b> (Personal + Team Videos) already unlocked.',
    TOTAL_TASK_COMPLETED: 'You already watched/cashed out <b>{{value}}</b> of the <b>{{value2}}</b> Total Videos Unlocked.',
    LEFT_TO_WATCH:'You have <b>{{value}}</b> Videos remaining to be watched out of <b>{{value2}}</b> Total Videos Unlocked.',
    LEFT_TO_WATCH_TEXT:'You have <b>{{value}}</b> Videos remaining to be watched out of <b>{{value2}}</b> Total Videos Unlocked.Enough for <b>{{value3}}</b> days.',
    POTENTIAL_BALANCE:'Potential Earnings',
    // AIRTIME_BALANCE:'Airtime Balance',
    CASHOUT_VIDEO_COUNT: "How many unlocked videos you need to cashout?",
    CASHOUT:'Convert To Cash',
    TOTAL_AMOUNT:'Total Amount ({{count}} videos x {{amount}} per video):',
    TOTAL_FEE:'Total Fee ({{value}} %) : ',
    PERCENTAGE:'Percentage : ',
    NET_AMOUNT:'Net Amount :',
    CASHOUT_UNLOCKED_VID:'Cashout Unlocked Videos',
    CONFIRM_BTN:'Confirm',
    VIEW_MORE:'View More',
    CHOOSE_MEMBERSHIP:'Choose Membership',
    MAIN_BALANCE:'Earning Wallet',
    PURCHASE_BALANCE: 'Spending Wallet',
    WATCHED_TOTAL : 'Watched/ Total Allocated',
    TO_BE_UNLOCKED:'Pending To Unlock',
    TO_BE_UNLOCKED_TEXT:'You have <b>{{value}}</b> Videos worth  <b>{{op}}</b> to unlock before ({{date}})',
    ALREADY_UNLOCKED_TEXT:'You Already Unlocked ',
    VALUE:'{{value}}',
    VIDEO_WORTH:'Videos worth',
    TOTAL:'{{total}}',
    COMPLETE:'& can complete watching in',
    DAILYQUOTA:'{{dailyQuota}} days',
    DAY:'days',
    ALREADY_UNLOCKED_COMPLETED_TEXT:'You Already Unlocked ',
    SEC_VALUE:'{{value}}',
    VIDEO_WORTHS:'Videos worth',
    TOTALS:'{{total}}',
    COMPLETED:'& completed',
    VIDEOS_CURRENT_PLAN:'Videos on current plan.',
    ALREADY_UNLOCKED:'Already Unlocked',
    WIDTHDRAW:'Total Withdrawals',
    AIRTIME_BALANCE:'Potential Earnings <b>({{value}}days)</b>',
    MEMBERSHIP_PLANS:'Membership Plan',
    CURRENT_BALANCE : 'Current Balance',
    EARNING_WALLET:'Total Earnings Upto Today',
    DAILY_WATCH_LIMIT:'Daily Quota',
    PAID_VIDEOS_MSG :' Paid Videos Per Day',
    TOTAL_REF : 'Total Referrals',
    TOTAL_REF_TEXT:'<b>{{value}}</b> Upgraded & ',
    TOTAL_REF_TEXT2:' <b>{{value}}</b> Free',
    LEVEL: 'Level',
    VALID_TILL: 'Valid Till',
    LEVEL_DETAILS: 'Level Details',
    USER_NAME:'User Name',
    BOOST_LEVEL: 'Boost Level',
    BALANCE: 'balance',
    MONEY: 'Money',
    POINTS: 'Points',
    PLAYED_TIME: '{{ hours }} h {{ minutes }} min',
    RADIO: {
      TITLE: 'radio',
      DAY: 'Day',
      WEEK: 'Week',
      MONTH: 'Month',
      YEAR: 'Year',
      TOTAL_TYPE: 'Total Time Played',
      EARNING_RATE: 'Earning Rate',
      TOP_STATIONS: 'Top 5 Stations',
      CHART:'chart'
    },
    QUICK_ACCESS: {
      QUICK_ACCESS: 'quick access',
      DEPOSIT_MONEY: 'Deposit Money',
      SEND_MONEY: 'Send Money',
      CASH_OUT: 'Cash Out',
      AIRTIME_CODES: 'Airtime Codes',
      LISTEN_AND_EARN: 'Listen to Radio and Earn',
      WATCH_AND_EARN: 'Watch Videos and Earn',
      INVITATION_LINK: 'Invitation Links',
      SUPER_BOOST: 'Superboost',
      TRENDING_RADIO: 'Trending Radio Stations',
    },
    TRENDING_MOVIES: {
      NOW_PLAYING: 'now playing',
    },
    REFERRALS: {
      NUMBER: 'Referrals Number',
      INCOME: 'Referrals Income',
      ON_FREE_PLAN: 'Referrals On Free Plan',
      PAID_PLANS:'Referrals On Paid Plan',
      TOTAL_REFERRALS:'Total Referrals',
      ACTIVE_REFERRALS:'Upgraded Referrals',
      TOTAL_VIDEO_UNLOCKED:'Total Deposits By Referrals',
      TOTAL_COMMISION_EARNED:'Upgrade Commissions Earned'
    },
    ACTIVITIES: {
      ACTIVITIES: 'Activities',
      BALANCES: 'Balances',
      BUTTONS: {
        EARNING_WALLET: 'Earning Wallet',
        POINTS_BALANCE: 'Points Balance',
        AIRTIME_BALANCE: 'Airtime Balance',
        COMMISSION_BALANCE: 'Commission Balance',
        CASH_BALANCE: 'Cash Balance',
        TOTAL_DEPOSIT: 'Total Deposits',
        TOTAL_SENT: 'Total Sent',
        TOTAL_WITHDRAWAL: 'Total Withdrawal',
      },
    },
  },
  REFERRALS: {
    TABS: {
      DIRECT: {
        UPGRADE_COMMISSION_RATE:"Upgrade Commission Rate",
        REFERRED_BY: 'Referred By',
        EARNING_FROM_REF:'This shows the total earnings from the Videos unlocked by your referrals.',
        TOTAL_VIDEOS:'This shows the total number of videos unlocked by all your referrals.',
        REGESTER_DATE: 'Registration Date',
        REF_BENIFIT_YOU: 'How Each Referral Benefits You',
        REF_BENIFIT_TEXT: '1. Each referral will unlock for you between 10 or more paid videos depending on the membership plan they purchase. Refer to membership plans for details.',
        REF_BENIFIT_TEXT1: '2. Each Referral will increase the number of paid videos you can watch each day, increasing your daily quota.',
        REF_BENIFIT_TEXT2: '3. Each referral will help you move up the several ranks from 1 Star General to 5 star General which in turn increases your referral cash bonus. eg a 2 star general gets a 2% referral bonus while a 5 star general gets a 5% referral bonus credited to their earnings.',
        TITLE: 'Direct Referrals',
        NO_DATA: 'No data related to direct referrals.',
        HEADERS: {
          USER_ID: 'UserId',
          BASICINFO:'Basic Info',
          USER_NAME: 'Username',
          MEMBERSHIP: 'Membership',
          STATUS: 'Status',
          JOIN_DATE: 'Join Date',
          MY_INCOME: 'My Income',
          LAST_ACTIVITY: 'Last activity',
          EXPIRE_DATE: 'Expiry Date',
          EMAIL: 'Email',
          TOTAL_EARNINGS: 'Downlines Total Earnings',
          FILTER_BY_PERIOD:'Filter by Period'
        }
      },
      PROMO: {
        TITLE: 'Promo Tools',
        DESCRIPTION: 'Ready-made promo-materials with your personal link. Choose the promo-material based on your client target.',
        TABS: {
          HOME: 'Home Page',
          REGISTER: 'Registration Page',
          BANNER: 'Banner Page',
        },
        INVITATION_LINK: 'My Unique Invitation Link',
        SITE_SLOGAN: 'Site slogan',
        INVITATION_CODE: 'HTML code',
        SHARE_LINKS: 'Share via',
        SUCCESS_COPIED: 'Successfully copied'
      },
      INDIRECT: 'Indirect referrals',
      RENTED: 'Rented referrals',
    },
  },
  REPRESENTATIVES: {
    COUNTRY_AGENT_TEXT:'Feel free to contact one of the agents in the list, they will assist you in making deposits, understanding how the platform works and assist you with working on PlayPayouts.',
    TABS: {
      APPROVED_COUNTRY_AGENTS: {
        REPRESENTATIVES:'representatives',
        TITLE: 'Authorised Airtime Distributors ',
        NO_DATA: 'No data related to direct representatives.',
        HEADERS: {
          COUNTRY_AGENT_DETAILS:'Country Agent Details',
          CONTACT_AGENT_FOR_VOUCHER:'Please contact the agent using the contact details below to buy a voucher code from them.',
          AGENT_NAME:'Agent Name',
          AGENT_EMAIL:'Agent Email',
          AGENT_WHATSAPP_LINK:'Agent Whatsapp',
          AGENT_TELEGRAM_LINK:'Agent Telegram',
          NAME: 'Name',
          BASICINFO: 'Basic Info',
          USER_NAME: 'Username',
          COUNTRY: 'Country',
          CITY: 'City',
          PHONE_NUMBER: 'Phone number',
          LAST_ACTIVITY: 'Last activity',
          LANGUAGES: 'Languages',
          WHATSAPP_LINK: 'Whatsapp',
          TELEGRAM_LINK: 'Telegram',
          EMAIL: 'Email',
          LOCATION: 'Location',
          SELECT: 'Select',
          VOUCHERS_CODE_BUTTON:'Voucher Code Button',
          BUY_VOUCHERS: 'Buy voucher'
        }
      },
      APPLICATION_FORM: {
        TITLE: 'Application form',
        DESCRIPTION: 'Please complete a form below to become a representative',
        FORM_SUBMITTED_TEXT: 'You already have an application pending approval. You cannot submit another one until the pending one is approved or rejected.',
        FORM_SUBMITTED: 'Application submitted successfully',
        REAPPLY:'Re-Apply',
        APPLICATION_REJECTED_EARLIER: 'Your application was rejected earlier',
        MINIMUM_REQUIREMENTS:'Ensure you first meet the minimum requirements before you re-apply',
        APPLICATION_REJECTED_SEVERAL:'Your applications have been rejected too many times. You cannot apply to be an agent.',
        CONTACT_ADMINISTRATOR:'Please contact your Administrator',
        AGENT_APPLICATION_HIS_BTN :'Agent Application History',
        HISTORY_FORM:{
          TITLE:'Agent Application History',
          USERNAME: 'Name',
          DATE:'Date',
          COUNTRY: 'Country',
          STATUS:'Status'
        },
        FORM: {
          NAME: 'Name',
          USERNAME: 'Username',
          EMAIL: 'Email',
          PHONE_NUMBER: 'Phone Number',
          TELEGRAM_LINK: 'Telegram Group Link',
          TELEGRAM_PLACEHOLDER:'https://web.telegram.org/a/',
          WHATSAPP_LINK: 'Whatsapp Group Link',
          WHATSAPP_PLACEHOLDER:'https://web.whatsapp.com/',
          COUNTRY: 'Country',
          CITY: 'City',
          LANGUAGE: 'Language',
          WHY_BECOME: 'Why you want to become a representative?',
          WHY_BECOME_PLACEHOLDER: 'Describe in a few words...',
        },
        RESPONSES: {
          SUCCESS: 'Form Submitted!',
          ERROR_0: 'Unknown',
          ERROR_1: 'Representatives Limit',
          ERROR_2: 'Application form is already submitted for this user!',
          ERROR_3: 'Email Is Used By Other Representative',
          ERROR_4: 'Representative policy is not set in app settings',
        }
      },
      MANAGE_CODES: {
        TITLE: 'Manage codes',
        HEADERS: {
          CODE: 'Code',
          SEND_TO_USER: 'Send to user',
          SEND_TO_USER_PLACEHOLDER: 'Enter Username',
          STATUS: 'Status',
          AMOUNT: 'Amount',
          CREATION_DATE: 'Creation date',
          VOCHER_SUCCESS: ' Airtime Code has been successfully sent to',
          THE: 'The',
          USEDBY : 'Usedby'
        },
        NO_DATA: 'No data related to codes.',
      },
      SALES_REPORT: {
        TITLE: 'Sales report',
        HEADERS: {
          CODE: 'Number of Voucher Code',
          USED_BY: 'Used by',
          COUNTRY: 'Country',
          COMMISSION: 'Commission',
          AMOUNT: 'Voucher Code Value',
          DATE: 'Date',
          SELECT: 'Select Date Range',
        },
        WIDGETS: {
          TOTAL_AMOUNT: 'Total Amount of Money',
          TOTAL_COMMISSION: 'Total Commissions',
        },
        NO_DATA: 'No data related to reports.',
      },
      BUY_VOUCHER: {
        TITLE: ' Buy Airtime Codes',
        TITLE_TEXT:'Buy Voucher',
        REQUIRED: 'Field is required',
        AMOUNT_CONTROL: 'Amount',
        CHOOSE_CONTROL: 'Choose Balance',
        BALANCE: 'Balance',
        DISCOUNT: 'Commission',
        REVIEW_AND_CONFIRM_MODAL: {
          TITLE: 'Review and Confirm',
        },
        SUCCESS: 'Voucher code has been successfully bought'
      },
    },
  },
  STATISTICS: {
    TABS: {
      MY_EARNING: {
        TITLE: 'My Earnings',
        EARNINGS: 'My Earnings',
        DAYS: 'Days',
        AMOUNT: 'Amount',
        TODAY: 'Today',
        VIDEO : 'Video Earnings',
      },
      MY_TEAM: {
        TITLE: 'My Team',
        EARNINGS_REFERRALS: 'Earnings From My Referrals',
        DAYS: 'Days',
        AMOUNT: 'Amount',
        TODAY: 'Today',
        REFERRALS_COUNT: 'My Daily Referrals Count',
      },
    },
  },
  CODE_CONFIRMATION_DIALOG: {
    SMS_CONFIRMATION_TITLE: 'Enter Code from SMS',
    EMAIL_CONFIRMATION_TITLE: 'Enter Code from Email',
    CODE_FIELD_LABEL: 'Code',
    SUBMIT_BUTTON: 'Submit',
    RESEND_SUBSCRIPTION: 'Haven’t received a code?',
    RESEND_BUTTON: 'Resend',
  },
  EMAIL_CONFIRMATION_DIALOG: {
    TITLE: 'Confirm your new email',
    DESCRIPTION: 'We have sent a confirmation code to email {{newEmail}}. Please enter that code below.'
  },
  PHONE_CONFIRMATION_DIALOG: {
    TITLE: 'Confirm your new phone number',
    DESCRIPTION: 'We have sent a verification code to phone number {{newPhone}}. Please enter that code below.'
  },
  RESET_PASSWORD: {
    BUTTON_BACK: 'Back',
    SIMPLE_FORM_TITLE: 'Reset Password',
    SIMPLE_FORM_TITLE_WITH_PIN: 'Reset Password and PIN',
    SIMPLE_FORM_DESCRIPTION: 'Please enter your email and we’ll send you a link to reset you password',
    SIMPLE_FORM_CONFIRM_BUTTON: 'Send',
    IMMEDIATELY_FORM_TITLE: 'Reset Password',
    IMMEDIATELY_FORM_DESCRIPTION: 'Please enter your username, email and PIN and we’ll send you a link to reset you password',
    IMMEDIATELY_FORM_CONFIRM_BUTTON: 'Reset Password',
    USERNAME_FIELD_LABEL: 'Username',
    EMAIL_FIELD_LABEL: 'Email',
    PIN_FIELD_LABEL: 'Pin',
  },
  SUCCESS_SOCIAL_PAGE: {
    TITLE: 'Almost Done!',
    DESCRIPTION: 'Please login with social account.',
    BACK_BUTTON: 'Back to Login',
  },
  SUCCESS_RESET_PAGE: {
    TITLE:  ' Registration Successful.',
    TITLE_DESCRIPTION:'We have sent a verification link to your email',
    TITLE_TEXT: 'You can now login using your credentials',
    DESCRIPTION: 'Check your email inbox. If you cant see the verification link in your inbox, check the spam folder',
    BACK_BUTTON: 'Back to Home',
  },
  SUCCESS_FORGOT_PAGE: {
    TITLE:  ' We have sent a reset password link to your email',
    DESCRIPTION: 'Check your email inbox. If you cant see the reset password link  in your inbox, check the spam folder',
    BACK_BUTTON: 'Back to Home',
  },
  SUCCESS_ACTIVATION_PAGE: {
    TITLE:  ' Activation Link Verified',
    DESCRIPTION: 'You can now login using your credentials',
    BACK_BUTTON: 'Back to Login',
  },
  FAILED_ACTIVATION_PAGE: {
    TITLE:  'Activation Link Verification Failed',
    BACK_BUTTON: 'Back to Home',
  },
  FAILED_RESET_PAGE: {
    TITLE:  'Registration Failed',
    DESCRIPTION : 'Please Try Again',
  },
  RESET_BY_LINK: {
    TITLE: 'Set New Password',
    PASS_FIELD_LABEL: 'New Password',
    PASS_CONFIRM_FIELD_LABEL: 'Confirm Password',
    PIN_FIELD_LABEL: 'New PIN',
    PIN_CONFIRM_FIELD_LABEL: 'Confirm PIN',
    SEND_BUTTON: 'Save',
    PASS_ERRORS: {
      REQUIRED: 'Field is required',
      MIN_LENGTH: 'Minimum length 8 characters',
      MAX_LENGTH: 'Maximum length 12 characters',
      PATTERN: 'Password must contain: only Latin letters are possible; min 1 uppercase letter; min 1 lowercase letter; min 1 number',
      CONFIRM: 'Password mismatch',
    },
    PIN_ERRORS: {
      REQUIRED: 'Field is required',
      LENGTH: 'Pin length 4 characters',
      PATTERN: 'Only numbers available',
      CONFIRM: 'PIN mismatch',
    },
  },
  LOGIN_BY_LINK: {
    TITLE: 'Great! You’ve confirmed you email',
    DESCRIPTION: 'Please enter your credentials to login into the system.',
    USERNAME_FIELD_LABEL: 'Username',
    PASSWORD_FIELD_LABEL: 'Password',
    FORGOT_PASSWORD: 'Forgot Password?',
    SEND_BUTTON: 'Login',
  },
  PHONE_NUMBER_WINDOW: {
    TITLE: 'Enter your phone number',
    PHONE_FIELD_LABEL: 'Phone Number',
    SEND_BUTTON: 'Save',
  },
  PHONE_NUMBER_CONFIRM_WINDOW: {
    TITLE: 'Please confirm your phone number',
    DESCRIPTION: 'We’ve sent you a code to the number',
    CODE_FIELD_LABEL: 'Code',
    SEND_BUTTON: 'Proceed',
    RESEND_INFO: 'Haven’t received a code?',
    RESEND_BUTTON: 'Resend',
    // RESEND_SUCCESS_MESSAGE: 'Code resend success',
    // RESEND_FAILED_MESSAGE: 'Code resend failed',
  },
  TWO_FACTOR_AUTHENTICATION:{
    TITLE:'Please enter your 2FA authentication code'
  },
  EMAIL_CONFORM_WINDOW:{
    TITLE: ' Please confirm your email',
    DESCRIPTION: 'We’ve sent you a code to the number',
  },
  FAILED_LOGIN: {
    TITLE: 'Can’t login to the system',
    DESCRIPTION: 'Oops, something went wrong',
    SEND_BUTTON: 'Back to Login',
  },
  CREATE_PIN_DIALOG: {
    TITLE: 'Please setup your PIN code',
    DESCRIPTION: 'The PIN code will be used to confirm your identity during security-sensitive operations',
    PIN_FIELD_LABEL: 'PIN',
    PIN_FIELD_PLACEHOLDER: 'Enter PIN',
    REPEAT_PIN_FIELD_LABEL: 'Confirm PIN',
    REPEAT_PIN_FIELD_PLACEHOLDER: 'Confirm PIN',
    USERNAME_FIELD_LABEL: 'Please enter your user name.',
    CONFIRM_BUTTON: 'Create ',
    USER_ALREADY_EXIST: 'User Name already exists!',
    ENTER_USERNAME: 'Please enter username!',
  },
  COOKIE_NOTIFICATION: {
    TITLE: 'We use cookies',
    MESSAGE: 'By using our website you agree to use cookies. You may visit “Cookie Settings” of your browser to provide controlled consent.',
    SEND_BUTTON: 'Got it',
  },
  ERRORS: {
    ERROR_TYPE_1: { //Confirmation
      ERROR_MSG_0: 'Something went wrong while checking confirmation.',
      ERROR_MSG_1: 'Invalid Security Code.',
      ERROR_MSG_2: 'Error related to validating code occured.',
      ERROR_MSG_3: 'Error while sending the code.',
      ERROR_MSG_4: 'Error related to activating user.',
      ERROR_MSG_5: 'Error related to wrong account status.',
      ERROR_MSG_6: 'Too many attempts for validation code.',
      ERROR_MSG_7: 'Code is invalid.',
      ERROR_MSG_8: 'Code send policy is not supported.',
      ERROR_MSG_9: 'User does not provide the phone.',
      ERROR_MSG_10: 'Verification code send limit.',
      ERROR_MSG_11: 'Wait for new code.',
    },
    ERROR_TYPE_2: { //Login
      ERROR_MSG_0: 'Unknown.',
      ERROR_MSG_1: 'Incorrect password and login combination.',
      ERROR_MSG_2: 'Account is not activated.',
      ERROR_MSG_3: 'Account is canceled.',
      ERROR_MSG_4: 'Account is locked.',
      ERROR_MSG_5: 'Account has expired and cannot be reactivated.',
      ERROR_MSG_6: 'AccountHasExpiredAndCanBeReactivated.',
      ERROR_MSG_7: 'PhoneHadBeenAlreadyLinkedToAccount.',
      ERROR_MSG_8: 'ThisIpIsForbidden.',
      ERROR_MSG_9: 'ThisCountryIsForbidden.',
      ERROR_MSG_10: 'Account has been suspended due to terms of service violation.',
      ERROR_MSG_11: 'Account has been suspended due to cheating.',
      ERROR_MSG_12: 'Account has been blacklisted.',
      ERROR_MSG_13: 'Incorrect email and pin combination.',
      ERROR_MSG_14: 'Incorrect login and pin combination.',
      ERROR_MSG_15: 'Facebook login failed.',
      ERROR_MSG_16: 'Google login failed.',
      ERROR_MSG_17: 'Failed sending reset password link.',
      ERROR_MSG_18: 'Code from email is empty.',
      ERROR_MSG_19: 'Password is empty.',
      ERROR_MSG_20: 'Request expired.',
      ERROR_MSG_21: 'Failed set new password.',
      ERROR_MSG_22: 'Email is invalid.',
      ERROR_MSG_23: 'Email has not been found.',
      ERROR_MSG_24: 'Failed getting new password.',
    },
    ERROR_TYPE_3: { //SignUp
      ERROR_MSG_0: 'Unknown.',
      ERROR_MSG_1: 'Invalid email address.',
      ERROR_MSG_2: 'Username is taken.',
      ERROR_MSG_3: 'Username is forbidden.',
      ERROR_MSG_4: 'Email is taken.',
      ERROR_MSG_5: 'Ip address is taken.',
      ERROR_MSG_6: 'Ip addressis  blacklisted.',
      ERROR_MSG_7: 'Ip address is proxy.',
      ERROR_MSG_8: 'Country is blacklisted.',
      ERROR_MSG_9: 'Phone country code and phonenumber should not be null.',
      ERROR_MSG_10: 'Phone number is taken.',
      ERROR_MSG_11: 'Unable to send activation email.',
      ERROR_MSG_12: 'Pin is incorrect.',
      ERROR_MSG_13: 'Detailed info is required.',
    },
    ERROR_TYPE_4: { //Payment
      ERROR_MSG_0: 'Unknown.',
      ERROR_MSG_1: 'Amount cannot be lower or equal to zero.',
      ERROR_MSG_2: 'This transfer is not allowed.',
      ERROR_MSG_3: 'Any transfer between users is forbidden.',
      ERROR_MSG_4: 'Transfer to country representative is not allowed.',
      ERROR_MSG_5: 'Transfer to self is not allowed.',
      ERROR_MSG_6: 'Not enough founds.',
      ERROR_MSG_7: 'Cannot transfer, that amount is blocked in your account.',
      ERROR_MSG_8: 'You must select your target account.',
      ERROR_MSG_9: 'You cannot transfer from traffic balance.',
      ERROR_MSG_10: 'Reciever is not found.',
      ERROR_MSG_11: 'User cannot recieve founds.',
      ERROR_MSG_12: 'You cannot send any founds.',
      ERROR_MSG_13: 'You need to set up your account first.',
      ERROR_MSG_14: 'Amount is lower than minimum.',
      ERROR_MSG_15: 'No specified gateway has been installed.',
      ERROR_MSG_16: 'Unable to execute operation.',
      ERROR_MSG_17: 'Payout cannot be processed.',
      ERROR_MSG_18: 'Incorrect paymen details.',
      ERROR_MSG_19: 'Cannot withdraw due to recently changed account id.',
      ERROR_MSG_20: 'Cannot use diferrent account id.',
      ERROR_MSG_21: 'Fill in your personal details before payout.',
      ERROR_MSG_22: 'You cannot exceed max daily payout limit.',
      ERROR_MSG_23: 'You have reached your global payout limit.',
      ERROR_MSG_24: 'You cannot exceed max weekly payout limit.',
      ERROR_MSG_25: 'Your account is in vacation mode.',
      ERROR_MSG_26: 'You need to complete document verification.',
      ERROR_MSG_27: 'You cannot exceed max daily payout times limit.',
      ERROR_MSG_28: 'Withdrawal is forbidden at least one of your balances is negative.',
      ERROR_MSG_29: 'You must repay your credit line debt before withdraw.',
      ERROR_MSG_30: 'Minimum time between withdrawals exceeded.',
      ERROR_MSG_31: 'You need more referrals to be allowed to make payout.',
      ERROR_MSG_32: 'Payout is unsuccessful.',
      ERROR_MSG_33: 'Account field cannot be left blank.',
      ERROR_MSG_34: 'No gateway available at the moment.',
      ERROR_MSG_35: 'Todays limit for withdrawals reached try again tomorrow.',
      ERROR_MSG_36: 'Amount must be at least your payout limit.',
      ERROR_MSG_37: 'Payout request is too high.',
    },
    ERROR_TYPE_5: { //Representative
      ERROR_MSG_0: 'Unknown.',
      ERROR_MSG_1: 'Representatives limit per country.',
      ERROR_MSG_2: 'Is already representative.',
      ERROR_MSG_3: 'Email is used by other representative.',
      ERROR_MSG_4: 'Representative policy is not set.',
    },
    ERROR_TYPE_6: { //Settings
      ERROR_MSG_0: 'Unknown.',
      ERROR_MSG_1: 'Email already exists.',
      ERROR_MSG_2: 'Phone number is taken.',
      ERROR_MSG_3: 'Empty input.',
      ERROR_MSG_4: 'Password is incorrect.',
      ERROR_MSG_5: 'Payment address already exists.',
      ERROR_MSG_6: 'Failed update detailed info settings.',
      ERROR_MSG_7: 'Failed update email address.',
      ERROR_MSG_8: 'Failed update phone number.',
      ERROR_MSG_9: 'Failed update payout addresses.',
      ERROR_MSG_10: 'Failed update preferences settings.',
      ERROR_MSG_11: 'Failed update Google 2FA settings.',
      ERROR_MSG_12: 'Failed update PIN.',
      ERROR_MSG_13: 'Failed update password.',
    },
    ERROR_TYPE_7: { //MyTeam
      ERROR_MSG_0: 'Unknown.',
      ERROR_MSG_1: 'User cannot use ref tools.',
      ERROR_MSG_2: 'Site url is not valid.',
    },
    ERROR_TYPE_8: { //VoucherCode
      ERROR_MSG_0: 'Unknown.',
      ERROR_MSG_1: 'Voucher code does not exist.',
      ERROR_MSG_2: 'Transfer code failed due to username or code.',
      ERROR_MSG_3: 'User does not own code.',
      ERROR_MSG_4: 'Code is expired.',
      ERROR_MSG_5: 'Representative could not redeem.',
      ERROR_MSG_6: 'Code is inactive.',
      ERROR_MSG_7: 'User already used code.',
      ERROR_MSG_8: 'Target balance is not supported.',
      ERROR_MSG_9: 'Feature is disabled.',
      ERROR_MSG_10: 'Error related to buying.',
      ERROR_MSG_11: 'Amount of voucher code is too low.',
      ERROR_MSG_12: '{{value}} user is already a representative.'
    },
    ERROR_TYPE_9: { //EnumHelper
      ERROR_MSG_0: 'Unknown.',
      ERROR_MSG_1: 'TEnum must be an enum.',
      ERROR_MSG_2: 'Not found enum.',
      ERROR_MSG_3: 'Value is not defined.',
      ERROR_MSG_4: 'Value is not valid.',
    },
    ERROR_TYPE_10: { //Membership
      ERROR_MSG_0: 'Unknown.',
      ERROR_MSG_1: 'Error is related to upgrading membership.',
      ERROR_MSG_2: 'Membership does not exist.',
      ERROR_MSG_3: 'Target balance is not supported for membership.',
      ERROR_MSG_4: 'Downgrading Membership is not allowed'
    },
    ERROR_TYPE_11: { //Validation
      ERROR_MSG_0: 'Something went wrong while validating pin.',
      ERROR_MSG_1: 'Pin is invalid.',
    },
    ERROR_TYPE_12: { //PurchaseOption
      ERROR_MSG_0: 'Unknown.',
      ERROR_MSG_1: 'Chosen balance is invalid.',
      ERROR_MSG_2: 'Not enough funds.',
      ERROR_MSG_3: 'Invalid wallet code.',
      ERROR_MSG_4: 'No funds in wallet.',
      ERROR_MSG_5: 'Invalid amount.',
      ERROR_MSG_6: 'Wallet is not active.',
    },
    ERROR_TYPE_13: { //Currency
      ERROR_MSG_0: 'Unknown.',
      ERROR_MSG_1: 'Currency invalid or not supported.',
    },
    ERROR_TYPE_14: { //Email
      ERROR_MSG_0: 'Unknown.',
      ERROR_MSG_1: 'Errors ending email.',
      ERROR_MSG_2: 'Admin email is not setup correctly.',
    },
    ERROR_TYPE_15: { //Youtube
      ERROR_MSG_0: 'Unknown.',
      ERROR_MSG_1: 'Advert pack does not have valid end mode.',
      ERROR_MSG_2: 'Link id is null.',
      ERROR_MSG_3: 'Advert is not found.',
      ERROR_MSG_4: 'Failed crediting user.',
      ERROR_MSG_5: 'Failed creating new advert.',
      ERROR_MSG_6: 'Advert has not allowed status.',
      ERROR_MSG_7: 'User does not own advert.',
      ERROR_MSG_8: 'Link id is broken.',
    },
    ERROR_TYPE_16: { //Jwt
      ERROR_MSG_0: 'Unknown.',
      ERROR_MSG_1: 'Provide both access and refresh tokens.',
      ERROR_MSG_2: 'Access and refresh tokens does not match.',
      ERROR_MSG_3: 'Failed refresh token.',
      ERROR_MSG_4: 'Failed decode access token.',
      ERROR_MSG_5: 'Failed to revoke refresh token.',
    },
    ERROR_TYPE_17: { //PreLogin
      ERROR_MSG_0: 'Unknown.',
      ERROR_MSG_1: 'Phone number is taken.',
      ERROR_MSG_2: 'Empty input.',
    },
  },
  RADIO_PLAY: {
    STREAM_PLAY_ERROR: 'The radio station is currently unavailable.'
  },
  COUNTRY_BLOCK:{
    TEXT:'We’re sorry',
    COUNTRY_TEXT:'We do not provide any services to users in your country at this moment',
    HOME : ' Back to Home'
  },
  PAYMENT_PROCESS:{
    PAYPAL: 'Paypal',
    PERFECT_MONEY: 'Enter your perfect money account number',
    PAYEER: 'Enter your payeer account number',
    COINBASE: 'CoinBase',
    COIN_PAYMENTS: 'Enter your bitcoin address'
  },
  Dashboard:'Dashboard',
  Membership:'Membership',
  Rate_Earn: 'Earn',
  Referrals:'Referrals',
  Finance:'Finance',
  Representatives:'Representatives',
  Statistics:'Statistics',
  Top_members:'My Reports',
  Settings:'Settings',
  statistics:'statistics',

  DOWNLOAD_EARNING_GUIDE:'Download Earning Guide',
  DOWNLOAD_COUNTRY_AGENT_GUIDE:'Download Country Agent Handbook',

  HAND_BOOK:'Download Our Earning Handbook',
  Insta_Likes : 'Insta Likes',
  Insta_Follows : 'Insta Follows',
  Insta_Views:'Insta Views',
  Insta_Reels_Views: 'Insta Reels Views',
  Tiktok_Followers : 'Tiktok Followers',
  Tiktok_Likes:'Tiktok Likes',
  Tiktok_Views :'Tiktok Views',
  YT_Subscribers : 'YT Subscribers',
  YT_Likes : 'YT Likes',
  YT_Watch_Hours : 'YT Watch Hours',
  Forum_Reviews : 'Forum Reviews',
  Join_A_Community : 'Join A Community',
  App_Reviews : 'App Reviews',
  Game_Reviews : 'Game Reviews',
  Web_Reviews : 'Web Reviews',
  user:'user',
  Subscription_Plans:'Compare Plans',
  Income_Calculator:'Income Calculator',
  Radio:'Radio',
  Youtube:'Play and Earn',
  Movie:'Movie',
  Direct_referrals:'Direct Referrals',
  Indirect_referrals:'Indirect referrals',
  Deposit:'Deposit',
  Send:'Send',
  Withdraw:'Withdraw',
  Vouchers:'Deposit Vouchers',
  Extra_Minutes:'Extra Minutes',
  Logs:'Logs',
  Country_Agent:'Country Agent List',
  Manage_Code:'Manage Code',
  Sales_Report:'Rep Sales Report',
  Buy_Airtime_Codes:'Buy Voucher',
  Buy_Voucher_Codes:'Buy Voucher',
  SPENDING_WALLET_BALANCE: 'Spending Wallet Balance',
  Application_Form:'Agent Application Form',
  User_Info:'User Profile',
  My_Profile:' My Profile',
  Activity_Logs:'Activity Logs',
  Payment_Proofs:'Payment Proofs',
  Promo_Tools:'Promo Tools',
  Booster_Account:'Booster Account',
  Tutorials:'Tutorials',
  Unlocked_Video_Logs: 'Unlocked Video Logs',
  Referral_Calculator:'Referral Calculator',
  Referral_Competition : 'Referral Competition',
  Leaderboard: 'Leaderboard',
  Top_10_Today_Earners : 'Top 10 Today Earners',
  Top_10_Last_7_Days_Earners : 'Top 10 Last 7 Days Earners',
  Top_10_Referrers_Today : 'Top 10 Referrers Today',
  Top_10_Last_7_Days_Referrers : 'Top 10 Last 7 Days Referrers',
  EXTRA_LABELS:{
     VIEWS:'Unlocked Videos',
     ADDING_MONTH:'Total Withdrawn',
     YOUTUBE_DETAILS:'CashClips For You To Watch',
     MOVIE_LIST:'Movie List',
     MOVIE_DETAILS: 'Movie Details',
     PAID_PLANS:'Referrals On Paid Plan',
     BASICINFO:'Basic Info',
     VIDEOS_UNLOCKED: 'Upgrade Commission',
     TRANSACTIONS_HISTORY: 'Transactions History',
     TOTAL_RSFERRAL_DEPOSIT_BONUS:'Paid Videos Remaining',
     TOTAL_DEPOSITS:'Total Deposits',
     CLOSE:'Close',
     PAYMENT_PROOFS_URL_NAME:'Upload/View Payment Proofs From Users',
     TUTORIAL_LIST:'Tutorial List',
     TOTAL_REWARDS : 'Total Paid Videos In Your Account',
     TOTAL_INCOME : 'TOTAL INCOME',
     TOTAL_REVENUE : 'TOTAL REVENUE',
     EARNING : 'Earnings Chart',
     UNLOCK_VIDEO: 'Unlock Your Hidden Gem!',
     ONLINE_USER: 'Online Users',
     BOOST_EARNING:'Ready to boost your earnings? Unlock more videos by referring friends!',
     CHECK_OUT:'Check out the ',
     MEM_PLAN_DETAIL:'Membership plan details',
     EACH_REF:'to see how much you earn per referral on each plan.'
  },
  REF:{
    WINNER_TITLE:'Winners Details',
    WINNER_BTN : 'Winner History',
    WINNER_LIST:'Competition History',
    BTN_BACK:'Back',
    WINNERS_TABLE:{
        NAME:'Name',
        START_DATE: 'Start Date',
        END_DATE : 'End Date',
        WELCOME_BTN : 'Winners List'
    },
    TOTAL_EARNINGS:'Total Earnings',
    REFS_MEMBERSHIP_SELECTOR_LABEL: 'Referrals Membership Plan',
    REFERRAL_SELECTOR_LABEL:'Referral Membership',
    REFERRAL_CALCULATOR_TITLE:'Referral Calculator',
    ADDITION_EVERY_UPGRADE:'Additionally Every Upgrade Bonus',
    TOTAL_TITLE: 'TOTAL',
    REF_COUNT : 'Referral Count',
    INCOMECARD:{
      DAILY: 'Daily',
      WEEKLY: 'Weekly',
      MONTHLY: 'Monthly'
    },
    COMPETITTIONS:{
      MAIN_TITLE : 'Leaderboard',
      TITLE:'Referral Competition Leaderboard',
      RULES:'Rules',
      ACTIVE_REF:'Upgraded Referrals (This week)',
      TOTAL_POINTS:'Total Points',
      POINTS_TITLE : 'You are ranked {{value}} in this weeks competition.',
      POINTS_TITLE_ERROR : "You currently have 0 points. You need to have at least 1 upgraded referral this week to earn points & get join the competition.",
      TIMER_TITLE : 'Winner will be automatically rewarded in',
      DAYS:'Days',
      HOURS:'Hours',
      MINUTES : 'Minutes',
      SECONDS :'Seconds',
     TABLE:{
      USER_NAME : 'User Name',
      ACTIVE_REF : 'Upgraded Referrals (This week)',
      TOTAL_POINT : 'Total Point',
      PRIZE : 'Prize',
      COMPETITION_START_DATE : 'Competition Start Date',
      COMPETITION_END_DATE : 'Competition End Date'
     }
    }
  },
  SCANNER:{
    TOP_TITLE: 'Please send {{value}}',
    TITLE1:'or more (equivalent of {{value}})',
    TITLE2:'to the {{value}} address below',
    INFO_MESSAGE: 'Once you have sent the funds, Please wait for a few minutes for the amount. To reflect in your Spending balance.',
    COPY_BTN:'Copy'
  },
  MY_RANK:{
    LEVEL :'Level',
    LEVEL0:'Level 0',
    LEVEL1:'Level 1',
    LEVEL2:'Level 2',
    LEVEL3:'Level 3',
    BRONZE_PARTNER : 'Bronze Partner',
    SILVER_PARTNER : 'Silver Partner',
    GOLD_PARTNER   : 'Gold Partner',
    PLATINUM_PARTNER : 'Platinum Partner',
    LEARN_MORE_LINK : 'Click here to learn more',
    USER_RANK_COM_TIERS_LEVEL_TITLE : 'User Ranks and Commission Tiers',
    USER_RANK_COM_TIERS_LEVEL0 : 'Users with no upgraded referrals will receive a 1% commission on referral deposits.',
    USER_RANK_COM_TIERS_LEVEL1 : 'Users with 1 to 10 upgraded referrals will receive a higher 5% commission on deposits.',
    USER_RANK_COM_TIERS_LEVEL2 : 'Users with 10 to 30 upgraded referrals will enjoy an even more attractive 8% commission on deposits.',
    USER_RANK_COM_TIERS_LEVEL3 : 'For the elite users with over 31 upgraded referrals, a top-tier 10% commission on deposits will be granted.',
    MOVIVATION_TITLE : 'Congratulations!',
    MOVIVATION_TITLE3 : '{{value}} commission on referral deposits.',
    MOVIVATION_TITLE1 : 'fantastic {{value}} commission on every referral deposit.',
    MOTIVATION_TITLES : 'You are currently on {{value}} & earning a',
    MOVIVATION_TITLE2 : 'Keep up the great work! You need just {{value}} more',
    MOTIVATION_TITLES2 : 'referrals to reach {{value}} & start earning'
  },
  LEADER_BOARD :{
    EARNERS_LAST_SEVEN_DAYS : 'Top 10 : Earners Last 7 Days Leaderboard',
    EARNERS_ONE_DAYS : 'Top 10 : Today Earners Leaderboard',
    REFERRERS_LAST_SEVEN_DAYS : 'Top 10 : Referrers Last 7 Days Leaderboard',
    REFERRERS_ONE_DAYS : 'Top 10 : Today Referrers Leaderboard',
    RANK : 'Rank',
    USER_NAME : 'User Name',
    MEMBERSHIP : 'Membership',
    EARNING_AMOUNT : 'Earning Amount',
    TOTAL_REF : 'Total Refferral',
    TOTAL_UPGRADE_REF : 'Total Upgraded Referrals'
  },
  NEW_DASHBOARD : {
    UNLOCKED_VIDEOS : 'You have unlocked {{value}} videos',
    VIDEO_SOON : 'Instantly Earn Upto 10% Referral Upgrade Commissions  ',
    COINS : '<b>{{value}}</b> Videos',
    WATCHED_TOTAL:'<b>{{value}}</b> watched of ',
    WATCHED_TOTAL2:'<b>{{value}}</b> Paid Videos Per year',
    UNLOCK_HIDDEN: 'Unlock Your Hidden Gem!',
    UNLOCK_HID_TEXT: 'Ready to boost your earnings? Earn Upto 10% commission when your referrals upgrade to any plan!',
    REF_UNLOCK_PAID_VID_BTN: 'Refer Now to Unlock More Paid Videos!',
    INFO_MSG_VIDEO: 'These are the total number of videos remaining to watch in your account. Both Ready-To-Watch + Unlock-To-Watch. Once you finish these you can renew the same subscription plan or go to a higher plan with higher allocation.',
    POTENTIAL_EARNINGS_POPUP: 'This is the Total amount to be earned after you have unlocked & watched all the Paid videos in your current subscription plan. Refer More Friends to unlock for you more paid videos & maximize your earnings in the shortest time.',
    UNLOCK_VID_POPUP: 'These are the Paid Videos That have already been unlocked. To unlock more videos you will need to refer a friend. Each referral unlocks 10 to 60 videos or more for you depending on the membership plan your referral purchases.',
    TOTAL_EAR : 'Total Amount you have Earned up to Today',
    TOTAL_WITHDRAE:'Total amount you have withdrawn up to Today.',
    SHOW_REMAINING:'You still have <b>{{value}} Team Tasks Remaining To Be Unlocked</b>.'
  }
};