import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  private basePath = 'assets/images/icons';
  private baseTypePrefix = '.svg';

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  public initSvgIcons(): void {
    this.iconRegistry.addSvgIconResolver((iconName: string, namespace: string) => {
      const iconUrl = namespace
        ? `${this.basePath}/${namespace}/${iconName}${this.baseTypePrefix}`
        : `${this.basePath}/${iconName}${this.baseTypePrefix}`;

      return this.sanitizer.bypassSecurityTrustResourceUrl(iconUrl);
    });
  }
}
