import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { IHeaderMenuItem } from '@interfaces/header.interface';

@Component({
  selector: 'skd-menu-selector',
  templateUrl: './menu-selector.component.html',
  styleUrls: ['./menu-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSelectorComponent {
  @Input() public menuList: IHeaderMenuItem[];
  @Input() public disableRipple = false;
  @Input() public overlapTrigger = false;

  @Output() menuPoint = new EventEmitter<string>();

  public icon = 'keyboard_arrow_down';
  public arrow = {
    up: 'up',
    down: 'down',
  };

  public onMenuClick(point: string): void {
    this.menuPoint.emit(point);
  }

  public isMenuOpen(type): void {
    this.icon = `keyboard_arrow_${type}`;
  }
}
