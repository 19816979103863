<header *ngIf="userDetails$ | async as userDetails" class="header">
  <!-- [ngClass]="userDetails?.isAccountExpired ? 'expried-enabled' : 'expried-disabled'" -->
  <!-- <header class="header"> -->
    <nav class="header-nav d-none">
      <a [routerLink]="updatePlanUrl" class="header-nav-link">{{ translations.HEADER.BOOST_EARNINGS | translate }}
        <img src="../../../../assets/images/updated/memership-icon.png">
      </a>
    </nav>
  
  <div class="header__plan d-none">
    <!-- <span class="header__plan_icon" [innerHTML]="icons.userSvg | safe"></span> -->
    <span class="header__plan_icon"><i class="fe fe-bookmark"></i></span>
    <span class="header__plan_text">{{ userDetails?.membershipName }}</span>
    <!-- <span class="header__plan_text">TRIAL PLAN </span> -->
  </div>
<!-- <p>{{userDetails?.isAccountExpired}}</p> -->
  <div *ngIf="userDetails?.isAccountExpired" class="expried-text d-none">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    Account Expired
  </div>

  <div class="header__balance">
    <ul class="d-flex m-0 p-0 top-menu">
      <!-- <li  class="mr-3">
        <a (click)="page_sidebar_init()" id="collapsed-sidebar-toggle-button" href="javascript:void(0)"><i class="fe fe-home"></i></a>
      </li> -->
      <li class="mr-3">
        <a href="{{valName}}" ><i class="fe fe-home"></i>{{ translations.HEADER.HOME| translate }}</a>
      </li>
      <li *ngIf="content?.topMenuSupportEnabled">
        <a [href]="support" target="_blank"><i class="fe fe-headphones"></i>{{ translations.HEADER.SUPPORT | translate }}</a>
      </li>
    </ul>
    <!-- <span class="header__balance_icon" [innerHTML]="icons.balanceSvg | safe"></span> -->
    <!-- <span class="header__plan_icon"><i class="fe fe-bar-chart-2"></i></span> -->
    <!-- <span class="header__balance_sum">{{ userDetails?.moneyBalance | skdCurrency }}</span> -->
    <!-- <span>&nbsp;/&nbsp;</span> -->
    <!-- <span class="header__balance_sum">{{ userDetails?.purchaseBalance }}</span> -->
    <!-- <span class="header__balance_sum">0</span> -->
  </div>

  <div class="header-select">
    <!-- <skd-selector [options]="currencyOptions" [option]="selectedCurrencyOption$ | async"
      (selectOption)="onCurrencySelect($event)">
    </skd-selector>   -->
    <skd-select-currency class="lang-dropdown lang-dropdown-ch" [currencyData]="currenyCodeData" [currency]="selectedCurrencyOption$ | async" ></skd-select-currency>             
  </div>

  
  <div class="header-select">
    <!-- <skd-selector [options]="languages" [option]="language" (selectOption)="onLanguageSelect($event)">
    </skd-selector> -->
    <skd-select-option class="lang-dropdown lang-dropdown-ch" [language]="languages" [setLanguage]="language" ></skd-select-option>  
  </div>

  <div class="header-profile d-flex">
    <div class="dropdown notification-block my-auto mr-2">
      <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">
      <span><i class="fe fe-bell"></i></span>
      <span class="badge-danger" *ngIf="notificationCount > 0">{{notificationCount}}</span>
    </button>
      <div class="dropdown-menu p-0 m-0">
       <!-- <p *ngFor="let item of activityData | slice:0:1">{{item.text}}</p> -->
        <div class="notification-header-block">
          <h3>{{ translations.HEADER.NOTIFICATIONS| translate }} <span routerLink="/user/activity-logs" ><i class="fe fe-chevron-right"></i></span></h3>
          <div class="notification-scroll">
            <div class="notification-list" *ngFor="let item of activityData">
              <div class="notify-img">
                   <p class="m-0">{{item.text.charAt(0)}}</p>
              </div>
              <div class="notify-info">
                <p class="line-dot" routerLink="/user/activity-logs">
                 {{item.text}}
                </p>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>
    <div class="header-name mt-auto mb-auto d-none">
      <span>{{ userDetails?.userName }}</span>
    </div>
  
    <div class="header-menu mt-auto mb-auto">
      <div class="header-menu-avatar" *ngIf="myProfileImage !== undefined">
        <img class="header-menu-avatar"src="{{profileUrl}}{{myProfileImage}}">
      </div>
  
      <div class="header-menu-avatar mt-auto mb-auto" *ngIf="myProfileImage == undefined">
        <img class="header-menu-avatar"src="../../../../assets/images/updated/profile_image.jpeg">
      </div>
    </div>
  
    <div class="header-menu-button">
      <skd-menu-selector [menuList]="headerMenuList$ | async" [disableRipple]="true" (menuPoint)="onMenuClick($event)">
      </skd-menu-selector>
    </div>
  </div>
  
</header>
<skd-loading-spinner *ngIf="loadingCheck"></skd-loading-spinner>
