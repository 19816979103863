import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToasterDialogComponent } from './toaster-dialog/toaster-dialog.component';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [ToasterDialogComponent],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports:[
    CommonModule,
    ToasterDialogComponent,
    TranslateModule
  ]
})
export class DynamicTranslationModule { }
