import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { LoadingService } from './loading.service';

@Component({
  selector: 'skd-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent implements OnInit, OnDestroy {
  @Input()
  public name: string;

  @Input()
  public relatedElement: any;

  public isVisible = false;

  constructor(private readonly loadingService: LoadingService,
    private readonly renderer: Renderer2,
    private cdr: ChangeDetectorRef) { }

  public ngOnInit(): void {
    if (this.name) {
      this.loadingService.registerInstance(this.name, this);
    }
  }

  public ngOnDestroy(): void {
    if (this.name) {
      this.loadingService.removeInstance(this.name, this);
    }
  }

  public show(): void {
    this.isVisible = true;
    this.renderer?.setStyle(this.relatedElement, `filter`, `blur(8px)`);
    this.cdr.detectChanges();
  }

  public hide(): void {
    this.isVisible = false;
    this.renderer?.removeStyle(this.relatedElement, `filter`);
    this.cdr.detectChanges();
  }
}
