import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'skd-loading-spinner',
  templateUrl: './loadingspinner.component.html',
  styleUrls: ['./loadingspinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {
  public ngOnInit(): void {
   
  }
}
