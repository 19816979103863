import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CurrencyService } from '@services/currency.service';
import { LocalStorageService } from '@services/local-storage.service';
import { ToasterService } from '@services/toaster.service';
import { Subject } from 'rxjs';
import { ToasterDialogComponent } from './toaster-dialog/toaster-dialog.component';

const OPTIONS = {
  headers: new HttpHeaders({
    'Ocp-Apim-Subscription-Key': '20127c654eb54567a6a85c51820840a6',
    'Ocp-Apim-Subscription-Region': 'westeurope'
  })
};
@Injectable({
  providedIn: 'root'
})
export class DynamicTranslatonService {
  activeSub = new Subject();
  returnVal: any;
  value
  constructor(public http: HttpClient, public currency: CurrencyService, public toaster: ToasterService, private localStorageService: LocalStorageService, private readonly dialog: MatDialog) { }
  dynamicTranslationService(data: any) {
    this.localStorageService.setLocalStorageItem('unicId', true);
    const arr = []

    const obj = {}

    if (typeof data === 'object') {
      obj['Text'] = data.text;
    }else{
      obj['Text'] = data;
    }
  

    arr.push(obj)

    const languageVal = this.currency.getLanguage() ? this.currency.getLanguage() : 'en';

   

    this.http.post(`https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&to=${languageVal}`, arr, OPTIONS).subscribe({
      next: (data: any) => {
        this.localStorageService.removeLocalStorageItem('unicId');
        const returnVal = data[0].translations[0];
        this.returnVal = returnVal.text;
        // this.returnVal.length > 0 ? this.toaster.success(this.returnVal) : this.toaster.success('No Message')
       let val =  this.returnVal.length > 0 ? this.returnVal : this.toaster.success('No Message')
        if(val.length > 0) this.showingPopupMessage(val)
       }, error: (err) => {
        this.localStorageService.removeLocalStorageItem('unicId');
         this.value = arr[0].Text
      let val =  this.value?.length > 0 ? this.value : '';
      if(val.length > 0) this.showingPopupMessage(val)
      }
    })
    this.localStorageService.removeLocalStorageItem('unicId');
  }

  showingPopupMessage(val){
    return this.dialog.open(ToasterDialogComponent,{
      disableClose: true,
      data:val
    }).afterClosed();
  }

}