export const SOUND_MATE_PATH = 'https://frontend.soundmate.app/';
// export const SOUND_MATE_DEV_PATH = 'https://radiocoins-backend.azurewebsites.net/';
// base url
export const SOUND_MATE_DEV_PATH = 'https://api.playpayouts.com'
export const SOUND_MATE_STAGE_PATH = 'https://frontend-stage.soundmate.app/';
// export const FRONT_END_API_PROD_PATH = 'https://radiocoins-backend.azurewebites.net/';
// export const FRONT_END_API_PROD_PATH = 'https://skdapi.skadasoft.com';
export const FRONT_END_API_PROD_PATH = 'https://api.playpayouts.com';
// export const FRONT_END_MOVIE_POSTER_IMAGE = 'https://skdapi.skadasoft.com/StaticFiles/Movies/Poster';
export const FRONT_END_MOVIE_POSTER_IMAGE = 'https://api.playpayouts.com/StaticFiles/Movies/Poster';
// export const FRONT_END_MOVIE_CASTANDCREW_IMAGE = 'https://skdapi.skadasoft.com/StaticFiles/Movies/CastAndCrew';
export const FRONT_END_MOVIE_CASTANDCREW_IMAGE = 'https://api.playpayouts.com/StaticFiles/Movies/CastAndCrew';
export const RADIO_STREAMING_PATH = 'https://radiostreaming.soundmate.app/';
export const RADIO_STREAMING_DEV_PATH = 'https://radiostreamingapi.azurewebsites.net/';
export const RADIO_STREAMING_STAGE_PATH = 'https://radiostreaming-stage.soundmate.app/';
export const RADIO_STREAMING_PROD_PATH = 'https://radiostreamingapi.azurewebsites.net/';
export const RADIO_STREAMING_LOCAL_PATH = 'https://localhost:5001';
export const YOU_TUBE_THUMBNAIL_PATH = 'https://img.youtube.com/vi/';
export const IP_CURRENCIES_PATH = 'https://api.ipdata.co/?api-key=e6db75d90c47944e9e981fa2d5c0668619b8c8ece140857958697a24'
export const IP_CURRENCIES_NEW_PATH = 'https://api.ipstack.com/'
export const LANDING_PAGE_HOME = 'https://playpayouts.com/'
export const LANDING_PAGE_TERMS = 'https://playpayouts.com/Home/TermsOfService'
export const LANDING_PAGE_PRIVACY = 'https://playpayouts.com/Home/PrivacyPolicy'
export const APPLICATION_NAME = "PlayPayouts"
export const PAYMENTS_PROOFS_USER = 'https://help.playpayouts.com/'
export const MONEY_BACK_GUARANTEE = 'https://help.playpayouts.com/'
export const NEWS_UPDATE = 'https://support.playpayouts.com/'
export const HAND_BOOK = 'https://playpayouts.com/playpayouts-how-it-works.pdf'
export const REGISTER_SUCCESS_LINK = 'https://playpayouts.com/Home/Successful'