import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LANDING_PAGE_HOME } from '@constants/environyment-pathes.const';
import { AuthManagerService } from '@services/auth-manager.service';
import { AuthService } from '@services/auth.service';
import { LocalStorageService } from '@services/local-storage.service';
import { ModulesService } from '@services/modules.service';
import { finalize } from 'rxjs/operators';
import { NUV_LIST } from '../side-nav-panel.const';
import { TranslationsService } from '@services/translations.service';

declare var $;
@Component({
  selector: "skd-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit {
  public landingHomePage = LANDING_PAGE_HOME;
  body = $(".page-sidebars");
  page_header = $(".page-header");
  page_sidebar = $(".page-sidebar");
  page_content = $(".page-content");
  page_sidebar_fixed = true;
  menuItem: any[] = [];
  activeMenu:any;
  constructor(
    public cdr: ChangeDetectorRef,
    private moduleService: ModulesService,
    private storage: LocalStorageService,
    private readonly authManager: AuthManagerService,
    private readonly auth: AuthService,
    public readonly translations: TranslationsService
  ) {}

  accordion_menu() {
    $(".accordion-menu li ul li").parent().addClass("sub-menu");
    $(".accordion-menu li ul").parent().addClass("has-sub");

    var urlparam = window.location.pathname.split("/");
    var menuselctor = window.location.pathname;
    if (urlparam[urlparam.length - 1].length > 0)
      menuselctor = urlparam[urlparam.length - 1];
    else menuselctor = urlparam[urlparam.length - 2];

    $(".accordion-menu li")
      .find('a[href="' + menuselctor + '"]')
      .closest("li")
      .addClass("active")
      .parents()
      .eq(1)
      .addClass("active-page");
    $(".accordion-menu .active-page > a").addClass("active");

    $(".has-sub > a").on("click", function (e) {
      e.preventDefault();
      $(this).toggleClass("active");
      $(this).next(".sub-menu").slideToggle();
      return $(this)
        .parents()
        .siblings(".has-sub")
        .children(".sub-menu")
        .slideUp()
        .prev(".active")
        .removeClass("active");
    });
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.menuItemFunction();
    this.moduleService.emittingFunction.subscribe(()=>this.menuItemFunction());
    $(".page-sidebar-inner").click(function(e){
      e.stopPropagation();
      if ($(".user-main").hasClass("toggle-disable")) {
        $( ".user-main" ).addClass( "toggle" );
        $( ".user-main" ).removeClass( "toggle-disable" );
        }
    });
    this.cdr.detectChanges();
  }
  menuItemFunction() {
    this.moduleService.getMenuItems().subscribe((data) => {
      data.map((item) => {
        if (item && item.name == "Dashboard") item.subModules = [];
        if (item && item.subModules?.length == 0) {
          let nameChange = item.name.split(" ");
          item.name = nameChange.includes("Top")
            ? nameChange[0] + "_" + nameChange[1]
            : nameChange[0];
          let index = NUV_LIST.findIndex((val) => val.name === item.name);
          if (index >= 0) {
            item["link"] = NUV_LIST[index].link;
            item["icon"] = NUV_LIST[index].icon;
          }
        } else if (item && item.subModules?.length > 0) {
          let nameChange = item.name.split(" ");
          item.name = nameChange.includes("&")
            ? nameChange[0] + "_" + nameChange[2]
            : nameChange[0];
          let index = NUV_LIST.findIndex((val) => val.name === item.name);
          if (index >= 0) {
            item["link"] = NUV_LIST[index].link;
            item["icon"] = NUV_LIST[index].icon;
            item["list"] = NUV_LIST[index].list;
            item.subModules.map((c) => {
              let s = c.name.split(" ");
              c.name = s.includes("&")
                ? s[0] + "_" + s[2]
                : s[0] +
                  "_" +
                  s[1] +
                  "_" +
                  s[2] +
                  "_" +
                  s[3] +
                  "_" +
                  s[4] +
                  "_" +
                  s[5];
              let b = c.name.split("-");
              c.name = b.includes("undefined") ? b[0] : b[0];
              b.map((str) => (c.name = str.replace(/_undefined/g, "")));
            });
            item["list"].forEach((x) => {
              let index = item.subModules.findIndex(
                (val) => val.name === x.name
              );
              if (index >= 0) {
                x.isVisible = item.subModules[index].isVisible;
                x.count = item.subModules[index].count;
              }
              item.subModules.map((valName) => {
                let subName = valName?.name.split(" ");
                return (valName.name = subName.includes("&")
                  ? ""
                  : subName.join("_"));
              });
              item["list"].map((v) => {
                let subName = v?.name.split(" ");
                return (v.name = subName.includes("&")
                  ? ""
                  : subName.join("_"));
              });
            });
          }
        }
      });
      this.menuItem = data.filter((o) => o.name !== "Statistics");
      this.cdr.detectChanges();
      this.accordion_menu()
    });
  }
  menuActive(menu,i){
    $(".page-sidebar-inner").click(function(e){
      e.stopPropagation();
      $( ".user-main" ).addClass( "toggle" );
    });
    if(menu.subModules.length === 0) return;
    this.activeMenu = this.menuItem.filter((x,index)=>(x.name == menu.name) && (i == index))
    this.activeMenu = this.activeMenu[0].name
    this.cdr.detectChanges();
  }
  mainMenuToggle(item){
    if(item.subModules.length === 0){
      $(".page-sidebar-inner").click(function(e){
        e.stopPropagation();
        if ($(".user-main").hasClass("toggle")) {
          $( ".user-main" ).addClass( "toggle-disable" );
          $( ".user-main" ).removeClass( "toggle" );
          }
      });
    }
    
  }
  hamberganMenu(item){
    if(item.subModules.length > 0){
      $(".page-sidebar-inner").click(function(e){
        e.stopPropagation();
        if ($(".user-main").hasClass("toggle")) {
          $( ".user-main" ).addClass( "toggle-disable" );
          $( ".user-main" ).removeClass( "toggle" );
          }else{
            $( ".user-main" ).removeClass( "toggle-disable" );
          }
         
      });
    }
     
  }
  logoutBtn(){
    this.storage.removeLocalStorageItem('sociallogin');
    this.storage.removeLocalStorageItem('welcomeMessage');
    this.storage.removeLocalStorageItem('CURRENCY');
    this.storage.removeLocalStorageItem('accessToken');
    this.auth.signOut(this.authManager.refreshToken).pipe(
      finalize(() => {
        this.authManager.clearAuthData();
        window.location.href = this.landingHomePage;
      })
    ).subscribe();
  }
}
