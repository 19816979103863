<div class="footer d-flex">
    <!-- <p class="d-none">
        {{currentYear}} © {{item}}
    </p> -->

    <div class="sidebar-copyright d-flex">
        {{currentYear}} © {{appName}}
        <span>{{serverTime | date:'medium'}}</span>
       </div>
    <ul class="footer-section" *ngIf="content">
        <li class="time-text d-none"><a>{{serverTime | date:'medium'}}</a></li>
        <li *ngIf="content.footerAboutEnabled" ><a routerLink="/user/about">{{ translate.FOOTER.EARNING_GUIDE | translate }}</a></li>
        <li *ngIf="content.footerHowItWorksEnabled"><a routerLink="/user/how-it-works">{{ translate.FOOTER.HOW_IT_WORKS | translate }}</a></li>
        <li *ngIf="content.termsOfServiceEnabled"><a routerLink="/user/terms-of-servies">{{ translate.FOOTER.TERMS_OF_SERVICES | translate }}</a></li>
        <li *ngIf="content.privacyPolicyEnabled"><a routerLink="/user/privacy-policy">{{ translate.FOOTER.PRIVACY_POLICY | translate }}</a></li>
        <li *ngIf="content.paymentProofsEnabled"><a routerLink="/user/payment-proofs">{{ translate.FOOTER.PAYMENT_PROOFS| translate }}</a></li>
        <li *ngIf="content.userMenuNewsEnabled"><a routerLink="/user/news">{{ translate.FOOTER.NEWS| translate }}</a></li>
<!-- <li *ngIf="content.footerSupportEnabled"><a routerLink="/user/faqs">{{ translate.FOOTER.FAQS | translate }}</a></li> -->

<li *ngIf="content.footerSupportEnabled"><a [href]="support" target="_blank">{{ translate.FOOTER.SUPPORT | translate }}</a></li>
    </ul>
</div>
