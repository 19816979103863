import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ICurrency } from '../interfaces/currency.interface';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  actiTop =new Subject();
  languageSubject = new Subject();
  private readonly CURRENCY_KEY = 'CURRENCY';

  constructor(
    private localStorageService: LocalStorageService
  ) { }

  public setCurrency(currencyOptions: ICurrency): void {
    this.localStorageService.setLocalStorageItem(this.CURRENCY_KEY, currencyOptions);
  }


  public setLanguage(selectedLang): void {
    this.localStorageService.setLocalStorageItem('setLanguageCode', selectedLang.id);
  }

  public getLanguage(): string {
   return  this.localStorageService.getLocalStorageItem('setLanguageCode');
  }

  public getCurrentCurrency(): ICurrency {
    return this.localStorageService.getLocalStorageItem<ICurrency>(this.CURRENCY_KEY);
  }
}
